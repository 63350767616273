import { Outlet } from "react-router-dom"
import { RequireAuth } from '../../commons/auth';
import { Header } from './Header';
import { ClienteFormModal } from "../../features/clientes/components/ClienteFormModal";
import { useGetCurrenciesQuery } from "../../features/currencies/services";
// import { useGetUserQuery } from "../../commons/auth/services"
import "./App.css"
import "../../features/currencies/services"

function App() {

  return <>
    <Header />
    <Outlet />
    <ClienteFormModal />
  </>
}

export default ()=><RequireAuth><App/></RequireAuth>;
