import { appApi, PaginatedResponse, PaginationParams } from "../../commons/services";
import { CuentasPorCobrar, RawTransaccion, RawTransaccionWithDetails } from "./types";

export type Filter = {
  desde?: string
  hasta?: string
}

export const cajaApi = appApi.enhanceEndpoints({
  addTagTypes: ['Caja', "Pagables"]}).injectEndpoints({
  endpoints: (build) => ({
    getTransacciones: build.query<PaginatedResponse<RawTransaccion>, PaginationParams<Filter>>({
      query: (params)=>({
        url: "transacciones",
        params
      }),
      providesTags: (results, error) => [
        ...(results ? results.records.map(v => ({ type: 'Caja' as const, id: v.id})) : []),
        { type: 'Caja', id: "*" }
      ]
    }),
    getTransaccion: build.query<RawTransaccionWithDetails, number>({
      query: (id) => `transacciones/${id}`,
      providesTags: (results) => results ? [{type: "Caja", id: results.id}] : []
    }),
    anularTransaccion: build.mutation<NonNullable<RawTransaccion["anulacion"]>, {
      transaccionId: number
      motivo: string
    }>({
      query: ({transaccionId, ...body}) => ({
        method: "POST",
        url: `transacciones/${transaccionId}/anular`,
        body
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const {data} = await queryFulfilled
          for(let tag of (getState().appApi.provided.Caja[args.transaccionId] ?? [])){
            const regex = /(?<endpoint>.*)\((?<queryArgs>.*)\)/
            const {groups: {endpoint, queryArgs}} = regex.exec(tag) as any as {groups: {endpoint: string, queryArgs: string}}
            if(endpoint === "getTransacciones"){
              dispatch(cajaApi.util.updateQueryData(endpoint, JSON.parse(queryArgs), (draft) => {
                Object.assign(draft, {
                  records: draft.records.map(r => {
                    return r.id === args.transaccionId ? {
                      ...r,
                      estado: 2,
                      anulacion: data
                    } : r
                  }),
                })
              }))
            }
          }
        }
        catch (e) { }
      }
    }),

    pagarCuotas: build.mutation<RawTransaccion, FormData>({
      query: (body) => ({
        method: "POST",
        url: "transacciones",
        body
      }),
      invalidatesTags: ["Caja"]
    }),

    getCuotasPendientes: build.query<CuentasPorCobrar, {codigoPago: string, fecha: string}>({
      query: (params) =>({
        url: `pagables`,
        params
      }),
      providesTags: ["Pagables"]
    }),

  })
})

export const {
  useGetTransaccionesQuery,
  useGetTransaccionQuery,
  useAnularTransaccionMutation,

  useLazyGetCuotasPendientesQuery,
  usePagarCuotasMutation,
} = cajaApi