import React from "react"

export type ModalState<Extra extends Record<string, any>> = {
  isOpen: boolean
} & Partial<Extra>

type Observer = <Extra extends Record<string, any>>(state: ModalState<Extra>) => void

type ModalContextValue = {
  observers: Record<string, Observer>
}

export const ModalContext = React.createContext<ModalContextValue>({
  observers: { },
})