import { skipToken } from "@reduxjs/toolkit/query"
import { useParams } from "react-router-dom"
import { PaginatedResponse, Page, appApi, PaginationParams } from "../../commons/services"
import { RawProyecto, ParsedProyecto, Proyecto } from "./types"
import { useCallback } from "react"
import { Money } from "../../commons/money"

export type ProyectoFilter = {}

export const proyectosApi = appApi.enhanceEndpoints({addTagTypes: ["Proyectos"]}).injectEndpoints({
  endpoints: (build) =>({
    //Queries
    getProyectos: build.query<PaginatedResponse<RawProyecto>, PaginationParams<ProyectoFilter>>({
      query: (params) => ({url: "proyectos", params}),
      providesTags: (results) => [
        ...(results ? results.records.map(p => ({type: "Proyectos"  as const, id: p.id})) : []),
        {type: "Proyectos", id: "*"}
      ]
    }),
    getProyecto: build.query<RawProyecto, number>({
      query: (id) => `proyectos/${id}`,
      providesTags: (_,__,id) => [{type: "Proyectos", id}]
    }),

    //Mutations
    registrarProyecto: build.mutation<RawProyecto, Omit<Proyecto, "id">>({
      query: (body) => ({
        method: "POST",
        url: "proyectos",
        body
      }),
      invalidatesTags: (result) => result ? [{ type: "Proyectos", id: "*" }] : []
    }),
    actualizarProyecto: build.mutation<RawProyecto, Partial<Proyecto>>({
      query: ({id, ...body}) => ({
        method: "PUT",
        url: `/proyectos/${id}`,
        body
      }),
      invalidatesTags: (result) => result ? [
        { type: "Proyectos", id: "*" },
        { type: "Proyectos", id: String(result.id) }
      ] : []
    })
  })
})

export const {
  useLazyGetProyectosQuery,
  useGetProyectosQuery,
  useGetProyectoQuery,
  useRegistrarProyectoMutation,
  useActualizarProyectoMutation
} = proyectosApi

export const parseProyecto = (data: RawProyecto): ParsedProyecto => {
  const moneda = data.moneda
  return {
    ...data,
    _raw: data,
    moneda,
    precioReservas: new Money(data.precioReservas.amount, moneda),
    cuotaInicial: new Money(data.cuotaInicial.amount, moneda),
  }
}

export const useParseProyecto = ()=>{
  return useCallback((raw: RawProyecto)=>{
    return parseProyecto(raw)
  }, [])
}

export const useProyectoContext = ()=>{
  const {proyectoId} = useParams()

  const parseProyecto = useParseProyecto()

  const { proyecto } = useGetProyectoQuery(proyectoId ? parseInt(proyectoId) : skipToken, {
    selectFromResult: ({data}) => ({
      proyecto: data && parseProyecto(data)
    })
  })

  return proyecto
}