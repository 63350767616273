import { useCallback, useEffect, ReactElement, useState } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { components, GroupBase, OptionProps } from 'react-select';
import { Props as SelectProps } from 'react-select';
import {
  AsyncPaginate,
  UseAsyncPaginateParams,
  ComponentProps,
} from 'react-select-async-paginate';
import { useLazyGetProyectosQuery } from '../proyectosApi';
import { RawProyecto } from '../types';

export type { RawProyecto }

type Props<OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>> = SelectProps<OptionType, IsMulti, Group>
& UseAsyncPaginateParams<OptionType, Group, {page: number}>
& ComponentProps<OptionType, Group, IsMulti>;


type CustomProps = {
  feedback?: string
}

export function ProyectoSelect<IsMulti extends boolean, Group extends GroupBase<RawProyecto>>({
  feedback,
  ...props
}: Omit<Props<RawProyecto, IsMulti, Group>, "loadOptions"> & CustomProps){
  const [buscar, buscarState] = useLazyGetProyectosQuery()
  const [inputValue, setInputValue] = useState("")

  const loadOptions = useCallback<Props<RawProyecto, IsMulti, Group>["loadOptions"]>(async (search, loadedOptions, additional)=>{
    if(!search) return { options: [] }
    const response = await buscar({
      search,
      page: {
        current: additional!.page,
        size: 10
      }
    }).unwrap()

    return {
      options: response.records,
      hasMore: !!response.meta.nextPage,
      additional: response.meta.nextPage !== undefined ? { page: response.meta.nextPage } : undefined
    }
  }, [buscarState])
  
  return <InputGroup hasValidation>
    <AsyncPaginate
      isClearable
      inputValue={inputValue}
      onInputChange={(input, reason) => {
        if (reason.action === "set-value"
          || reason.action === "input-blur"
          || reason.action === "menu-close") {
              return;
        }
        setInputValue(input);
      }}
      {...props}
      className={["form-control", props.className].filter(cn=>!!cn).join(" ")}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 999 }),
        container: base => ({...base, padding: 0, flex: 1}),
        control: (styles: any)=>{
          delete styles["boxShadow"]
          return {
            ...styles,
            borderRadius: "0.375rem",
            borderWidth: 0,
          }
        },
        ...(props.styles ?? {})
      }}
      menuPlacement="auto"
      placeholder=""
      getOptionValue={option => (option as any).value ?? String(option.id)}
      getOptionLabel={option => (option as any).label ?? option.nombre}
      loadOptionsOnMenuOpen={false}
      debounceTimeout={300}
      additional={{page: 1}}
      loadOptions={loadOptions}
      menuPortalTarget={document.body}
    />
    {/* {buscarState.isError ? <Button><FaSync /></Button> : null} */}
    <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
  </InputGroup>
}