import { useEffect, useState } from "react"
import { Alert, Breadcrumb, Col, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { FaSave } from "react-icons/fa"
import { CircleMarker, GeoJSONProps, MapContainer, Polyline, TileLayer } from "react-leaflet"
import {
  createElementObject,
  createPathComponent,
  extendContext
} from '@react-leaflet/core'
import { useDispatch } from "react-redux"
import { Link } from "react-router-dom"
import { LoadingButton } from "../../../commons/components"
import { useProyectoContext } from "../../proyectos/proyectosApi"
import * as yup from "yup"
import L from "leaflet"
import proj4 from "proj4"
import "proj4leaflet"
import { ErrorBoundary } from "../../../commons/components/ErrorBoundary"
import { Plano, useActualizarPlanoMutation, useRegistrarPlanoMutation } from "../planosApi"
import { yupResolver } from "@hookform/resolvers/yup"
import { useServerValidationErrors } from "../../../commons/hooks"

const GeoJSON = createPathComponent<L.Proj.GeoJSON, GeoJSONProps>(
  function createGeoJSON({ data, ...options }, ctx) {
    const geoJSON = L.Proj.geoJson(data as any, options)
    return createElementObject(
      geoJSON,
      extendContext(ctx, { overlayContainer: geoJSON }),
    )
  },
  function updateGeoJSON(layer, props, prevProps) {
    if (props.style !== prevProps.style) {
      if (props.style == null) {
        layer.resetStyle()
      } else {
        layer.setStyle(props.style)
      }
    }
  },
)

const schema = yup.object({
  titulo: yup.string()
    .label("título")
    .required()
    .max(100),
  descripcion: yup.string()
    .label("descripción")
    .max(255),
  lotes: yup.mixed()
    .test("fileSize", "El archivo es demasiado grande", (value) => {
      return !value.length || value[0].size <= 2000000
    })
  // .test("mimeType", "El archivo es demasiado grande", (value) => {
  //   console.log("Filename: " + value[0].name);
  //   console.log("Type: " + value[0].type);
  //   console.log("Size: " + value[0].size + " bytes");
  //   return !value.length || true
  // }),
})

type FormValues = {
  titulo: string
  descripcion: string
  lotes: FileList
  tiles: FileList
}

type Props = {
  plano?: Plano
}

export const PlanoForm = ({ plano }: Props) => {
  const proyecto = useProyectoContext()!
  const dispatch = useDispatch()
  // const [coordenadas, setCoordenadas] = useState<any>({
  //   loading: false,
  //   error: false,
  //   data: []
  // })

  useEffect(() => {
    dispatch({
      type: "CHANGE_CONTAINER_FLUID",
      payload: true
    })
    return () => {
      dispatch({
        type: "CHANGE_CONTAINER_FLUID",
        payload: false
      })
    }
  }, [])

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      titulo: plano?.titulo ?? "",
      descripcion: plano?.descripcion ?? ""
    }
  })

  // const coordenadasFile = watch("coordenadas")?.item(0)

  // useEffect(() => {
  //   let cancel = false
  //   if (coordenadasFile) {
  //     const reader = new FileReader()
  //     reader.readAsText(coordenadasFile);

  //     setCoordenadas({
  //       loading: true,
  //       error: false,
  //       data: []
  //     })
  //     reader.onload = function () {
  //       if (cancel) return
  //       const result = String(reader.result).trimEnd()
  //       const lines = result.split(/\r\n|\n/);
  //       const data = [] as any[]
  //       const headers = lines[0].split(",")
  //       const numeroIndex = headers.indexOf("numero")
  //       const xIndex = headers.indexOf("x")
  //       const yIndex = headers.indexOf("y")
  //       const sridIndex = headers.indexOf("srid")
  //       // const perimetroIndex = headers.indexOf("perimetro")
  //       for (let i = 1; i < lines.length; i++) {
  //         const cols = lines[i].split(",")
  //         const srid = +cols[sridIndex]
  //         let urn
  //         if (srid > 32600 && srid <= 32660) {
  //           urn = `urn:ogc:def:crs:EPSG::${srid}`
  //           if (!proj4.defs(urn)) {
  //             const zone = srid - 32600
  //             proj4.defs(urn, `+proj=utm +zone=${zone} +ellps=WGS84 +datum=WGS84 +units=m +no_defs`);
  //           }
  //         }
  //         else if (srid > 32700 && srid <= 32760) {
  //           urn = `urn:ogc:def:crs:EPSG::${srid}`
  //           if (!proj4.defs(urn)) {
  //             const zone = srid - 32700
  //             proj4.defs(urn, `+proj=utm +zone=${zone} +south +ellps=WGS84 +datum=WGS84 +units=m +no_defs`);
  //           }
  //         }
  //         else {
  //           urn = `urn:ogc:def:crs:EPSG::4326`
  //           if (!proj4.defs(urn)) {
  //             proj4.defs(urn, `+proj=longlat +ellps=WGS84 +datum=WGS84 +no_defs`);
  //           }
  //         }
  //         data.push({
  //           type: "Feature",
  //           geometry: {
  //             type: "Point",
  //             coordinates: [+cols[xIndex], +cols[yIndex]]
  //           },
  //           properties: {
  //             label: `V${cols[numeroIndex]}`,
  //             // perimetro: cols[perimetroIndex] == "1" ? true : false
  //           },
  //           crs: {
  //             type: "name",
  //             properties: {
  //               name: urn
  //             }
  //           }
  //         })
  //       }
  //       setCoordenadas({
  //         loading: false,
  //         error: false,
  //         data
  //       })
  //     };

  //     reader.onerror = function () {
  //       setCoordenadas({
  //         loading: false,
  //         error: true,
  //         data: []
  //       })
  //       console.log(reader.error);
  //     };
  //   }
  //   return () => {
  //     cancel = true
  //   }
  // }, [coordenadasFile])

  const [create, createState] = useRegistrarPlanoMutation()
  const [update, updateState] = useActualizarPlanoMutation()

  useServerValidationErrors(plano ? updateState : createState, setError)

  const renderAlerts = () => {
    const alerts = []
    const state = plano ? updateState : createState
    if (state.isError) {
      const error = state.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="error-br" />, error.message ?? error.data?.message ?? "Error de red")
      else message.push(".")
      alerts.push(<Alert key="error" variant="danger">
        {message}
      </Alert>)
    }
    else if (state.isSuccess) {
      if (!state.data.hasErrors) {
        alerts.push(<Alert key="success" variant="success">La solicitud se completó exitosamente</Alert>)
      }
      else {
        alerts.push(<Alert key="error" variant="warning">La solicitud se completó con errores</Alert>)
      }
    }
    return alerts
  }

  // const renderPerimetro = () => {
  //   let perimetro
  //   try {
  //     perimetro = coordenadas.data/*.filter((c:any)=>c.properties.perimetro)*/.map((coordenada: any) => {
  //       var point = L.point(coordenada.geometry.coordinates[0], coordenada.geometry.coordinates[1])
  //       const latLng = (new L.Proj.CRS(coordenada.crs.properties.name)).unproject(point)
  //       return latLng
  //     })
  //     if (perimetro.length) {
  //       perimetro = [
  //         ...perimetro,
  //         perimetro[0]
  //       ]
  //     }
  //   }
  //   catch (e) {
  //     return null
  //   }

  //   return <Polyline
  //     positions={perimetro}
  //   />
  // }

  return <>
    {!plano ? <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../planos" }}>Planos</Breadcrumb.Item>
      <Breadcrumb.Item active>Registro</Breadcrumb.Item>
    </Breadcrumb> : null}
    <Row className="flex-grow-1 flex-column flex-md-row">
      <Col sm={12} md={4} lg={3}>
        <Form aria-label="Formulario registro de planos" onSubmit={handleSubmit((values) => {
          const body = new FormData()
          body.append("titulo", values.titulo)
          body.append("descripcion", values.descripcion)
          if (values.lotes[0]) body.append("lotes", values.lotes[0], "lotes.csv")
          !plano ? create({
            proyectoId: proyecto.id,
            body
          }).unwrap().then(() => {
            reset()
            // setCoordenadas({
            //   loading: false,
            //   error: false,
            //   data: []
            // })
          }, () => { }) :
          update({
            proyectoId: proyecto.id,
            planoId: plano.id,
            body
          })

        })}>
          {renderAlerts()}
          <Form.Group data-testid="titulo-form-group" className="mb-3" controlId="titulo">
            <Form.Label>Título</Form.Label>
            <Form.Control
              {...register("titulo")}
              isInvalid={!!formState.errors.titulo}
            />
            <Form.Control.Feedback type="invalid">{formState.errors.titulo?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group data-testid="descripcion-form-group" className="mb-3" controlId="descripcion">
            <Form.Label>Descripción</Form.Label>
            <Form.Control as="textarea"
              {...register("descripcion")}
              isInvalid={!!formState.errors.descripcion}
            />
            {formState.errors.descripcion ?
              <Form.Control.Feedback type="invalid">{formState.errors.descripcion.message}</Form.Control.Feedback> :
              <Form.Text><span className={watch("descripcion").length > 255 ? "text-danger" : ""}>{watch("descripcion").length}</span> / 255 </Form.Text>}
          </Form.Group>
          <Form.Group data-testid="tiles-form-group" className="mb-3" controlId="coordenadas">
            <Form.Label>Cargar tiles</Form.Label>
            <Form.Control type="file"
              {...register("tiles")}
              accept="application/zip"
            />
          </Form.Group>
          <Form.Group data-testid="lotes-form-group" className="mb-3" controlId="lotes">
            <Form.Label>Cargar lotes</Form.Label>
            <Form.Control type="file"
              {...register("lotes")}
              accept="text/csv"
              isInvalid={!!formState.errors.lotes}
            />
            <Form.Control.Feedback type="invalid">{formState.errors.lotes?.message}</Form.Control.Feedback>
          </Form.Group>
          <LoadingButton type="submit" isLoading={(plano ? updateState : createState).isLoading} icon={<FaSave style={{ position: "relative", top: "-0.125rem" }} />}>
            Guardar
          </LoadingButton>
        </Form>

      </Col>
      <Col>
        <ErrorBoundary>
          <MapContainer
            // ref={(map) => {
            //   if (map) {
            //     try {
            //       if (coordenadas.data.length) map.fitBounds(L.Proj.geoJson(coordenadas.data as any).getBounds())
            //     }
            //     catch (e) {
            //       console.log(e)
            //     }
            //   }
            // }}
            preferCanvas
            zoom={15}
            center={[proyecto.ubicacion.latitud, proyecto.ubicacion.longitud]}
            style={{ height: "100%" }}
          >
            <TileLayer
              attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
              url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
            // url="https://{s}.aerial.maps.ls.hereapi.com/maptile/2.1/maptile/newest/satellite.day/{z}/{x}/{y}/256/jpg?apiKey=aRrXMN6rNeDunujbIgCqESvkttKlk4Pp2j5N7xTp4Ek"
            // subdomains={["1","2","3"]}
            // url={apiRoute("/{z}/{x}/{y}.png")}
            />
            {/* {coordenadas.data.map((coordenada: any) => {
              return <GeoJSON
                data={coordenada}
                onEachFeature={(f, l) => {
                  l.bindTooltip(f.properties.label)
                }}
                pointToLayer={(a, b) => {
                  return L.circleMarker(b, {
                    radius: 4
                  })
                }}
              />
            })}
            {renderPerimetro()} */}
          </MapContainer>
        </ErrorBoundary>
      </Col>
    </Row>
  </>
}