import { useMemo } from "react"
import { useSelector } from "react-redux"
import { useGetUserQuery, authApi } from "../services"
import { AuthAwareState, AuthState } from "../services/authSlice"
import { User as TUser } from "../services/types"


export const useUser = () => {

  const {user, timestamp} = useSelector<AuthAwareState, AuthState>(state => state.auth)

  const fetchUser = useGetUserQuery(undefined,  {
    // skip: user !== undefined && Date.now() - timestamp < 30000,
    refetchOnReconnect: true,
    refetchOnFocus: true
  })

  return useMemo(()=>{
    if(user !== null){
      const enhancedUser = {
        ...(user||{}),
        isReady: !!user,
        isLoading: fetchUser.isFetching,
        error: fetchUser.error,
        can: function(permission: string | string[]) {
          if(typeof permission === "string") permission = [permission]
          return permission.every(p => user?.roles.some(r => r.permissions.some(up => up.name == p)))
        },
        canAny: function(permission: string[]) {
          return permission.some(p => user?.roles.some(r => r.permissions.some(up => up.name == p)))
        },
        hasRole: (role: number | string) => {
          return user?.roles.some(r=>r.name === role || r.id === role)
        },
        isSuperUser: function() {
          return this.hasRole(1)
        }
      }
      return enhancedUser;
    } 
    return null
  }, [user])
}

export type User = ReturnType<typeof useUser>