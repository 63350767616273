import { Alert, Breadcrumb, Col, Form, InputGroup, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { Link, useParams } from "react-router-dom"
import { LoadingButton } from "../../../commons/components"
import { Money, MoneyInput } from "../../../commons/money"
import { ManzanasSelect } from "../../manzanas/components/ManzanasSelect"
import { ManzanaView } from "../../manzanas/types"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { MapContainer, Marker, Polygon, TileLayer, Tooltip } from "react-leaflet"
import L, { LatLngExpression, Map, Polygon as TPolygon } from "leaflet"
import { useProyectoContext } from "../../proyectos/proyectosApi"
import { useCallback, useEffect, useMemo, useRef } from "react"
import { useRegistrarLoteMutation } from "../services"
import { useServerValidationErrors } from "../../../commons/hooks"
import Decimal from "decimal.js"
import { CategoriaSelect } from "../../categorias/components/CategoriaSelect"
import { Categoria } from "../../categorias/categoriaApi"

type FormValues = {
  manzana: ManzanaView | null
  categoria: Categoria | null
  numero: string
  superficie: string
  precio: Money
  precioSugerido: Money
  geocerca: string
}

const schema = yup.object({
  manzana: yup.mixed().required(),
  categoria: yup.mixed().label("categoría").required(),
  numero: yup.string().label("número").required(),
  superficie: yup.string().required(),
  precio: yup.mixed().nullable(),
  geocerca: yup.string()
  // .matches(/([-+]?(?:(?:[1-8]?\d(?:\.\d+)?){1,15}|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?){1,15})\n/g, "El formato es inválido.")
})

export const LoteForm = ()=>{
  const proyecto = useProyectoContext()!

  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      manzana: null,
      categoria: null,
      geocerca: "",
      precio: new Money(null, proyecto.moneda),
      precioSugerido: new Money(null, proyecto.moneda),
    }
  })
  
  const mapRef = useRef<Map|null>(null)
  const polygonRef = useRef<TPolygon|null>(null)

  function parsePolygon(value: string){
    // return value.trim().split("\n").map( chunk => {
    //   const matches = chunk.match(/([-+]?(?:(?:[1-8]?\d(?:\.\d+)?){1,15}|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?){1,15})/)
    //   if(!matches) throw Error("No se puede convertir a LatLngExpression")
    //   return [+matches[1], +matches[2]] as LatLngExpression
    // })
    const regex = /([-+]?(?:(?:[1-8]?\d(?:\.\d+)?){1,15}|90(?:\.0+)?)),\s*([-+]?(?:180(?:\.0+)?|(?:(?:1[0-7]\d)|(?:[1-9]?\d))(?:\.\d+)?){1,15})/g
    
    const positions: LatLngExpression[] = []
    let match
    while((match = regex.exec(value)) !== null){
      const [, lat, lng] = match
      positions.push([+lat, +lng])
    }
    return positions
  }

  const geocerca = watch("geocerca")
  const parsedPolygon = useMemo(()=>parsePolygon(geocerca), [geocerca])

  useEffect(()=>{
    polygonRef.current && mapRef.current?.fitBounds(polygonRef.current.getBounds())
  }, [mapRef.current, polygonRef.current, parsedPolygon])

  useEffect(()=>{
    watch((values, info)=>{
      if(info.name === "superficie" || info.name === "categoria"){
        const { superficie, categoria } = values
        setValue("precioSugerido", superficie && categoria ? new Money(categoria.precioM2!.amount!, proyecto.moneda).mul(superficie).mround(proyecto.redondeo, Decimal.ROUND_UP) : new Money(null, proyecto.moneda))
      }
    })
  }, [formState.isSubmitted, proyecto])

  const [create, createState] = useRegistrarLoteMutation()

  useServerValidationErrors(createState, setError, useCallback((key: string) => {
    if(key === "manzanaId") return "manzana"
    if(key === "categoriaId") return "categoria"
    return key
  }, []))

  const renderAlert = () => {
    if (createState.isSuccess) {
      return <Alert variant="success">La solicitud se completo exitosamente</Alert>
    }
    if (createState.isError) {
      return <Alert variant="danger">Ocurrio un error al realizar la solicitud</Alert>
    }
  }

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{to: "../lotes"}}>Lote</Breadcrumb.Item>
      <Breadcrumb.Item active>Registro</Breadcrumb.Item>
    </Breadcrumb>
    {renderAlert()}
    <Form aria-label="Formulario de registro de lotes" autoComplete="off" onSubmit={handleSubmit((values)=>{
      // const vertices = values.geocerca.trim().split("\n")
      // const [lat, lng] = vertices[0].split(",")
      // const geocerca = `POLYGON((${vertices.reduce((carry, vertice)=>{
      //   const [lat, lng] = vertice.split(",")
      //   return `${carry}${lng.trim()} ${lat.trim()},`
      // }, "")}${lng.trim()} ${lat.trim()}))`
      const geocerca = ""
      
      create({
        proyectoId: proyecto.id,
        manzanaId: values.manzana!.id,
        categoriaId: values.categoria!.id,
        numero: values.numero,
        geocerca,
        superficie: values.superficie,
        precio: values.precio.amount?.toFixed(2)
      })
      .unwrap().then(()=>{
        reset()
        window.scrollTo(0, 0)
      }, (e)=>{

      })
    })}>
      <Row className="gx-2">
        <Controller
          control={control}
          name="manzana"
          render={({field: {ref, ...field}, fieldState})=>{
            return <Form.Group as={Col} xs={6} md={3} className="mb-3" data-testid="manzana-form-group" controlId="manzana">
              <Form.Label>Manzana</Form.Label>
              <ManzanasSelect
                inputId="manzana"
                {...field}
                proyectoId={proyecto.id}
                className={"text-uppercase"}
                isInvalid={!!fieldState.error}
              />
              <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
            </Form.Group>
          }}
        />
        <Form.Group as={Col} xs={6} md={3} className="mb-3" data-testid="numero-form-group" controlId="numero">
          <Form.Label>Número</Form.Label>
          <Form.Control
            {...register("numero")}
            isInvalid={!!formState.errors.numero}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.numero?.message}</Form.Control.Feedback>
        </Form.Group>
        <Controller
          control={control}
          name="categoria"
          render={({field: {ref, ...field}, fieldState})=>{
            return <Form.Group as={Col} xs={6} md={3} className="mb-3" data-testid="categoria-form-group" controlId="categoria">
              <Form.Label>Categoria</Form.Label>
              <CategoriaSelect
                inputId="categoria"
                {...field}
                proyectoId={proyecto.id}
                className={"text-uppercase" + (fieldState.error ? " is-invalid" : "")}
              />
              <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
            </Form.Group>
          }}
        />
        <Form.Group as={Col} xs={12} className="mb-3"  data-testid="geocerca-form-group" controlId="geocerca">
          <Form.Label>Geocerca</Form.Label>
          <div className="d-flex flex-wrap">
            <Form.Control
              {...register("geocerca")}
              as="textarea"
              className="col-12 col-lg-6 order-1"
              isInvalid={!!formState.errors.geocerca}
              rows={5}
            />
            <Form.Control.Feedback className="col-12 order-3" type="invalid">{formState.errors.geocerca?.message}</Form.Control.Feedback>
            <div className="col-12 col-lg-6 order-2">
              <MapContainer
                ref={mapRef}
                preferCanvas
                zoom={10}
                center={[proyecto.ubicacion.latitud, proyecto.ubicacion.longitud]}
                style={{height: 240}}
                dragging={false}
                zoomControl={false}
                doubleClickZoom={false}
                touchZoom="center"
                scrollWheelZoom={false}
              >
                <TileLayer
                  attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
                  url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                />
                <Marker
                  title={proyecto.nombre}
                  position={[proyecto.ubicacion.latitud, proyecto.ubicacion.longitud]}
                >
                  <Tooltip permanent>{proyecto.nombre}</Tooltip>
                </Marker>
                {parsedPolygon.length ? <Polygon
                  ref={polygonRef}
                  positions={parsedPolygon}
                /> : null}
              </MapContainer>
            </div>
          </div>
        </Form.Group>
        <Form.Group as={Col} xs={6} md={3} className="mb-3" data-testid="superficie-form-group" controlId="superficie">
          <Form.Label>Superficie</Form.Label>
          <InputGroup hasValidation>
            <Form.Control
              {...register("superficie")}
              isInvalid={!!formState.errors.superficie}
            />
            <InputGroup.Text>m²</InputGroup.Text>
            <Form.Control.Feedback type="invalid">{formState.errors.superficie?.message}</Form.Control.Feedback>
          </InputGroup>
        </Form.Group>
        <Controller
          control={control}
          name="precio"
          render={({field: {ref, ...field}, fieldState})=>{
            return <Form.Group as={Col} xs={6} md={3} className="mb-3" data-testid="precio-form-group" controlId="precio">
              <Form.Label>Precio</Form.Label>
              <MoneyInput
                {...field}
                isInvalid={!!fieldState.error}
              />
              <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
            </Form.Group>
          }}
        />
        <Controller
          control={control}
          name="precioSugerido"
          render={({field: {ref, ...field}})=>{
            return <Form.Group as={Col} xs={6} md={3} className="mb-3" data-testid="preciosugerido-form-group" controlId="precio">
              <Form.Label>Precio sugerido</Form.Label>
              <MoneyInput
                {...field}
                readOnly
              />
            </Form.Group>
          }}
        />
      </Row>

      <LoadingButton
        type="submit"
        isLoading={createState.isLoading}
      >
        Guardar
      </LoadingButton>
    </Form>
  </div>
}