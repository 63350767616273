import React from 'react'
import { Container } from 'react-bootstrap'
import { useSelector, useDispatch } from 'react-redux'
import { ClickOutsideHandler } from '../click-outside-handler'
import { Sidebar, SidebarProps } from './Sidebar'
import "./simple-sidebar.css"

type Props = {
  sidebar: SidebarProps
  children: React.ReactNode
}
export const SidebarLayout = ({sidebar, children}: Props) => {
  const showSidebar = useSelector((state:any) => state.sidebarVisibility)
  const containerFluid = useSelector((state:any) => state.containerFluid)
  const dispatch = useDispatch()

  const onClickOutsideHandler = React.useCallback((event: MouseEvent | TouchEvent)=>{
    const toggle = document.querySelector('[aria-label="Toggle navigation"]')
    if(!toggle?.contains(event.target as Node)){
      dispatch({
        type: "CLOSE_SIDEBAR"
      })
    }
  }, [showSidebar])
  
  return <div className={"position-relative d-flex flex-grow-1 bg-light" + (showSidebar ? "" : " collapsed")}  id="wrapper">
    <ClickOutsideHandler className="shadow-sm" onClickOutside={onClickOutsideHandler}>
      <Sidebar className="bg-white shadow-sm" {...sidebar}/>
    </ClickOutsideHandler>
    <div className="overflow-auto" style={{width: "100%"}}>
      <Container fluid={containerFluid} className="d-flex flex-column" style={{height: "100%"}}>
        <div className="d-flex flex-column bg-white rounded-lg shadow-sm my-2 p-3 flex-grow-1">
          {children}
        </div>
      </Container>
    </div>
  </div>
}

export default SidebarLayout