import moment from "moment";
import { LocaleObject } from "yup/lib/locale";

export const yupMessages: LocaleObject = {    
  "mixed": {
    "required": "El campo '${path}' es requerido.",
    "notType": ({path, type}) => {
      return `El campo '${path}' solo admite valores ${type == "number" ? "numéricos" : type}`
    }
  },
  "string": {
    "min": "El campo '${path}' debe contener al menos ${min} caracteres.",
    "max": "El campo '${path}' no debe exceder los ${max} caracteres."
  },
  "number": {
    "min": "El campo '${path}' debe ser mayor o igual a ${min}.",
    "max": "El campo '${path}' debe ser menor o igual a ${max}."
  },
  "date": {
    "min": ({min, path})=>`El campo '${path}' debe ser posterior a ${moment(min, "YYYY-MM-DD").format("DD-MM-YYYY")}`
  }
}