import ReactDOM from "react-dom"
import {Dropdown, Spinner} from "react-bootstrap"
import {VerticalEllipsisDropdownToggle} from "../../../commons/components"
import { Link } from "react-router-dom"
import { FaEye } from "react-icons/fa"
import { Rol } from "../rolesApi"

type Props = {
  rol: Omit<Rol, "permissions">
}

export function RowOptions({
  rol
}:Props){
  
  const options: React.ReactElement[] = []
  options.push(
    <Dropdown.Item key="view" as={Link} to={`${rol.id}`}>
      <FaEye className="me-2" style={{position: "relative", top: "-0.125rem"}} />
      Ver
    </Dropdown.Item>
  )
  
  if(!options.length) return null
  // if(deleteState.isLoading){
  //   return <div className="text-center">
  //     <Spinner animation="border" size="sm" variant="primary" />
  //   </div> 
  // }
  
  return <Dropdown>
    <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
    {ReactDOM.createPortal(
      <Dropdown.Menu style={{textTransform: 'none'}}>
        {options}
      </Dropdown.Menu>
    , document.body)}
  </Dropdown>
}