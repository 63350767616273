import {Routes, Route, Navigate} from "react-router-dom"
import { MainBody } from './MainBody';
import { ProjectBody } from "./ProjectBody";
import App from "./App"
import { ProyectosIndex, ProyectoForm, ProyectoView } from "../../features/proyectos/components";
import { VentasIndex, VentasForm } from "../../features/ventas/components";
import { ListaMoraEntryView, ListaMoraIndex } from "../../features/lista_mora/components";
import { CajaIndex, CajaForm, TransaccionView } from "../../features/caja/components";
import { ReservaForm, ReservasIndex } from "../../features/reservas/components";
import { Login, RequireAuth } from "../../commons/auth/components";
import { ManzanaIndex } from "../../features/manzanas/components/ManzanaIndex";
import { LoteForm, LoteIndex } from "../../features/lotes/components";
import { PagoExtraForm } from "../../features/creditos/components";
import { UfvIndex } from "../../features/ufv/components";
import { PlanoForm, PlanoFormEdit, PlanoIndex } from "../../features/planos/components";
import { UsuarioForm, UsuarioFormEdit, UsuarioIndex } from "../../features/usuarios/components";
import { RolForm, RolFormEdit, RolIndex, RolView } from "../../features/roles/components";
import { VendedorForm, VendedorIndex } from "../../features/vendedores/components";
import { ExchangeRateForm, ExchangeRateIndex } from "../../features/currencies/components";
import { ClienteIndex, ClienteView } from "../../features/clientes/components";

const projectRoutes = () => {
  return <Route path="proyectos/:proyectoId"
    element={<RequireAuth
      authorize={()=>true}
    >
      <ProjectBody />
    </RequireAuth>}
  >
    <Route path="" element={<ProyectoView />} />
    <Route path="planos" element={<PlanoIndex />} />
    <Route path="planos/registrar" element={<PlanoForm />} />
    <Route path="planos/:planoId/editar" element={<PlanoFormEdit />} />
    <Route path="manzanas" element={<ManzanaIndex />} />
    <Route path="lotes" element={<LoteIndex />} />
    <Route path="lotes/registrar" element={<LoteForm />} />
    <Route path="reservas" element={<ReservasIndex />} />
    <Route path="reservas/registrar" element={<ReservaForm />} />
    <Route path="ventas" element={<VentasIndex />} />
    <Route path="ventas/registrar" element={<VentasForm />} />
  </Route>
}

const mainRoutes = () =>{
  return <Route path="" element={<RequireAuth><MainBody /></RequireAuth>}>
    <Route path="" element={<ProyectosIndex />} />
    <Route path="clientes" element={<ClienteIndex />} />
    <Route path="clientes/:clienteId" element={<ClienteView />} />
    <Route path="vendedores" element={<VendedorIndex />} />
    <Route path="vendedores/registrar" element={<VendedorForm />} />
    <Route path="usuarios" element={<UsuarioIndex />} />
    <Route path="usuarios/registrar" element={<UsuarioForm />} />
    <Route path="usuarios/:usuarioId/editar" element={<UsuarioFormEdit />} />
    <Route path="roles" element={<RolIndex />} />
    <Route path="roles/registrar" element={<RolForm />} />
    <Route path="roles/:rolId" element={<RolView />} />
    <Route path="roles/:rolId/editar" element={<RolFormEdit />} />
    <Route path="proyectos" element={<ProyectosIndex />} />
    <Route path="proyectos/registrar" element={<ProyectoForm />} />
    <Route path="/lista-mora" element={<ListaMoraIndex />} />
    <Route path="/lista-mora/:id" element={<ListaMoraEntryView />} />
    <Route path="/caja" element={<CajaIndex />} />
    <Route path="/caja/registrar" element={<CajaForm />} />
    <Route path="/caja/:id" element={<TransaccionView />} />
    <Route path="/creditos/:id/pagos-extras" element={<PagoExtraForm />} />
    <Route path="/ufv" element={<UfvIndex />} />
    <Route path="/tipos-de-cambio" element={<ExchangeRateIndex />} />
    <Route path="/tipos-de-cambio/registrar" element={<ExchangeRateForm />} />
  </Route>
}

const Redirect404 = ()=>{
  return <Navigate replace to="/404" />
}

export const AppRoutes = () => {
  return <>
    <Routes>
      {/* <Route path="*" element={<Redirect404 />}/> */}
      <Route path="/404" element={"Página no encontrada"}/>
      <Route path="/login" element={<Login />} />
      <Route path="/forbidden" element={"Parece que te has perdido"}/>
      <Route path="/" element={<App />}>
        {mainRoutes()}
        {projectRoutes()}
      </Route>
    </Routes>
  </>
}