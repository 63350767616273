import { User } from "../../../commons/auth"
import * as Permisos from "./permisos"

export class ListaMoraPolicy {
  index = (user: User)=>{
    return true || this.view(user)
  }

  view = (user: User) =>{
    return true || user?.can([
      Permisos.VER_LISTA_DE_MORA
    ])
  }

  register = (user: User) => undefined
}