import { createSlice, Slice } from "@reduxjs/toolkit";
import { User } from "./types";
import { userApi, authApi } from "./authApi";
import { unauthorized } from "../../services/createBaseQuery";


export type AuthState = {user: User|null|undefined, timestamp: number}

export const authSlice = createSlice({
  name: 'auth',
  initialState: ():AuthState => {    
    let cache = localStorage.getItem("user")
    if(cache) return { user: JSON.parse(cache) as User, timestamp: Date.now() }
    else return { user: undefined, timestamp: 0 }
  },
  reducers: {},
  extraReducers: (builder) => {
    builder.addMatcher(
      (action) => authApi.endpoints.login.matchFulfilled(action) || userApi.endpoints.getUser.matchFulfilled(action),
      (state, { payload, meta }) => {
        localStorage.setItem("user", JSON.stringify(payload))
        state.user = payload
        state.timestamp = meta.fulfilledTimeStamp
      }
    ).addMatcher(
      action => authApi.endpoints.logout.matchFulfilled(action) || unauthorized.type === action.type,
      (state) => {
        localStorage.removeItem("user")
        state.user = null
        state.timestamp = Infinity
      }
    )
  },
})


type InferedState<T> = T extends Slice<infer State> ? State : never
type InferedAwareState<T> = T extends Slice<infer State, any, infer Name> ? { [key in Name]: State} : never

export type AuthAwareState = InferedAwareState<typeof authSlice>