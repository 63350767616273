import Decimal from "decimal.js"
import { forwardRef, useState, useImperativeHandle, useRef } from "react"
import { InputGroup, Form, FormControlProps } from "react-bootstrap"
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/esm/helpers"
import { NumericFormat } from "react-number-format"

type Props = Omit<FormControlProps, "type"> & {
  allowEdit?: boolean
}

export const PercentageInput =  forwardRef<HTMLInputElement, ReplaceProps<"input", BsPrefixProps<"input"> & Props>>(({
  as,
  onChange,
  allowEdit,
  readOnly: defaultReadOnly,
  ...props
}: Props, ref)=>{
  const [readOnly, setReadOnly] = useState(defaultReadOnly)
  //@ts-ignore
  if(props.name == "cuotaInicial")console.log(readOnly, props.isInvalid)

  return <NumericFormat
    customInput={Form.Control}
    valueIsNumericString
    className={[/*readOnly ? "" : */"text-end", readOnly && props.isInvalid && "text-danger", props.className].filter(cn=>!!cn).join(" ")}
    suffix={" %"}
    getInputRef={ref}
    decimalScale={2}
    fixedDecimalScale
    readOnly={readOnly}
    plaintext={readOnly}
    placeholder={`-.-- %`}
    onDoubleClick={() => {
      if(allowEdit) setReadOnly(false)
    }}
    onKeyDown={(e)=> {
      if(e.key === "Enter" && allowEdit) setReadOnly(false)
    }}
    onValueChange={(values: any, info: any)=>{
      if(info.event.type == "change" && onChange) onChange(values.value)
    }}
    {...(props as any)}
  />
})
