import { useState } from "react"
import { GroupBase, Props as SelectProps, default as BaseSelect} from "react-select"
import { Rol, useGetRolesQuery } from "../rolesApi"

type Option = Omit<Rol, "permissions">

type Props<
  IsMulti extends boolean = boolean,
  Group extends GroupBase<Option> = GroupBase<Option>
> = SelectProps<Option, IsMulti, Group> & {
  isInvalid?: boolean
  loadingIndicatorTestId?: string
}

export function RolSelect({
  id,
  ...props
}: Props) {
  const [inputValue, setInputValue] = useState("")

  const buscar = useGetRolesQuery({})

  return <BaseSelect
    isClearable
    inputValue={inputValue}
    onInputChange={(input, reason) => {
      if (reason.action === "set-value"
        || reason.action === "input-blur"
        || reason.action === "menu-close") {
            return;
      }
      setInputValue(input);
    }}
    {...props}
    className={["form-control", props.isInvalid ? "is-invalid" : undefined, props.className].filter(cn => !!cn).join(' ')}
    value={props.value}
    isLoading={buscar.isFetching}
    options={buscar.currentData?.records}
    loadingMessage={()=>"Cargando..."}
    placeholder=""
    getOptionValue={(option) => String(option.id)}
    getOptionLabel={(option) => option.name}
    menuPortalTarget={document.body} 
    styles={{
      menuPortal: base => ({ ...base, zIndex: 999 }),
      container: base => ({...base, padding: 0}),
      control: (styles: any)=>{
        delete styles["boxShadow"]
        return {
          ...styles,
          borderRadius: "0.375rem",
          borderWidth: 0,
        }
      },
      ...(props.styles ?? {})
    }}
  />
}