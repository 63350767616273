import { forwardRef, useEffect } from "react"
import Select, { GroupBase, Props as SelectProps } from "react-select"
import {useGetCurrenciesQuery, Filter } from "../services"
import { Currency } from "../types"


type Option = Currency

type Props<IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>> = Omit<SelectProps<Option, IsMulti, Group>, "value"> & {
  value: SelectProps<Option, IsMulti, Group>["value"]
  isInvalid?: boolean
}

export const CurrencySelect = forwardRef<any,Props>((props, ref)=>{

  const buscar = useGetCurrenciesQuery({})

  return <Select
    isClearable
    {...props}
    ref={ref}
    value={typeof props.value === "number" ? undefined : props.value}
    isLoading={buscar.isFetching}
    options={buscar.currentData?.records}
    loadingMessage={()=>"Cargando..."}
    placeholder=""
    getOptionValue={(option) => option.code}
    getOptionLabel={(option) => `${option.name} (${option.code})`}
    className={["form-control", props.isInvalid ? "is-invalid" : undefined, props.className].filter(cn => !!cn).join(' ')}
    menuPortalTarget={document.body}styles={{
      menuPortal: base => ({ ...base, zIndex: 1100 }),
      container: base => ({...base, padding: 0}),
      control: (base, state)=>{
        delete base["boxShadow"]
        return {
          ...base,
          backgroundColor: state.isDisabled ? "#e9ecef" : base.backgroundColor,
          borderRadius: "0.375rem",
          borderWidth: 0,
        }
      },
      multiValue: (base, state) => state.isDisabled ? { ...base, backgroundColor: "rgba(0,0,0,0.1)"} : base,
      ...(props.styles ?? {})
    }}
  />
})