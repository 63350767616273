import { forwardRef, useEffect, useImperativeHandle, useState } from "react"
import { Alert, Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { FaSave } from "react-icons/fa"
import { useChangePasswordMutation } from "../../commons/auth/services"
import { LoadingButton } from "../../commons/components"
import { useServerValidationErrors } from "../../commons/hooks"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"

const schema = yup.object({
  currentPassword: yup.string().label("contraseña actual").required(),
  password: yup.string().label("contraseña nueva").required().min(8),
  passwordConfirmation: yup.string().required("Debe confirmar la contraseña.").oneOf([yup.ref("password")], "La confirmación de la contraseña no coincide."),
})

export type ModalRef = {
  open(): void
}
type FromValues = {
  currentPassword: string
  password: string
  passwordConfirmation: string
}
type Props = {
  defaultShow?: boolean
}
export const ChangePasswordForm = forwardRef<ModalRef, Props>(({
  defaultShow
}, ref) => {
  const [show, setShow] = useState(!!defaultShow)

  useImperativeHandle(ref, () => ({
    open() {
      setShow(true)
    }
  }))

  const {
    formState,
    handleSubmit,
    register,
    setError
  } = useForm<FromValues>({
    defaultValues: {
      currentPassword: "",
      password: "",
      passwordConfirmation: ""
    },
    resolver: yupResolver(schema)
  })


  const [changePassword, changePasswordState] = useChangePasswordMutation()

  useServerValidationErrors(changePasswordState, setError)

  const isSuccess = changePasswordState.isSuccess
  useEffect(()=>{
    if(isSuccess){
      setShow(false)
    }
  }, [isSuccess])

  const renderAlert = () => {
    if (changePasswordState.isError) {
      const error = changePasswordState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <Modal
    centered
    show={show}
    size="sm"
    onHide={() => {
      setShow(false)
    }}
  >
    <Modal.Header>Cambio de contraseña</Modal.Header>
    <Modal.Body>
      <Form aria-label="Formulario de cambio de contraseña" onSubmit={handleSubmit((values)=>{
        changePassword(values)
      })}>
        {renderAlert()}
        <Form.Group data-testid="currentpassword-form-group" className="mb-3" controlId="current-password">
          <Form.Label>Contraseña actual</Form.Label>
          <Form.Control
            type="password"
            {...register("currentPassword")}
            isInvalid={!!formState.errors.currentPassword}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.currentPassword?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group data-testid="newpassword-form-group" className="mb-3" controlId="password">
          <Form.Label>Contraseña nueva</Form.Label>
          <Form.Control
            type="password"
            {...register("password")}
            isInvalid={!!formState.errors.password}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.password?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group data-testid="passwordconfirmation-form-group" className="mb-3" controlId="password-confirmation">
          <Form.Label>Confirmar contraseña</Form.Label>
          <Form.Control
            type="password"
            {...register("passwordConfirmation")}
            isInvalid={!!formState.errors.passwordConfirmation}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.passwordConfirmation?.message}</Form.Control.Feedback>
        </Form.Group>
        <LoadingButton
          type="submit"
          disabled={!formState.isDirty}
          icon={<FaSave style={{position: "relative", top: "-0.125rem"}} />}
          isLoading={changePasswordState.isLoading}
        >
          Guardar
        </LoadingButton>
      </Form>
    </Modal.Body>
  </Modal>
})