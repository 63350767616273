import { Image } from "react-bootstrap"
import { FaHome, FaTicketAlt, FaMap } from "react-icons/fa"
import { MdSpaceDashboard } from "react-icons/md"
import { Outlet } from "react-router-dom"
import { LoteIcon, VentaIcon } from "../../commons/components/icons"
import { SidebarLayout } from "../../commons/layouts/SidebarLayout"
import { useProyectoContext } from "../../features/proyectos/proyectosApi"

export const ProjectBody = () => {

  const proyectoContext = useProyectoContext()

  return <SidebarLayout sidebar={{
    header: <div className="flex-grow-1"><Image src="/logo192.png" style={{width: "100%"}} /></div>,
    items: [
      {
        id: "home/navitem",
        title: "Inicio",
        icon: <FaHome />,
        path: ""
      },
      {
        id: "planos/navitem-group",
        title: "Planos",
        items: [
          {
            id: "revisiones/navitem",
            title: "Planos",
            icon: <FaMap />,
            path: "planos"
          },
          {
            id: "manzanas/navitem",
            title: "Manzanas",
            icon: <MdSpaceDashboard />,
            path: "manzanas"
          },
          {
            id: "lotes/navitem",
            title: "Lotes",
            icon: <LoteIcon />,
            path: "lotes"
          }
        ]
      },
      {
        id: "ventas/navitem-group",
        title: "Ventas",
        items: [
          {
            id: "reservas/navitem",
            title: "Reservas",
            icon: <FaTicketAlt />,
            path: "reservas"
          },
          {
            id: "ventas/navitem",
            title: "Ventas",
            icon: <VentaIcon />,
            path: "ventas"
          }
        ]
      }
    ]
  }}>
    {proyectoContext ? <Outlet /> : null}
  </SidebarLayout>
}