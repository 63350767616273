import Decimal from "decimal.js";
import moment from "moment"
import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { IndexTemplate, TableView } from "../../../commons/components";
import { Page } from "../../../commons/services";
import { useRefetch } from "../../../commons/utils";
import { Filter, useGetTransaccionesQuery } from "../cajaApi";
import { cajaPolicy } from "../policies";
import { RowOptions } from "./RowOptions";

export function CajaIndex(){
  const [page, setPage] = useState<Page>({
    current: 1,
    size: 10
  })
  const [filter, setFilter] = useState<Filter>({})
  const buscar = useGetTransaccionesQuery({
    page,
    filter
  })
  const isRefetching = buscar.isFetching && !buscar.isLoading

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item active>Caja</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      total={buscar.data?.meta.totalRecords ?? -1}
      page={page}
      pageSizes={[10,20,30,50,100]}
      registerPath="registrar"
      policy={cajaPolicy}
      onPageChange={setPage}
      onRefetch={buscar.refetch}
      renderView={()=>{
        return <TableView
          expectedLength={page.size}
          data={isRefetching ? undefined : buscar.currentData?.records}
          isLoading={buscar.isFetching}
          hasError={buscar.isError}
          renderHeaders={()=><tr>
            <th style={{width: 0}}>#</th>
            <th style={{width: 0}}>Id</th>
            <th>Fecha</th>
            <th>Cliente</th>
            <th>Importe</th>
            <th>Estado</th>
            <th style={{width: 0}}></th>
          </tr>}
          renderRow={(item, index)=><tr key={item?.id || index}>
            <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
            <td>{item ? item.id : <Skeleton />}</td>
            <td>{item ? moment(item.fecha, "YYYY-MM-DD").format("L") : <Skeleton />}</td>
            <td>{item ? item.cliente.nombreCompleto : <Skeleton />}</td>
            <td className="text-nowrap text-end">{item ? `${new Decimal(item.importe.amount).toFixed(2)} ${item.importe.currency}` : <Skeleton />}</td>
            <td className={item ? {1: "bg-primary text-white", 2: "bg-danger text-white"}[item.estado] : ""}>{item ? {
              0: "Procesando",
              1: "Registrado",
              2: "Anulado"
            }[item.estado] ?? "" : <Skeleton />}</td>
            <td>{item ? <RowOptions transaccion={item} /> : null}</td>
          </tr>}
        />
      }}
    />
  </div>
}