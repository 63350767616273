import { Alert, Breadcrumb, Spinner } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { useGetRolQuery } from "../rolesApi"
import { RolForm } from "./RolForm"

export function RolFormEdit(){
  const {
    rolId
  } = useParams()
  const getRol = useGetRolQuery(+rolId)

  const renderForm = ()=>{
    if(getRol.isLoading){
      return <div className="text-nowrap">
        <Spinner size="sm" animation="border" className="me-2" />
        Cargando...
      </div>
    }

    if(getRol.error){
      const error = getRol.error as any
      const message: Array<JSX.Element | string> = [
        "Ocurrio un error al realizar la solicitud:",
        <br key="error-br" />,
        error.message ?? error.data?.message ?? "Error de red"
      ]
      return <Alert variant="danger">
        {message}
      </Alert>
    }

    const rol = getRol.currentData

    return <RolForm
        data={rol}
      />
  }

  return <>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{
        to: "../roles"
      }}>Roles</Breadcrumb.Item>
      <Breadcrumb.Item  linkAs={Link} linkProps={{
        to: `../roles/${rolId}`
      }}>{rolId}</Breadcrumb.Item>
      <Breadcrumb.Item active>Editar</Breadcrumb.Item>
    </Breadcrumb>
    {renderForm()}
  </>
}