import { createSelector } from "@reduxjs/toolkit";
import Big from "big.js"
import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseQuery, appApi, PaginatedResponse, PaginationParams } from "../../../commons/services";
import { RawLote, ParsedLote, LoteView, Lote } from "../types";
import { useCallback } from "react";
import { Money } from "../../../commons/money";

export type Filter = {
  numero?: number
  manzanaId?: number
  estado?: number
}

export const lotesApi = appApi.enhanceEndpoints({
  addTagTypes: ["Lotes"]
}).injectEndpoints({
  endpoints: (builder) => ({
    getLotes: builder.query<PaginatedResponse<RawLote>, PaginationParams<Filter> & {proyectoId: number}>({
      query: ({proyectoId, ...params})=>({
        url: `proyectos/${proyectoId}/lotes`,
        params
      }),
      providesTags: (results)=>(results?.records??[]).map(({id})=>({type: "Lotes" as const, id: String(id)})).append({type: "Lotes" as const, id: "*"})
    }),
    registrarLote: builder.mutation<RawLote, {
      proyectoId: number
      manzanaId: number
      categoriaId: number
      numero: string
      geocerca: string
      superficie: string
      precio?: string | null
    }>({
      query: ({proyectoId, ...body}) => ({
        method: "POST",
        url: `proyectos/${proyectoId}/lotes`,
        body
      }),
      invalidatesTags: (result) => result ? [{type: "Lotes" as const, id: "*"}] : []
    })
  })
})


// const originalSelector = lotesApi.endpoints.getLotes.select
// lotesApi.endpoints.getLotes.select = (queryArg)=>createSelector(
//   originalSelector(queryArg),
//   currenciesApi.endpoints.getCurrencies.select(),
//   (queryState, currencies) => {
//     const {data} = queryState
//     const result = {
//       ...queryState,
//       data: data && {
//         ...data,
//         records: data.records.map(lote => ({
//           ...lote,
//           get precio(){
//             return new Money(
//               (lote.precio as any).importe,
//               currencies.data![(lote.precio as any).moneda]
//             )
//           }
//         }))
//       }
//     }
//     return result as typeof queryState
//   }
// ) as any

export function parseLote<Raw extends RawLote>(raw: Raw): ParsedLote<Raw> {
  return {
    ...raw,
    _raw: raw,
    superficie: raw.superficie,
    precio: raw.precio && new Money(raw.precio.amount, raw.precio.currency),
    precioSugerido: new Money(raw.precioSugerido.amount, raw.precioSugerido.currency)
  }
}

export function useParseLote() {
  return useCallback((raw: RawLote): ParsedLote => {
    return parseLote(raw)
  }, [])
}

export const useGetParsedLotesQuery = (...args: Parameters<typeof lotesApi.useGetLotesQuery>)=>{
  const [queryArg, options] = args
  const parseLote = useParseLote()
  return lotesApi.useGetLotesQuery(queryArg, {
    ...options,
    selectFromResult: (result) => {
      const parsedResult = {
        ...result,
        data: result.data && {
          ...result.data,
          records: result.data.records.map(parseLote)
        },
        currentData: result.currentData && {
          ...result.data,
          records: result.currentData.records.map(parseLote)
        }
      }
      return parsedResult
      // return options?.selectFromResult?.(parsedResult) ?? parsedResult
    }
  })
}

export const {
  useGetLotesQuery,
  useRegistrarLoteMutation
} = lotesApi