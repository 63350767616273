import { SVGProps } from "react"

export const MoneySync = (props: SVGProps<SVGSVGElement>)=>{
  // return <svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 512 512" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" {...props}>
  //   <path d="M8.68875 3.12375C7.95605 2.43769 7.00519 2.06161 5.99644 2.0625C4.18102 2.06409 2.61375 3.30886 2.18102 5.03555C2.14952 5.16124 2.03754 5.25 1.90795 5.25H0.564914C0.38918 5.25 0.25568 5.09046 0.288187 4.91775C0.795305 2.22478 3.15968 0.1875 6 0.1875C7.55737 0.1875 8.97166 0.800062 10.0152 1.7973L10.8523 0.960234C11.2066 0.605883 11.8125 0.856852 11.8125 1.35799V4.5C11.8125 4.81066 11.5607 5.0625 11.25 5.0625H8.10799C7.60685 5.0625 7.35588 4.45662 7.71023 4.10224L8.68875 3.12375ZM0.75 6.9375H3.89201C4.39315 6.9375 4.64412 7.54338 4.28977 7.89776L3.31125 8.87627C4.04395 9.56236 4.99488 9.93844 6.00366 9.93752C7.81814 9.93588 9.38604 8.69196 9.81898 6.9645C9.85048 6.83881 9.96246 6.75005 10.092 6.75005H11.4351C11.6108 6.75005 11.7443 6.90959 11.7118 7.0823C11.2047 9.77522 8.84032 11.8125 6 11.8125C4.44263 11.8125 3.02834 11.1999 1.9848 10.2027L1.14773 11.0398C0.793383 11.3941 0.1875 11.1431 0.1875 10.642V7.5C0.1875 7.18934 0.439336 6.9375 0.75 6.9375Z" fill="currentColor"/>
  //   <path d="M6.76406 5.73516L5.49844 5.36484C5.35195 5.32266 5.25 5.18555 5.25 5.0332C5.25 4.84219 5.40469 4.6875 5.5957 4.6875H6.37266C6.51563 4.6875 6.65625 4.73086 6.77344 4.81055C6.84492 4.85859 6.94102 4.84687 7.00195 4.78711L7.40977 4.38867C7.49297 4.30781 7.48125 4.17305 7.38867 4.10156C7.10156 3.87656 6.74297 3.75117 6.375 3.75V3.1875C6.375 3.08437 6.29063 3 6.1875 3H5.8125C5.70937 3 5.625 3.08437 5.625 3.1875V3.75H5.5957C4.84922 3.75 4.24922 4.39102 4.31836 5.15156C4.36758 5.6918 4.78008 6.13125 5.30039 6.28359L6.50156 6.63516C6.64805 6.67852 6.75 6.81445 6.75 6.9668C6.75 7.15781 6.59531 7.3125 6.4043 7.3125H5.62734C5.48438 7.3125 5.34375 7.26914 5.22656 7.18945C5.15508 7.14141 5.05898 7.15313 4.99805 7.21289L4.59023 7.61133C4.50703 7.69219 4.51875 7.82695 4.61133 7.89844C4.89844 8.12344 5.25703 8.24883 5.625 8.25V8.8125C5.625 8.91563 5.70937 9 5.8125 9H6.1875C6.29063 9 6.375 8.91563 6.375 8.8125V8.24766C6.92109 8.23711 7.4332 7.9125 7.61367 7.3957C7.86563 6.67383 7.44258 5.9332 6.76406 5.73516Z" fill="currentColor"/>
  //   <path d="M8.68875 3.12375C7.95605 2.43769 7.00519 2.06161 5.99644 2.0625C4.18102 2.06409 2.61375 3.30886 2.18102 5.03555C2.14952 5.16124 2.03754 5.25 1.90795 5.25H0.564914C0.38918 5.25 0.25568 5.09046 0.288187 4.91775C0.795305 2.22478 3.15968 0.1875 6 0.1875C7.55737 0.1875 8.97166 0.800062 10.0152 1.7973L10.8523 0.960234C11.2066 0.605883 11.8125 0.856852 11.8125 1.35799V4.5C11.8125 4.81066 11.5607 5.0625 11.25 5.0625H8.10799C7.60685 5.0625 7.35588 4.45662 7.71023 4.10224L8.68875 3.12375ZM0.75 6.9375H3.89201C4.39315 6.9375 4.64412 7.54338 4.28977 7.89776L3.31125 8.87627C4.04395 9.56236 4.99488 9.93844 6.00366 9.93752C7.81814 9.93588 9.38604 8.69196 9.81898 6.9645C9.85048 6.83881 9.96246 6.75005 10.092 6.75005H11.4351C11.6108 6.75005 11.7443 6.90959 11.7118 7.0823C11.2047 9.77522 8.84032 11.8125 6 11.8125C4.44263 11.8125 3.02834 11.1999 1.9848 10.2027L1.14773 11.0398C0.793383 11.3941 0.1875 11.1431 0.1875 10.642V7.5C0.1875 7.18934 0.439336 6.9375 0.75 6.9375Z" stroke="black"/>
  //   <path d="M6.76406 5.73516L5.49844 5.36484C5.35195 5.32266 5.25 5.18555 5.25 5.0332C5.25 4.84219 5.40469 4.6875 5.5957 4.6875H6.37266C6.51563 4.6875 6.65625 4.73086 6.77344 4.81055C6.84492 4.85859 6.94102 4.84687 7.00195 4.78711L7.40977 4.38867C7.49297 4.30781 7.48125 4.17305 7.38867 4.10156C7.10156 3.87656 6.74297 3.75117 6.375 3.75V3.1875C6.375 3.08437 6.29063 3 6.1875 3H5.8125C5.70937 3 5.625 3.08437 5.625 3.1875V3.75H5.5957C4.84922 3.75 4.24922 4.39102 4.31836 5.15156C4.36758 5.6918 4.78008 6.13125 5.30039 6.28359L6.50156 6.63516C6.64805 6.67852 6.75 6.81445 6.75 6.9668C6.75 7.15781 6.59531 7.3125 6.4043 7.3125H5.62734C5.48438 7.3125 5.34375 7.26914 5.22656 7.18945C5.15508 7.14141 5.05898 7.15313 4.99805 7.21289L4.59023 7.61133C4.50703 7.69219 4.51875 7.82695 4.61133 7.89844C4.89844 8.12344 5.25703 8.24883 5.625 8.25V8.8125C5.625 8.91563 5.70937 9 5.8125 9H6.1875C6.29063 9 6.375 8.91563 6.375 8.8125V8.24766C6.92109 8.23711 7.4332 7.9125 7.61367 7.3957C7.86563 6.67383 7.44258 5.9332 6.76406 5.73516Z" stroke="black"/>
  // </svg> 
  return <svg stroke="currentColor" fill="none" strokeWidth="0" width="1em" height="1em" viewBox="0 0 12 12" xmlns="http://www.w3.org/2000/svg" {...props}>
    <path d="M1.7592 1.76093C1.12157 2.39822 0.635614 3.1714 0.337521 4.02291C0.0394281 4.87443 -0.0631281 5.78234 0.0374868 6.67907C0.0768322 7.02974 0.392453 7.28205 0.742445 7.24263C1.09244 7.20321 1.34427 6.88698 1.30492 6.53631C1.22573 5.83057 1.30645 5.11602 1.54105 4.44586C1.77566 3.7757 2.15867 3.16663 2.6605 2.66507C4.45339 0.868732 7.32516 0.817487 9.17123 2.50852L8.82256 2.51628C8.47045 2.52412 8.19136 2.81647 8.19918 3.16925C8.207 3.52204 8.49879 3.80168 8.8509 3.79384L10.7533 3.75148C11.0998 3.74376 11.3768 3.46005 11.3768 3.1127V1.24905C11.3768 0.896174 11.0913 0.610113 10.7391 0.610113C10.3869 0.610114 10.1014 0.896174 10.1014 1.24905V1.6301C7.7563 -0.587462 4.05744 -0.541147 1.7592 1.76093Z" fill="currentColor"/>
    <path d="M1.25543 8.23431C0.90884 8.24203 0.63189 8.52575 0.63189 8.87309V10.7367C0.63189 11.0896 0.917402 11.3757 1.2696 11.3757C1.6218 11.3757 1.90731 11.0896 1.90731 10.7367V10.3774C4.25525 12.5857 7.94437 12.5344 10.2395 10.2349C10.8731 9.59825 11.3569 8.82802 11.6557 7.98031C11.9545 7.1326 12.0608 6.22877 11.9668 5.33468C11.9299 4.98375 11.6161 4.72923 11.2658 4.76619C10.9156 4.80315 10.6615 5.11761 10.6984 5.46854C10.7725 6.17357 10.6887 6.88629 10.4531 7.55475C10.2175 8.22308 9.83608 8.83034 9.33663 9.3323C7.53947 11.1325 4.66229 11.1809 2.81466 9.47779L3.18612 9.46951C3.53823 9.46167 3.81733 9.16933 3.8095 8.81654C3.80168 8.46375 3.50989 8.18412 3.15778 8.19196L1.25543 8.23431Z" fill="currentColor"/>
    <path d="M7.01818 5.64687L5.33068 5.15313C5.13537 5.09688 4.99943 4.91406 4.99943 4.71094C4.99943 4.45625 5.20568 4.25 5.46037 4.25H6.49631C6.68693 4.25 6.87443 4.30781 7.03068 4.41406C7.12599 4.47813 7.25412 4.4625 7.33537 4.38281L7.87912 3.85156C7.99006 3.74375 7.97443 3.56406 7.85099 3.46875C7.46818 3.16875 6.99006 3.00156 6.49943 3V2.25C6.49943 2.1125 6.38693 2 6.24943 2H5.74943C5.61193 2 5.49943 2.1125 5.49943 2.25V3H5.46037C4.46506 3 3.66506 3.85469 3.75724 4.86875C3.82287 5.58906 4.37287 6.175 5.06662 6.37813L6.66818 6.84688C6.86349 6.90469 6.99943 7.08594 6.99943 7.28906C6.99943 7.54375 6.79318 7.75 6.53849 7.75H5.50256C5.31193 7.75 5.12443 7.69219 4.96818 7.58594C4.87287 7.52188 4.74474 7.5375 4.66349 7.61719L4.11974 8.14844C4.00881 8.25625 4.02443 8.43594 4.14787 8.53125C4.53068 8.83125 5.00881 8.99844 5.49943 9V9.75C5.49943 9.8875 5.61193 10 5.74943 10H6.24943C6.38693 10 6.49943 9.8875 6.49943 9.75V8.99687C7.22756 8.98281 7.91037 8.55 8.15099 7.86094C8.48693 6.89844 7.92287 5.91094 7.01818 5.64687Z" fill="currentColor"/>
  </svg>



}