import Decimal from "decimal.js"
import { Breadcrumb, Card, Col, Row, Table } from "react-bootstrap"
import { FaWhatsapp } from "react-icons/fa"
import { Link, useLocation } from "react-router-dom"
import { ListaMoraEntry } from "../../../commons/services/types"

export const ListaMoraEntryView = ()=>{
  const location = useLocation()
  const locationState = location.state as {
    entry: ListaMoraEntry
  }

  const {
    cliente,
    creditos
  } = locationState.entry

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../lista-mora" }}>Lista de mora</Breadcrumb.Item>
      <Breadcrumb.Item active>{cliente.id}</Breadcrumb.Item>
    </Breadcrumb>
    <h2 className="fs-5 mb-2">Cliente</h2>
    <Row className="gx-2 mb-2">
      <Col sm={3}>
        <div className="fw-semibold mb-2">{cliente.documentoIdentidad.tipoText}:</div>
        <div>{cliente.documentoIdentidad.numero}</div>
      </Col>
      <Col sm={6}>
        <div className="fw-semibold mb-2">Nombre:</div>
        <div>{cliente.nombreCompleto}</div>
      </Col>
      <Col sm={3}>
        <div className="fw-semibold mb-2">Teléfono:</div>
        <div><a 
          target="_blank"
          href={`https://wa.me/${cliente.telefono}/?text=Estimado(a)%20${cliente.nombre.trim().toLocaleLowerCase().split(/\s+/).map(w => w[0].toLocaleUpperCase() + w.substring(1)).join(" ")},`}
        >
          <FaWhatsapp className="me-1 mt-n1" />
          {cliente.telefono}
        </a></div>
      </Col>
    </Row>
    <h2 className="fs-5 mb-2">Créditos en mora</h2>
    {creditos.map(credito=>{
      return <Card key={credito.id} className="mb-2">
        <Card.Header>Crédito {credito.id}</Card.Header>
        <Card.Body>
          <Row className="gx-2 mb-2">
            <Col sm={3}>
              <div className="fw-semibold mb-2">Fecha:</div>
              <div>{credito.fecha}</div>
            </Col>
            <Col sm={6}>
              <div className="fw-semibold mb-2">Proyecto:</div>
              <div>{credito.proyecto.nombre}</div>
            </Col>
            <Col sm={3}>
              <div className="fw-semibold mb-2">Lote:</div>
              <div>Mz {credito.manzana.numero}, Lt {credito.lote.numero}</div>
            </Col>
          </Row>
          <h3 className="fs-6 mb-2">Cuotas vencidas</h3>
          <Table>
            <thead>
              <tr>
                <th>#</th>
                <th>Vencimiento</th>
                <th>Saldo pendiente</th>
                <th>Multa</th>
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              {credito.cuotasVencidas.map(cuota=>{
                return <tr key={`${credito.id}/${cuota.numero}`}>
                  <th scope="row">{cuota.numero}</th>
                  <td>{cuota.vencimiento}</td>
                  <td>{new Decimal(cuota.saldo.amount).toFixed(2)} {cuota.saldo.currency}</td>
                  <td>{new Decimal(cuota.multa.amount).toFixed(2)} {cuota.multa.currency}</td>
                  <td>{new Decimal(cuota.total.amount).toFixed(2)} {cuota.saldo.currency}</td>
                </tr>
              })}
            </tbody>
          </Table>
        </Card.Body>
      </Card>
    })}
  </div>
}