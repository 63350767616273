import { Alert, Breadcrumb, Spinner } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { useGetPlanoQuery } from "../planosApi"
import { PlanoForm } from "./PlanoForm"

export const PlanoFormEdit = ()=>{
  const {proyectoId, planoId} = useParams()

  const getPlano = useGetPlanoQuery({proyectoId, planoId})

  const renderForm = ()=>{
    if(getPlano.isLoading){
      return <div className="text-nowrap">
        <Spinner size="sm" animation="border" className="me-2" />
        Cargando...
      </div>
    }

    if(getPlano.error){
      const error = getPlano.error as any
      const message: Array<JSX.Element | string> = [
        "Ocurrio un error al realizar la solicitud:",
        <br key="error-br" />,
        error.message ?? error.data?.message ?? "Error de red"
      ]
      return <Alert variant="danger">
        {message}
      </Alert>
    }

    const plano = getPlano.currentData

    return <PlanoForm 
      plano={plano}
    />
  }

  return <>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../planos" }}>Planos</Breadcrumb.Item>
      <Breadcrumb.Item active>{planoId}</Breadcrumb.Item>
      <Breadcrumb.Item active>Actualizacion</Breadcrumb.Item>
    </Breadcrumb>
    {renderForm()}
  </>
}