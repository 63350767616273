import { forwardRef, useImperativeHandle, useState } from "react"
import { Form, Modal } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { useParams } from "react-router-dom"
import { LoadingButton } from "../../../commons/components"
import { useRegistrarManzanasMutation } from "../services"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useServerValidationErrors } from "../../../commons/hooks"


type FormValues = {
  numero: string
}

const schema = yup.object({
  numero: yup.string().label("número").required()
})

export const ManzanaFormModal = forwardRef<{open():void}, {defaultShow?: boolean}>((props, ref)=>{
  const {proyectoId} = useParams()

  const [show, setShow] = useState(!!props.defaultShow)

  useImperativeHandle(ref, ()=>({
    open: ()=>setShow(true)
  }))

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<FormValues>({
    resolver: yupResolver(schema)
  })

  const [create, createState] = useRegistrarManzanasMutation()

  useServerValidationErrors(createState, setError)

  return <Modal show={show} onHide={()=>setShow(false)} centered>
    <Modal.Body>
      <Form onSubmit={handleSubmit(async (values)=>{
        try{
          await create({
            numero: values.numero,
            proyectoId: +proyectoId
          }).unwrap()
          reset()
          setShow(false)
        }
        catch {}
      })}>
        <Form.Group className="mb-3" controlId="numero">
          <Form.Label>Número</Form.Label>
          <Form.Control
            {...register("numero")}
            isInvalid={!!formState.errors.numero}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.numero?.message}</Form.Control.Feedback>
        </Form.Group>
        <LoadingButton
          type="submit"
          isLoading={createState.isLoading}
        >
          Guardar
        </LoadingButton>
      </Form>
    </Modal.Body>
  </Modal>
})