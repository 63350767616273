import { useMemo, useState } from "react"
import Select, { MultiValue, Props as SelectProps } from "react-select"
import permissions from "../../../configs/permissions.json"

type PermissionGroups = keyof typeof permissions["groups"]

// export const permissions: {
//   tag: PermissionGroups
//   name: string,
//   includes?: string[]
// }[] = [
//   {
//     tag: "Usuarios",
//     name: "Ver usuarios",
//   },
//   {
//     tag: "Usuarios",
//     name: "Registrar usuarios",
//     includes: [
//       "Ver proyectos",
//       "Ver vendedores",
//     ]
//   },
//   {
//     tag: "Usuarios",
//     name: "Editar usuarios",
//     includes: [
//       "Ver proyectos",
//       "Ver vendedores",
//     ]
//   },
//   {
//     tag: "Usuarios",
//     name: "Activar/Desactivar usuarios",
//   },
//   {
//     tag: "Roles",
//     name: "Ver roles",
//   },
//   {
//     tag: "Roles",
//     name: "Registrar roles",
//   },
//   {
//     tag: "Roles",
//     name: "Editar roles",
//   },
//   {
//     tag: "Roles",
//     name: "Eliminar roles",
//   },
//   {
//     tag: "Proyectos",
//     name: "Ver proyectos",
//   },
//   {
//     tag: "Proyectos",
//     name: "Registrar proyectos",
//   },
//   {
//     tag: "Proyectos",
//     name: "Editar proyectos",
//   },
//   {
//     tag: "Proyectos",
//     name: "Archivar/Desarchivar proyectos",
//   },
// ]

type Option = {
  name: string,
  includes?: string[]
}

type OptionsGroup = {
  options: Option[],
  label: PermissionGroups
}

type Props = SelectProps<Option, true, OptionsGroup> & {
  isInvalid?: boolean
}

export function PermissionSelect(props: Props){
  const [inputValue, setInputValue] = useState("")

  const options = useMemo(()=>{
    return Object.keys(permissions.groups).map((k)=>{
      return {
        label: k as PermissionGroups,
        options: permissions.groups[k as PermissionGroups].map((name) => ({
          name 
        }))
      }
    })
  }, [])

  // const value = new Set(props.value as Option[])
  // const extra = permissions.filter(p => (props.value as Option[]).some(o => o.includes?.includes(p.name)))
  // extra.forEach(e => value.add(e))

  return <Select
    isMulti
    isClearable
    inputValue={inputValue}
    onInputChange={(input, reason) => {
      if (reason.action === "set-value"
        || reason.action === "input-blur"
        || reason.action === "menu-close") {
            return;
      }
      setInputValue(input);
    }}
    placeholder=""
    {...props}
    // value={Array.from(value)}
    options={options}
    getOptionLabel={(option) => option.name }
    getOptionValue={(option) => option.name }
    className={["form-control", props.isInvalid ? "is-invalid" : undefined, props.className].filter(cn => !!cn).join(' ')}
    menuPortalTarget={document.body} 
    styles={{
      menuPortal: base => ({ ...base, zIndex: 999 }),
      container: base => ({...base, padding: 0}),
      control: (base, state)=>{
        delete base["boxShadow"]
        return {
          ...base,
          backgroundColor: state.isDisabled ? "#e9ecef" : base.backgroundColor,
          borderRadius: "0.375rem",
          borderWidth: 0,
        }
      },
      multiValue: (base, state) => state.isDisabled ? { ...base, backgroundColor: "rgba(0,0,0,0.1)"} : base,
      ...(props.styles ?? {})
    }}
    // onChange={(value, action)=>{
    //   console.log(value, action)
    //   if(action.action === "select-option"){
    //     value = [
    //       ...props.value as any,
    //       action.option
    //     ]
    //   }
    //   else if(action.action === "remove-value"){
    //     value = (props.value as MultiValue<Option>).filter(o => o.name !== action.removedValue?.name)
    //   }
    //   props.onChange?.(value, action)
    // }}
  />
}