import { useState } from "react"
import ReactDOM from "react-dom"
import {Dropdown} from "react-bootstrap"
import {Link} from "react-router-dom"
import { FaEye, FaTimes } from "react-icons/fa"
import {VerticalEllipsisDropdownToggle} from "../../../commons/components"
import { ClienteView } from "../services/clientesApi"

type Props = {
  cliente: ClienteView
}

export function RowOptions({
  cliente
}:Props){
  const options: React.ReactElement[] = [
    <Dropdown.Item key="detalles" as={Link} to={`../clientes/${cliente.id}`}><span className="me-2"><FaEye className="me-1 mt-n1" /></span><span>Detalles</span></Dropdown.Item>,
  ]
  return <>
    <Dropdown>
      <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
      {ReactDOM.createPortal(
        <Dropdown.Menu style={{textTransform: 'none'}}>
          {options}
        </Dropdown.Menu>
      , document.body)}
    </Dropdown>
  </>
}