import { configureStore } from '@reduxjs/toolkit'
import { setupListeners } from '@reduxjs/toolkit/query'
import { sidebarVisibility, containerFluid } from '../commons/layouts/reducer'
import { authApi } from '../commons/auth/services/authApi'
import { authSlice } from '../commons/auth/services/authSlice'
import { appApi } from '../commons/services'
import { Money } from '../commons/money'
import Decimal from 'decimal.js'
import moment from 'moment'
import { currenciesApi } from '../features/currencies/services'

export const store = configureStore({
  reducer: {
    sidebarVisibility,
    containerFluid,
    // Add the generated reducer as a specific top-level slice
    [authSlice.name]: authSlice.reducer,
    [authApi.reducerPath]: authApi.reducer,
    [appApi.reducerPath]: appApi.reducer,
  },
  // Adding the api middleware enables caching, invalidation, polling,
  // and other useful features of `rtk-query`.
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({      
      serializableCheck: false,
    }).concat(
      authApi.middleware,
      appApi.middleware,
    ),
})

// configure listeners using the provided defaults
setupListeners(store.dispatch)

Money.exchangeProvider = {
  async exchange(from, to, amount, date?, direct=true) {
    if(from == to) return amount
    if(!direct){
      [from, to] = [to, from]
    }
    date = date ?? moment().format("YYYY-MM-DD")
    const queryAction = currenciesApi.endpoints.getExchangeRates.initiate({filter:{source: from, target: to, date}}, {
      forceRefetch: false
    })
    const exchangeRate = (await store.dispatch(queryAction).unwrap()).records[0]
    if(!exchangeRate) throw new Error(`Currency exchange rate does not found (${from}, ${to}, ${date}, ${direct}).`)

    const decimalAmount = new Decimal(amount)
    if(direct && exchangeRate.indirect || !direct && !exchangeRate.indirect){
      return decimalAmount.dividedBy(exchangeRate.rate).toString()
    }
    else {
      return decimalAmount.times(exchangeRate.rate).toString()
    }
  }
}