import { Card, Col, Row } from "react-bootstrap"

type Props<T> = {
  data?: T[]
  expectedLength: number
  isLoading: boolean
  hasError: boolean
  renderItemHeader: (item: T|null, index: number) => React.ReactNode
  renderItemBody: (item: T|null, index: number) => React.ReactNode
}

export function GridView<T extends { id: number | string}>(props: Props<T>) {

  function* loadingIterator(){
    for(let i = 0; i < props.expectedLength; i++) yield null
  }

  const renderCards = ()=>{
    if (props.hasError) {
      return <Col><div className="bg-danger text-light text-center">Ocurrió un error al realizar la solicitud</div></Col>
    }
    if(props.data?.length === 0){
      return <Col><div className="bg-light text-center">No se encontraron resultados</div></Col>
    }
    const data = props.data || (props.isLoading && loadingIterator())
    if(data){
      return Array.from(data).map((item, index)=>(
        <Col key={item ? item.id : index} className="mb-2" lg={4} md={6}>
          <Card>
            <Card.Header><div className="text-nowrap text-truncate">{props.renderItemHeader(item, index)}</div></Card.Header>
            <Card.Body>
              {props.renderItemBody(item, index)}
            </Card.Body>
          </Card>
        </Col>
      ))
    }
    return null
  }

  return <Row>
    {renderCards()}
  </Row>
}