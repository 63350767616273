import Decimal from "decimal.js"
import moment from "moment"
import { useEffect, useState } from "react"
import { Button, Form, Modal } from "react-bootstrap"
import { useWatch, useController, Control } from "react-hook-form"
import { FaEdit, FaEraser } from "react-icons/fa"
import { MdOutlineDataExploration } from "react-icons/md"
import { Money, MoneyInput } from "../../../commons/money"
import { Pagable } from "../types"


export const PagableRow = ({
  index,
  moneda,
  name,
  value,
  onChange
}: {
  index: number
  moneda: string
  name: string
  value: {
    pagable: Pagable
    cargo: Money
    cargoMonedaPago: Money
    abono: Money
    abonoMonedaPago: Money
  }
  onChange(value: {
    pagable: Pagable
    cargo: Money
    cargoMonedaPago: Money
    abono: Money
    abonoMonedaPago: Money
  }): void
}) =>{

  const {abono: importe, abonoMonedaPago, pagable, cargoMonedaPago} = value

  const [isEditing, setEditing] = useState(false)

  const [innerValue, setInnerValue] = useState<Money>(importe)

  const total = value.cargo

  useEffect(()=>{
    if(isEditing && !innerValue.amount?.eq(importe.amount!)){
      setInnerValue(importe)
    }
  }, [isEditing])

  

  // const totalMonedaPago = total.exchangeTo(monedaPago)
  // const pagoMonedaPago = importe.exchangeTo(monedaPago)
  
  const [innerValueMonedaPago, setInnerValueMonedaPago] = useState<Money>(abonoMonedaPago)

  useEffect(()=>{
    if(isEditing){
      let cancel = false;
      (async function(){
        const valueMP = await innerValue.exchangeTo(moneda)
        if(!cancel){
          setInnerValueMonedaPago(valueMP)
        }
      })()
      
      setInnerValueMonedaPago(new Money(null, moneda))
      return ()=>{
        cancel = true
      }
    }
  }, [String(innerValue), moneda])

  const renderEditModal = ()=>{
    return <Modal centered show={isEditing} onHide={() => setEditing(false)}>
      {/* <Modal.Header>{`${pagable.type}-${pagable.id}`}</Modal.Header> */}
      <Modal.Body>
        <Form.Group className="mb-3" controlId={`${name}.abono`}>
          <Form.Label>Saldo a pagar</Form.Label>
          <MoneyInput 
            value={innerValue}
            onChange={(value) => {
              setInnerValue(value.amount ? 
                value.amount?.lte(pagable.total) ? 
                  value : 
                  new Money(pagable.total, innerValue.currency) : 
                new Money("0", value.currency)
              )
            }}
          />
        </Form.Group>
        
        {moneda !== pagable.moneda && <Form.Group className="mb-3" controlId={`${name}.abonomp`}>
          <Form.Label>Importe en la moneda de pago</Form.Label>
          <Form.Control 
            value={innerValueMonedaPago.toString()}
            readOnly
            plaintext
          />
        </Form.Group>}
        <Button type="button"
          disabled={!innerValueMonedaPago.amount}
          onClick={()=>{
          if(innerValueMonedaPago.amount){
            setEditing(false)
            onChange({
              ...value,
              abono: innerValue,
              abonoMonedaPago: innerValueMonedaPago
            })
          }
        }}>Aceptar</Button>
      </Modal.Body>
    </Modal>
  }

  return <>
    <tr>
      <td>{index+1}</td>
      <td>{pagable.referencia}</td>
      <td>{moment(pagable.vencimiento).format('L')}</td>
      <td className="text-nowrap text-end">{total.toFixed(2)}</td>
      <td className="text-nowrap text-end" title={String(cargoMonedaPago)}>{cargoMonedaPago.amount ? cargoMonedaPago.toFixed(2) : "Convirtiendo..."}</td>
      <td className="text-nowrap text-end">
        <span title={String(abonoMonedaPago)}>{abonoMonedaPago.amount ? abonoMonedaPago.toFixed(2) : "Convirtiendo..."}</span>
        <FaEdit role="button" aria-label="Editar"            
          style={{position: "relative", top: "-0.125rem"}}
          className="text-primary ms-2"
          onClick={()=>{
            setEditing(true)
          }}
        />
        <FaEraser role="button" aria-label="Borrar"
          className="text-danger ms-1"            
          style={{position: "relative", top: "-0.125rem"}}
          onClick={()=>{
            onChange({
              ...value,
              abono: new Money("0", importe.currency),
              abonoMonedaPago: new Money("0", moneda)
            })
          }}
        />
      </td>
      <td className="text-nowrap text-end">{total.sub(importe).toFixed(2)}</td>
    </tr>
    {renderEditModal()}
  </>
}