import { Table } from "react-bootstrap"

type Props<T> = {
  data?: T[]
  errorMessage?: string
  expectedLength: number
  isLoading: boolean
  hasError: boolean
  renderRow: (item: T | null, index: number) => React.ReactElement
  renderHeaders: () => React.ReactElement
}

export function TableView<T>(props: Props<T>) {

  function* loadingIterator(){
    for(let i = 0; i < props.expectedLength; i++) yield null
  }

  const renderRows = ()=>{
    if (props.hasError) {
      return <tr>
        <td className="bg-danger text-light text-center" colSpan={100}>
          {props.errorMessage ? props.errorMessage : "Ocurrió un error al realizar la solicitud"}
        </td>
      </tr>
    }
    if(props.data?.length === 0){
      return <tr>
        <td className="bg-light text-center" colSpan={100}>
          No se encontraron resultados
        </td>
      </tr>
    }
    const data = props.data || (props.isLoading && loadingIterator())
    if(data){
      return Array.from(data).map(props.renderRow)
    }
    return null
  }

return <Table aria-label="indice" responsive>
  <thead>
    {props.renderHeaders()}
  </thead>
  <tbody>
    {renderRows()}
  </tbody>
</Table>
}