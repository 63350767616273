import { createApi } from "@reduxjs/toolkit/query/react"
import { apiBaseQuery, appApi, authBaseQuery } from "../../services";
import { User } from "./types";

export const authApi = createApi({
  reducerPath: "authApi",
  baseQuery: authBaseQuery(),
  tagTypes: ['Auth'],
  refetchOnMountOrArgChange: 30,
  endpoints: (build) => ({
    // getUser: build.query<User, void>({
    //   query: ()=>"user",
    //   providesTags: (results, error) => [{ type: 'Auth', id: "USER" }]
    // }),
    login: build.mutation<User, {username: string, password: string, rememberMe: boolean}>({
      query: (body) => ({
        method: "POST",
        url: "login",
        body
      })
    }),
    logout: build.mutation<void, void>({
      query: () => ({        
        method: "POST",
        url: "logout"
      })
    }),
    changePassword: build.mutation<void, {
      currentPassword: string
      password: string
      passwordConfirmation: string
    }>({
      query: (body) => ({        
        method: "POST",
        url: "change-password",
        body
      })
    })
  })
})

export const userApi = appApi.enhanceEndpoints({
  addTagTypes: ['User']}).injectEndpoints({
  endpoints: (build) => ({
    getUser: build.query<User, void>({
      query: ()=>"user",
      providesTags: () => [{ type: 'User' }]
    })
  })
})

export const {
  useLoginMutation,
  useLogoutMutation,
  useChangePasswordMutation
} = authApi
export const { useGetUserQuery } = userApi
// import { authClient, apiClient } from "../../../commons/api"

// export type User = {
//   id: number
//   username: string
//   estado: boolean
//   ciRaiz: number
//   ciComplemento: string
//   ci: string
//   apellidoPaterno: string
//   apellidoMaterno: string
//   nombres: string
//   nombreCompleto: string
//   roles: {
//     id: number
//     name: string
//     permissions: {
//       id: number
//       name: string
//     }[]
//   }[]
//   createdAt: string
//   updatedAt: string
//   regionalId: number
//   regional: {
//     id: number
//     nombre: string
//   }
// }

// export const AuthService = {
//   login: async (username: string, password: string, remember_me: boolean) => {
//     await authClient.get("sanctum/csrf-cookie")
//     return await authClient.post<User>("login", {
//       username,
//       password,
//       remember_me
//     })
//   },
//   logout: () => {
//     return authClient.post("logout")
//   },
//   fetch: () => {
//     return apiClient.get<User>("user")
//   }
// }