
export function sidebarVisibility(state=false, action: any){
  if(action.type == "TOGGLE_SIDEBAR"){
    return !state
  }
  if(action.type == "OPEN_SIDEBAR"){
    return true
  }
  if(action.type == "CLOSE_SIDEBAR"){
    return false
  }
  return state
}

export function containerFluid(state=false, action: any){
  if(action.type == "CHANGE_CONTAINER_FLUID"){
    return action.payload
  }
  return state
}