import { createApi } from "@reduxjs/toolkit/dist/query/react";
import moment from "moment";
import { apiBaseQuery, appApi, PaginatedResponse, PaginationParams } from "../../commons/services";
import { Money } from "../../commons/money";
import { parseLote } from "../lotes/services";
import { ParsedReserva, RawReserva, Reserva } from "./types";

export type Filter = {

}

export const parseReserva = (reserva: RawReserva): ParsedReserva =>{
  const moneda = reserva.importe.currency
  return {
    _raw: reserva,
    ...reserva,
    fecha: moment(reserva.fecha, "YYYY-MM-DD"),
    vencimiento: moment(reserva.vencimiento, "YYYY-MM-DD"),
    importe: new Money(reserva.importe.amount, moneda),
    lote: parseLote(reserva.lote),
    saldoContado: new Money(reserva.saldoContado.amount, moneda),
    saldoCredito: new Money(reserva.saldoCredito.amount, moneda),
  }
}

export const useReservaParser = ()=>{

  return (reserva: RawReserva): ParsedReserva => {
    return parseReserva(reserva)
  }
}

export const reservaApi = appApi.enhanceEndpoints({
  addTagTypes: ["Reservas"],
}).injectEndpoints({
  endpoints: (build)=>({
    getReservas: build.query<PaginatedResponse<RawReserva>, PaginationParams<Filter> & {proyectoId: number}>({
      query: ({proyectoId, ...params})=>({
        url: `proyectos/${proyectoId}/reservas`,
        params
      }),
      providesTags: (results) => [
        ...(results?.records ?? []).map(r => ({type: "Reservas" as const, id: r.id})),
        {type: "Reservas", id: "*"}
      ]
    }),

    registrarReserva: build.mutation<RawReserva, Omit<Reserva, "id" | "importe" | "saldoContado" | "saldoCredito" | "observaciones"> & {
      importe:  string
      saldoContado:  string
      saldoCredito:  string
    }>({
      query: ({proyectoId, ...body})=>({
        url: `proyectos/${proyectoId}/reservas`,
        method: "POST",
        body
      }),
      invalidatesTags: [{type: "Reservas", id: "*"}]
    })
  })
})

export const {
  useGetReservasQuery,
  useRegistrarReservaMutation
} = reservaApi