import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { IndexTemplate, TableView } from "../../../commons/components";
import { Page } from "../../../commons/services";
import { rolPolicy } from "../policies";
import { useGetRolesQuery } from "../rolesApi";
import { RowOptions } from "./RowOptions";

export function RolIndex(){
  const [page, setPage] = useState<Page>({
    current: 1,
    size: 10
  })

  const buscar = useGetRolesQuery({
    page
  })
  const isRefetching = !buscar.isLoading && buscar.isFetching

  return <>
    <Breadcrumb>
      <Breadcrumb.Item active>Roles</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      total={buscar.data?.meta.totalRecords ?? -1}
      onPageChange={setPage}
      onRefetch={buscar.refetch}
      registerPath="registrar"
      policy={rolPolicy}
      renderView={()=>{
        return <TableView
          expectedLength={page.size}
          data={isRefetching ? undefined : buscar.currentData?.records}
          isLoading={buscar.isFetching}
          hasError={buscar.isError}
          renderHeaders={()=><tr>
            <th style={{width: 0}}>#</th>
            <th className="text-nowrap">Nombre</th>
            <th className="text-nowrap">Descripción</th>
            <th style={{width: 0}}></th>
          </tr>}
          renderRow={(item, index)=><tr key={item?.id || index}>
            <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
            <td>{item ? item.name : <Skeleton />}</td>
            <td>{item ? item.description : <Skeleton />}</td>
            <td>{item ? <RowOptions rol={item} /> : null}</td>
          </tr>}
        />
      }}
    />
  </>
}