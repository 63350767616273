import Decimal from "decimal.js"
import { useRef, useState } from "react"
import { Breadcrumb } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { useParams } from "react-router-dom"
import { IndexTemplate, TableView } from "../../../commons/components"
import { Page } from "../../../commons/services/types"
import { lotePolicy } from "../policies"
import { useGetLotesQuery } from "../services"

export const LoteIndex = ()=>{
  const { proyectoId } = useParams() as any

  const [page, setPage] = useState<Page>({
    current: 1,
    size: 10
  })

  const [search, setSearch] = useState("")

  const formModalRef = useRef<{open():void}>(null)

  const getLotes = useGetLotesQuery({ search, page, proyectoId })
  // const [isRefetching, refetch] = useRefetch(getManzanas)
  const isRefetching = getLotes.isFetching && !getLotes.isLoading

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item active>Lotes</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      policy={lotePolicy}
      registerPath="registrar"
      page={page}
      total={getLotes.currentData?.meta.totalRecords ?? -1}
      onPageChange={setPage}
      onSearch={setSearch}
      onRefetch={getLotes.refetch}
      renderView={()=>{
        return <TableView
          expectedLength={page.size}
          isLoading={getLotes.isFetching}
          data={isRefetching ? undefined : getLotes.currentData?.records}
          hasError={getLotes.isError}
          renderHeaders={()=>{
            return <tr>
              <th>#</th>
              <th>N.º de manzana</th>
              <th>N.º de lote</th>
              <th>Superficie</th>
              <th>Precio</th>
              <th>Estado</th>
              <th></th>
            </tr>
          }}
          renderRow={(item, index)=>{
            const precio = item ? item.precio ?? item.precioSugerido : null
            return <tr key={item?.id ?? index}>
              <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
              <td style={{textTransform: "none"}}>{item ? `Mz. ${item.manzana.numero}` : <Skeleton />}</td>
              <td style={{textTransform: "none"}}>{item ? `Lt. ${item.numero}` : <Skeleton />}</td>
              <td style={{textTransform: "none"}}>{item ? `${item.superficie} m²` : <Skeleton />}</td>
              <td>{precio ? `${new Decimal(precio.amount).toFixed(2)} ${precio.currency}` : <Skeleton />}</td>
              <td>{item ? {
                1: "Disponible",
                2: "No disponible",
                3: "Reservado",
                4: "Vendido"
              }[item.estado] : <Skeleton />}</td>
              <td></td>
            </tr>
          }}
        />
      }}
    />
  </div>
}