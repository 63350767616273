import { yupResolver } from "@hookform/resolvers/yup"
import { Alert, Breadcrumb, Col, Form, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { FaCheckCircle, FaSave } from "react-icons/fa"
import { Link } from "react-router-dom"
import * as yup from "yup"
import { LoadingButton } from "../../../commons/components"
import { useServerValidationErrors } from "../../../commons/hooks"
import { useRegistrarVendedorMutation } from "../services/vendedoresApi"

const schema = yup.object().shape({
  numeroDocumento: yup.string().label("carnet de identidad").required(),
  nombre: yup.string().required(),
  apellidoPaterno: yup.string().label("apellido paterno").when("apellidoMaterno",{
    is: "",
    then: schema => schema.required("Debe ingresar al menos un apellido.")
  }),
  apellidoMaterno: yup.string().label("apellido materno").when("apellidoPaterno",{
    is: "",
    then: schema => schema.required("Debe ingresar al menos un apellido.")
  }),
  telefono: yup.string().label("teléfono").required()
}, [
  ["apellidoPaterno", "apellidoMaterno"],
  ["apellidoMaterno", "apellidoPaterno"]
])

type FormValues = {
  numeroDocumento: string
  nombre: string
  apellidoPaterno: string
  apellidoMaterno: string
  telefono: string
}

export function VendedorForm(){

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<FormValues>({
    defaultValues:{
      numeroDocumento: "",
      nombre: "",
      apellidoPaterno: "",
      apellidoMaterno: "",
      telefono: ""
    },
    resolver: yupResolver(schema)
  })

  const [create, createState] = useRegistrarVendedorMutation()
  const mutationState = createState

  useServerValidationErrors(mutationState, setError)

  const renderAlert = () => {
    if (mutationState.isSuccess) {
      return <Alert className="text-nowrap" variant="success">
        <FaCheckCircle className="me-1" style={{ position: "relative", top: "-0.125rem" }} />
        La solicitud se completó exitosamente.
      </Alert>
    }
    if (mutationState.isError) {
      const error = mutationState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <>
  <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../vendedores" }}>Vendedores</Breadcrumb.Item>
      <Breadcrumb.Item active>Registro</Breadcrumb.Item>
    </Breadcrumb>
  <Form aria-label="Formulario de registro de vendedores" onSubmit={handleSubmit((values)=>{
    create(values)
      .unwrap()
      .then(()=>{
        reset()
      })
      .catch(()=>{
        
      })
  })}>
    {renderAlert()}
    <Row className="gx-3 mb-3">
      <Form.Group as={Col} md={4} data-testid="ci-form-group" controlId="ci">
        <Form.Label>Carnet de identidad</Form.Label>
        <Form.Control
          {...register("numeroDocumento")}
          isInvalid={!!formState.errors.numeroDocumento}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.numeroDocumento?.message}</Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row className="gx-3 mb-3">
      <Form.Group as={Col} md={4} data-testid="apellidopaterno-form-group" controlId="apellidopaterno">
        <Form.Label>Apellido paterno</Form.Label>
        <Form.Control
          {...register("apellidoPaterno")}
          isInvalid={!!formState.errors.apellidoPaterno}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.apellidoPaterno?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md={4} data-testid="apellidomaterno-form-group" controlId="apellidomaterno">
        <Form.Label>Apellido materno</Form.Label>
        <Form.Control
          {...register("apellidoMaterno")}
          isInvalid={!!formState.errors.apellidoMaterno}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.apellidoMaterno?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group as={Col} md={4} data-testid="nombre-form-group" controlId="nombre">
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          {...register("nombre")}
          isInvalid={!!formState.errors.nombre}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.nombre?.message}</Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row className="gx-3 mb-3">
      <Form.Group as={Col} md={4} data-testid="telefono-form-group" controlId="telefono">
        <Form.Label>Teléfono</Form.Label>
        <Form.Control
          {...register("telefono")}
          isInvalid={!!formState.errors.telefono}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.telefono?.message}</Form.Control.Feedback>
      </Form.Group>
    </Row>
    <Row>
      <Col xs>
        <LoadingButton
          type="submit"
          isLoading={mutationState.isLoading}
          disabled={!formState.isDirty || !!Object.keys(formState.errors).length}
          icon={<FaSave className="mt-n1" />}
        >
          Guardar
        </LoadingButton>
      </Col>
    </Row>
  </Form>
  </>
}