import { string } from "yup";
import { appApi, PaginatedResponse, PaginationParams } from "../../commons/services";
import { Currency } from "./types";

export type Filter = {
  date?: string
  source?: string
  target?: string
}

export type ExchangeRate = {
  id: number
  validFrom: string|null
  source: string
  target: string
  rate: string
  indirect: boolean
}

export type RawExchangeRate = Omit<ExchangeRate, "source" | "target"> & {
  source: Currency
  target: Currency
}

export const currenciesApi = appApi.enhanceEndpoints({
  addTagTypes: ["Currencies", "ExchangeRates"]
}).injectEndpoints({
  endpoints: (builder) => ({
    getCurrencies: builder.query<PaginatedResponse<Currency>, PaginationParams<{}>|void>({
      query: (params) => ({
        url: "currencies",
        params: params ?? {}
      }),
      providesTags: (results)=>(results?.records??[])
        .map(({code: id})=>({type: "Currencies" as const, id}))
        .append({type: "Currencies" as const, id: "*"})
    }),
    getExchangeRates: builder.query<PaginatedResponse<RawExchangeRate>, PaginationParams<Filter>>({
      query: (params) => ({
        url: "exchange-rates",
        params
      }),
      providesTags: (results)=>(results?.records??[])
        .map(({id})=>({type: "ExchangeRates" as const, id: String(id)}))
        .append({type: "ExchangeRates" as const, id: "*"})
    }),

    registrarExchangeRate: builder.mutation<RawExchangeRate, Omit<ExchangeRate, "id">>({
      query: (body) =>({
        method: "POST",
        url: "exchange-rates",
        body
      }),
      invalidatesTags: (results)=>results ? [{type: "ExchangeRates", id: "*"}] : []
    }),
    actualizarExchangeRate: builder.mutation<RawExchangeRate, ExchangeRate>({
      query: ({id, ...body}) =>({
        method: "PUT",
        url: "exchange-rates/"+id,
        body
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const result = await queryFulfilled
          for(let tag of (getState().appApi.provided.ExchangeRates[args.id] ?? [])){
            const regex = /(?<endpoint>.*)\((?<queryArgs>.*)\)/
            const {groups: {endpoint, queryArgs}} = regex.exec(tag) as any as {groups: {endpoint: string, queryArgs: string}}
            if(endpoint === "getExchangeRates"){
              dispatch(currenciesApi.util.updateQueryData(endpoint, JSON.parse(queryArgs), (draft) => {
                Object.assign(draft, {
                  records: draft.records.map(r => {
                    return r.id === args.id ? result.data : r
                  }),
                })
              }))
            }
          }
        }
        catch (e) { }
      }
    }),
    eliminarExchangeRate: builder.mutation<void, number>({
      query: (id)=>({
        method: "DELETE",
        url: `exchange-rates/${id}`
      }),
      // invalidatesTags: (result, error, args)=> !error ? [{type: "ExchangeRates", id: String(args)}] : []
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const result = await queryFulfilled
          for(let tag of (getState().appApi.provided.ExchangeRates[args] ?? [])){
            const regex = /(?<endpoint>.*)\((?<queryArgs>.*)\)/
            const {groups: {endpoint, queryArgs}} = regex.exec(tag) as any as {groups: {endpoint: string, queryArgs: string}}
            if(endpoint === "getExchangeRates"){
              dispatch(currenciesApi.util.updateQueryData(endpoint, JSON.parse(queryArgs), (draft) => {
                Object.assign(draft, {
                  records: draft.records.filter(r => {
                    return r.id !== args
                  }),
                })
              }))
            }
          }
        }
        catch (e) { }
      }
    })
  })
})

export const {
  useGetCurrenciesQuery,

  useGetExchangeRatesQuery,
  useRegistrarExchangeRateMutation,
  useActualizarExchangeRateMutation,
  useEliminarExchangeRateMutation
} = currenciesApi

// export const useCurrencies = () => {
//   const empty: Record<string, Currency> = {}
//   return useGetCurrenciesQuery(undefined, {
//     selectFromResult: (result) =>{
//       return result.data || empty
//     }
//   })
// }