import { skipToken } from "@reduxjs/toolkit/dist/query"
import { useEffect, useRef, useState } from "react"
import { Button, Col, Modal, Nav, Navbar, NavDropdown, Row, Tab } from "react-bootstrap"
import { FaBars, FaCog, FaHome, FaUserCircle } from "react-icons/fa"
import { useDispatch } from "react-redux"
import { Link, NavLink, useLocation, useParams } from "react-router-dom"
import { useUser } from "../../commons/auth"
import { useLogoutMutation } from "../../commons/auth/services"
import useBreakpointResolver from "../../commons/hooks/useBreakpoints"
import { CategoriaIndex } from "../../features/categorias/components/CategoriaIndex"
import { GeneralSettingsForm, PreferencesForm } from "../../features/proyectos/components"
import { useGetProyectoQuery } from "../../features/proyectos/proyectosApi"
import { ChangePasswordForm } from "./ChangePasswordForm"
import "./Header.css"

export const Header = ()=>{

  const [showSettings, setShowSettings] = useState(false)

  const user = useUser()

  const params = useParams<{proyectoId: string}>()

  const skipRequest = !params.proyectoId
  const getProyecto = useGetProyectoQuery(skipRequest ? skipToken : parseInt(params.proyectoId))
  // console.log(getProyecto)

  const proyectoActual = getProyecto.data
  // const logoutModal = useModalActions("dialog")

  const [logout, logoutState] = useLogoutMutation()

  const dispatch = useDispatch()

  const changePasswordModal = useRef<{
    open():void
  }>(null)

  useEffect(()=>{
    // if(logoutState.isError){
    //   logoutModal.open({
    //     type: "error",
    //   })
    // }
    // else if(logoutState.isSuccess){
    //   logoutModal.close()
    // }
  }, [logoutState])

  const breakpointResolver = useBreakpointResolver()

  return <>
    <Navbar variant="dark" bg="primary" className="px-2 justify-content-between flex-nowrap" expand="sm">
      <Nav style={{flexFlow: "nowrap"}}>
        <Navbar.Toggle as={Button} aria-controls="sidebar-wrapper" onClick={()=>{
          dispatch({type: "TOGGLE_SIDEBAR"})
        }}>
          <FaBars />
        </Navbar.Toggle>
        <Navbar.Brand as={Link} to="/" className={params.proyectoId ? "d-none d-sm-inline" : ""}>El Dorado Bienes Raices</Navbar.Brand>
      </Nav>

      {params.proyectoId ? <Nav className="project-menu px-4 mx-lg-5">
        <NavDropdown align="end" title={<><span>Proyecto: </span><span className="project-name ps-1">{proyectoActual?.nombre}</span></>}>
          <NavDropdown.Item onClick={()=>{
            setShowSettings(true)
          }}><FaCog/> Ajustes del proyecto</NavDropdown.Item>
          <NavDropdown.Item as={Link} to="/"><FaHome/> Otros projectos</NavDropdown.Item>
        </NavDropdown>
      </Nav> : null}

      <Nav>
      {
        user === null ? <Nav.Link as={Link} className="text-nowrap" to="/login">Iniciar sesión</Nav.Link> :
        user.isReady ? <NavDropdown 
          align="end"
          title={<>
            <FaUserCircle />
            <span className="ms-1 d-none d-sm-inline ml-1">{user.username}</span>
          </>} id="collasible-nav-dropdown">
          <NavDropdown.Item onClick={()=>{
            changePasswordModal.current?.open()
          }}>Cambiar contraseña</NavDropdown.Item>
          <NavDropdown.Divider />
          <NavDropdown.Item as={Button} className="btn-link" onClick={()=>{
            // logoutModal.open({
            //   type: "loader"
            // })
            logout()
          }} >Cerrar sesión</NavDropdown.Item>
        </NavDropdown> : null
      }
      </Nav>
    </Navbar>
    
    <Modal show={showSettings} onHide={()=>setShowSettings(false)} centered size="xl">
        <Modal.Header>Ajustes</Modal.Header>
        <Modal.Body>
          <Tab.Container id="project-settings-tabs" defaultActiveKey="general">
            <Row className="gx-3">
              <Col lg={3} className={breakpointResolver("lg") ? "" : "mb-2"}>
                <Nav variant={breakpointResolver("lg") ? "pills" : "tabs"} className="flex-lg-column">
                  <Nav.Item>
                    <Nav.Link eventKey="general">General</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="categorias">Categorías</Nav.Link>
                  </Nav.Item>
                  <Nav.Item>
                    <Nav.Link eventKey="preferencias">Preferencias</Nav.Link>
                  </Nav.Item>
                </Nav>
              </Col>
              <Col lg={9}>
                <Tab.Content>
                  <Tab.Pane eventKey="general">
                    <GeneralSettingsForm />
                  </Tab.Pane>
                  <Tab.Pane eventKey="categorias">
                    <CategoriaIndex />
                  </Tab.Pane>
                  <Tab.Pane eventKey="preferencias">
                    <PreferencesForm />
                  </Tab.Pane>
                </Tab.Content>
              </Col>
            </Row>
          </Tab.Container>
        </Modal.Body>
    </Modal>
    <ChangePasswordForm ref={changePasswordModal} />
  </>
}