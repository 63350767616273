import { useState } from "react"
import { Breadcrumb } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { IndexTemplate, TableView } from "../../../commons/components"
import { Page } from "../../../commons/services"
import { clientePolicy } from "../policies"
import { useGetClientesQuery } from "../services/clientesApi"
import { RowOptions } from "./RowOptions"

export const ClienteIndex = ()=>{
  const [page, setPage] = useState<Page>({
    current: 1,
    size: 10
  })

  const [filter, setFilter] = useState({})

  const buscar = useGetClientesQuery({page, filter})
  const isRefetching = buscar.isFetching && !buscar.isLoading

  return <>
    <Breadcrumb>
      <Breadcrumb.Item active>Clientes</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
        policy={clientePolicy}
        page={page}
        total={buscar.currentData?.meta.totalRecords ?? -1}
        onPageChange={setPage}
        onRefetch={buscar.refetch}
        // registerPath="registrar"
        renderView={()=>{
          return <TableView
            expectedLength={page.size}
            data={isRefetching ? undefined : buscar.currentData?.records}
            isLoading={buscar.isFetching}
            hasError={buscar.isError}
            renderHeaders={()=><tr>
              <th style={{width: 0}}>#</th>
              <th className="text-nowrap">Documento de identidad</th>
              <th className="text-nowrap">Nombre y apellido</th>
              <th className="text-nowrap">Teléfono</th>
              <th style={{width: 0}}></th>
            </tr>}
            renderRow={(item, index)=><tr key={item?.id || index}>
              <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
              <td>{item ? `${item.documentoIdentidad.tipoText} ${item.documentoIdentidad.numero}` : <Skeleton />}</td>
              <td>{item ? item.nombreCompleto : <Skeleton />}</td>
              <td>{item ? <a
                target="_blank"
                href={`https://wa.me/${item.telefono}/?text=Estimado(a)%20${item.nombre.trim().toLocaleLowerCase().split(/\s+/).map(w => w[0].toLocaleUpperCase() + w.substring(1)).join(" ")},`}
              >
                {item.telefono}
              </a> : <Skeleton />}</td>
              {/* <td>{item ? item.riesgoMora ? "Sí" : "No" : <Skeleton />}</td> */}
              <td>{item ? <RowOptions cliente={item} /> : null}</td>
            </tr>}
          />
        }}
    />
  </>
}