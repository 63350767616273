import { forwardRef, useImperativeHandle, useRef, useState } from "react"
import { Alert, Col, Form, Modal, Row } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { LoadingButton } from "../../../commons/components"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useServerValidationErrors } from "../../../commons/hooks"
import moment from "moment"
import Decimal from "decimal.js"
import { UfvIndexParams, useRegistrarUfvMutation } from "../ufvApi"

const schema = yup.object({
  fecha: yup.date().undefined().required(),
  valor: yup.string().required().test("numeric", "El campo ${path} debe ser un valor númerico", function(value){
    try{
      value && new Decimal(value)
    }catch(e) {
      return false
    }
    return true
  })
})

export type UfvFormModalRef = {
  open():void
}

type FormValues = {
  fecha: Date
  valor: string
}

type Props = {
  // onSaved?: () => void
  defaultShow?: boolean
}

export const UfvFormModal = forwardRef<UfvFormModalRef, Props>((props, ref)=>{

  const [show, setShow] = useState(!!props.defaultShow)

  useImperativeHandle(ref, ()=>({
    open: ()=>setShow(true)
  }))

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<FormValues>({
    resolver: yupResolver(schema)
  })

  const saveAnother = useRef(false)

  const [create, createState] = useRegistrarUfvMutation()

  useServerValidationErrors(createState, setError)

  
  const renderAlert = ()=>{
    const alerts = []
    if(createState.isError) {
      const error = createState.error as any
      const message: Array<JSX.Element|string> = ["Ocurrio un error al realizar la solicitud"]
      if(error.status != 422) message.push(":", <>
        <br/>
        {error.message ?? error.data.message}
      </>)
      else message.push(".")
      alerts.push(<Alert key="error" variant="danger">
        {message}
      </Alert>)
    }
    if(createState.isSuccess){
      alerts.push(<Alert key="success" variant="success">
        La solicitud se completo exitosamente
      </Alert>)
    }
    return alerts
  }

  return <Modal show={show} onHide={()=>setShow(false)} centered>
    <Modal.Body>
      <Form onSubmit={handleSubmit((values)=>{
        create({
          fecha: moment(values.fecha).format("YYYY-MM-DD"),
          valor: values.valor,
          // _meta: {
          // // Como invalidariamos despues el indice si solo cerramos el modal?
          //   shouldInvalidateIndex: !saveAnother.current
          // }
        }).unwrap().then(()=>{
          reset()
          !saveAnother.current && setShow(false)
          // props.onSaved?.()
        }, () => { })
      })}>
        {renderAlert()}
        <Form.Group data-testid="fecha-form-group" className="mb-3" controlId="fecha">
          <Form.Label>Fecha</Form.Label>
          <Form.Control
            {...register("fecha")}
            type="date"
            isInvalid={!!formState.errors.fecha}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.fecha?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group data-testid="valor-form-group" className="mb-3" controlId="valor">
          <Form.Label>Valor</Form.Label>
          <Form.Control
            {...register("valor")}
            isInvalid={!!formState.errors.valor}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.valor?.message}</Form.Control.Feedback>
        </Form.Group>
        <Row className="gx-2">
          <Col xs="auto">
            <LoadingButton
              type="submit"
              onClick={()=>saveAnother.current=false}
              isLoading={createState.isLoading}
            >
              Guardar
            </LoadingButton>
          </Col>
          <Col xs="auto">
            <LoadingButton
              type="submit"
              isLoading={createState.isLoading}
              onClick={()=>saveAnother.current=true}
            >
              Guardar y continuar
            </LoadingButton>
          </Col>
        </Row>
      </Form>
    </Modal.Body>
  </Modal>
})