import { yupResolver } from "@hookform/resolvers/yup"
import moment from "moment"
import { Modal, Form, Alert } from "react-bootstrap"
import { useForm } from "react-hook-form"
import { LoadingButton } from "../../../commons/components"
import { RawTransaccion } from "../types"
import { useAnularTransaccionMutation } from "../cajaApi"
import * as yup from "yup"
import { useServerValidationErrors } from "../../../commons/hooks"

type FormValues = {
  fecha: string,
  motivo: string
}

const schema = yup.object({
  motivo: yup.string().required()
})

export function AnulacionFormModal({
  transaccion,
  ...props
}: {
  transaccion: RawTransaccion
  show: boolean
  onHide?: () => void
}){

  const {
    formState,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<FormValues>({
    defaultValues: {
      fecha: transaccion.anulacion?.fecha ?? moment().format("YYYY-MM-DD"),
      motivo: transaccion.anulacion?.motivo ?? ""
    },
    resolver: yupResolver(schema)
  })
  
  const [anular, anularState] = useAnularTransaccionMutation();

  useServerValidationErrors(anularState, setError)

  const renderAlert = () => {
    // if (anularState.isSuccess) {
    //   return <Alert className="text-nowrap" variant="success">
    //     <FaCheckCircle className="me-1 mt-n1" />
    //     La solicitud se completó exitosamente.
    //   </Alert>
    // }
    if (anularState.isError) {
      const error = anularState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <Modal
    centered
    {...props}
  >
    <Modal.Body>
      <Form onSubmit={handleSubmit((values)=>{
        anular({
          transaccionId: transaccion.id,
          motivo: values.motivo
        }).unwrap()
          .then((anulacion)=>{
            reset({
              fecha: anulacion.fecha,
              motivo: anulacion.motivo
            })
            props.onHide?.()
          },()=>{ })
      })}>
        {renderAlert()}
        <Form.Group className="mb-3" controlId="fecha">
          <Form.Label>Fecha</Form.Label>
          <Form.Control
            disabled
            type="date"
            {...register("fecha")}
          />
        </Form.Group>
        <Form.Group className="mb-3" data-testid="motivo-form-group" controlId="motivo">
          <Form.Label>Motivo</Form.Label>
          <Form.Control
            as="textarea"
            disabled={!!transaccion.anulacion}
            {...register("motivo")}
            isInvalid={!!formState.errors.motivo}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.motivo?.message}</Form.Control.Feedback>
        </Form.Group>
        {!transaccion.anulacion ? <LoadingButton
          type="submit"
          disabled={!formState.isDirty || !!Object.keys(formState.errors).length}
          isLoading={anularState.isLoading}
        >
          Guardar
        </LoadingButton> : null}
      </Form>
    </Modal.Body>
  </Modal>
}