import { User } from "../../../commons/auth"
import * as Permisos from "./permisos"

export class ReservaPolicy {
  index = (user: User)=>{
    return true || this.view(user) || this.register(user)
  }

  view = (user: User) =>{
    return true || user?.can([
      Permisos.VER_RESERVAS
    ])
  }
  
  register = (user: User)=>{
    return true || user?.can([
      Permisos.REGISTRAR_RESERVA
    ])
  }
}