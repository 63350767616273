import Big, { BigSource } from "big.js"
import Decimal from "decimal.js"
import moment, { Moment } from "moment"
import React, { useEffect, useMemo, useRef } from "react"
import { Table } from "react-bootstrap"
import { Money } from "../../../commons/money"
import { PlanPagosBuilder } from "../utils"

type Props = {
  fecha: Date | string
  diaPago: number
  prestamo: Money
  plazo: number
  periodicidad: number
  tasa: string
  cuotaInicial: Money
  onComputed: (pago: Money, total: Money) => void
}

export const TablaAmortizacion = React.memo(({
  fecha,
  diaPago,
  prestamo,
  plazo,
  periodicidad,
  tasa,
  cuotaInicial,
  onComputed,
}: Props)=>{

  const C0 = useMemo(()=>cuotaInicial.round(2), [cuotaInicial])
  const P = useMemo(()=>prestamo.round(2), [prestamo])
  const S0 = useMemo(()=>P.sub(C0), [P, C0])

  const cuotas = useMemo(() => {
    const n = plazo/periodicidad
    if(!S0.amount || !Number.isInteger(n) || n <= 0 || !fecha) return []
    
    // // const pago = S0.mul(r).div(Big(1).sub(r.add(1).pow(-n)))
    // const pago = S0.mul(getFCR(moment(fecha, "YYYY-MM-DD"), tasa, n, periodicidad, diaPago))

    // return pago.round(2)
    const builder = new PlanPagosBuilder(
      moment(fecha, "YYYY-MM-DD"),
      S0.amount,
      new Decimal(tasa), 
      plazo,
      periodicidad,
      diaPago
    )
    
    return builder.build()
  }, [ fecha, S0, tasa, plazo, periodicidad, diaPago])

  const [ pago, total ] = useMemo(()=>{
    if(cuotas.length){
      const n = plazo/periodicidad - 1
      const pago = new Money(cuotas[0].pago, prestamo.currency)
      const last = new Money(cuotas[n].pago, prestamo.currency)
      const total =  pago.mul(String(n)).add(last).add(C0)
      return [pago, total]
    }

    return [new Money(null, prestamo.currency), new Money(null, prestamo.currency)]
  }, [cuotas])


  useEffect(()=>{
    onComputed(pago, total)
  }, [pago, total])

  const moneda = P.currency

  const renderBody = ()=>{
    if(!cuotas.length) return <tbody></tbody>
    const n = plazo/periodicidad - 1
    const pago = new Money(cuotas[0].pago, prestamo.currency)
    const last = new Money(cuotas[n].pago, prestamo.currency)
    const total =  pago.mul(String(n)).add(last).add(C0)
    return <tbody>
    <tr>
      <th className="position-sticky bg-white border-end" style={{width: 0, left: 0}} scope="row">0</th>
      <td>{moment(fecha, "YYYY-MM-DD").format("DD-MM-YYYY")}</td>
      <td>-</td>
      <td className="text-end">{C0.toFixed(2)}</td>
      <td className="text-end"> - {moneda}</td>
      <td className="text-end">{C0.toFixed(2)}</td>
      <td className="text-end">{S0.toFixed(2)}</td>
    </tr>
    {cuotas.map((cuota, index) => {
      return <tr key={cuota.numero}>
        <th className="position-sticky bg-white border-end" style={{width: 0, left: 0}} scope="row">{cuota.numero}</th>
        <td>{cuota.vencimiento.format("DD-MM-YYYY")}</td>
        <td>{cuota.diasTranscurridos}</td>
        <td className="text-end">{cuota.pago.toFixed(2)} {moneda}</td>
        <td className="text-end">{cuota.interes.toFixed(2)} {moneda}</td>
        <td className="text-end">{cuota.amortizacion.toFixed(2)} {moneda}</td>
        <td className="text-end">{cuota.saldo.toFixed(2)} {moneda}</td>
      </tr>
    })}
  </tbody>
  }

  return <div style={{maxHeight: "24rem", overflow: "auto"}}>
  <Table aria-label="Tabla de amortización" className="text-nowrap table-bordered" style={{borderCollapse: "separate", borderSpacing: 0}}>
    <thead className="position-sticky bg-white shadow-sm" style={{top: 0, zIndex: 1}}>
      <tr>
        <th className="position-sticky bg-white border-end" style={{width: 0, left: 0}}>#</th>
        <th style={{width: 0}}>Fecha</th>
        <th style={{width: 0}}>Días</th>
        <th className="text-end" style={{width: "25%"}}>Cuota</th>
        <th className="text-end" style={{width: "25%"}}>Interes</th>
        <th className="text-end" style={{width: "25%"}}>Amortización</th>
        <th className="text-end" style={{width: "25%"}}>Saldo</th>
      </tr>
    </thead>
    {renderBody()}
    {cuotas.length ? <tfoot className="position-sticky bg-white" style={{bottom: 0, boxShadow: "0 -0.125rem 0.25rem rgb(0 0 0 / 8%)"}}>
      <tr>
        <th className="position-sticky bg-white border-end text-end" style={{width: 0, left: 0}} scope="row">Σ</th>
        <td></td>
        <td>{cuotas.reduce((suma, current)=>suma + current.diasTranscurridos,0)}</td>
        <td className="text-end">{total.toFixed(2)}</td>
        <td className="text-end">{total.sub(P).toFixed(2)}</td>
        <td className="text-end">{P.toFixed(2)}</td>
        <td></td>
      </tr>
    </tfoot> : null}
  </Table>
</div>
})