import { appApi, PaginatedResponse, PaginationParams } from "../../commons/services";
import { Money } from "../../commons/services/types";

export type CreditoFilter = {

}

export type Cuota = {
  id: number
  numero: number
  vencimiento: string
  dias: number
  importe: Money
  interes: Money
  pagoExtra: Money
  amortizacion: Money
  saldoCapital: Money

  saldo: Money
  multa: Money
  total: Money
  vencida: boolean
  activa: boolean
  adelantable: boolean
}

export type Credito = {
  id: number
  codigo: number
  estado: number
  fecha: string
  importe: Money
  cuotaInicial: Money
  tasaInteres: string
  tasaMora: string
  plazo: number
  periodoPago: number
  diaPago: number
  urlPlanPago: string
  urlHistorialPagos: string
  cuotas: Cuota[]
}

export type PagoExtraBody = {
  tipoAjuste: number
  periodo: number
  importe: string
  creditoId: number
}

export const creditosApi = appApi.enhanceEndpoints({
  addTagTypes: ['Creditos'],
}).injectEndpoints({
  endpoints: (build) => ({
    getCredito: build.query<Credito, number>({
      query: (id)=>({
        url: `creditos/${id}`
      })
    }),

    habilitarPagos: build.mutation<void, {
      creditoId: number
      cuota: number
    }>({
      query: ({creditoId, cuota})=>({
        method: "PUT",
        url: `creditos/${creditoId}/cuotas/${cuota}/habilitar-pagos`
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled
          const clientesProvidedTags = getState().appApi.provided.Clientes
          for(let id in clientesProvidedTags){
            for(let tag of (clientesProvidedTags[id] ?? [])){
              const regex = /(?<endpoint>.*)\((?<queryArgs>.*)\)/
              const {groups: {endpoint, queryArgs}} = regex.exec(tag) as any
              if(endpoint === "getCliente"){
                dispatch(creditosApi.util.updateQueryData(endpoint, JSON.parse(queryArgs), (draft: any) => {
                  Object.assign(draft, {
                    creditos: draft.creditos.map(({credito, ...venta}: any) => {
                      return {
                        ...venta,
                        credito: {
                          ...credito,
                          cuotas: credito.cuotas.map((cuota: any) =>{
                            if(credito.codigo == args.creditoId && cuota.numero == args.cuota){
                              cuota.adelantable = 1
                            }
                            return cuota
                          })
                        }
                      }
                    }),
                  })
                }))
              }
            }
          }
        }
        catch (e) {
          // console.error(e)
        }
      },
    }),
    programarPagoExtra: build.mutation<Credito, PagoExtraBody>({
      query: ({creditoId: id, ...body}) => ({
        method: "POST",
        url: `creditos/${id}/pagos-extras`,
        body
      }),
      async onQueryStarted(body, { dispatch, queryFulfilled }) {
        try {
          const { data } = await queryFulfilled
          dispatch(creditosApi.util.updateQueryData('getCredito', body.creditoId, () => data))
        } catch (err) { }
      },
    })
  })
})

export const {
  useGetCreditoQuery,
  useHabilitarPagosMutation,
  useProgramarPagoExtraMutation
} = creditosApi