import { createApi } from "@reduxjs/toolkit/query/react"
import { apiBaseQuery, appApi, PaginatedResponse, PaginationParams } from "../../../commons/services"

export type Vendedor = {
  id: number
  numeroDocumento: string
  apellidoPaterno: string
  apellidoMaterno: string
  nombre: string
}

export type VendedorView = Vendedor & {
  nombreCompleto: string
}

export type Filter = {
}

export const vendedoresApi = appApi.enhanceEndpoints({
  addTagTypes: ["Vendedores"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getVendedores: builder.query<PaginatedResponse<VendedorView>, PaginationParams<Filter>>({
      query: (params) => ({
        url: "vendedores",
        params
      }),
      providesTags: (results) => [
        ...(results?.records.map(c => ({ type: "Vendedores" as const, id: c.id })) || []),
        { type: "Vendedores", id: "*" }
      ]
    }),

    registrarVendedor: builder.mutation<VendedorView, Omit<Vendedor, "id">>({
      query: (body)=>({
        method: "POST",
        url: `vendedores`,
        body
      }),
      invalidatesTags: (results) => results ? [{ type: "Vendedores", id: "*" }] : []
    })
  })
})

export const {
  useGetVendedoresQuery,
  useLazyGetVendedoresQuery,
  useRegistrarVendedorMutation
} = vendedoresApi