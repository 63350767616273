import { appApi, PaginatedResponse, PaginationParams } from "../../../commons/services";
import { Manzana, ManzanaView } from "../types";

export type Filter = {
  numero?: number
}

export const manzanasApi = appApi.enhanceEndpoints({addTagTypes: ["Manzanas"]}).injectEndpoints({
  endpoints: (builder) => ({
    getManzanas: builder.query<PaginatedResponse<ManzanaView>, PaginationParams<Filter> & {proyectoId: number}>({
      query: ({proyectoId, ...params})=>({
        url: `proyectos/${proyectoId}/manzanas`,
        params
      }),
      providesTags: (results)=>(results?.records??[]).map(({id})=>({type: "Manzanas" as const, id: String(id)})).append({type: "Manzanas" as const, id: "*"})
    }),
    registrarManzanas: builder.mutation<ManzanaView, Omit<Manzana, "id">>({
      query: ({proyectoId, ...body}) => ({
        method: "POST",
        url: `proyectos/${proyectoId}/manzanas`,
        body
      }),
      invalidatesTags: (result) => result ? [{type: "Manzanas" as const, id: "*"}] : []
    })
  })
})

export const {
  useGetManzanasQuery,
  useLazyGetManzanasQuery,
  useRegistrarManzanasMutation
} = manzanasApi