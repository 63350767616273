import ReactDOM from "react-dom"
import { Dropdown, Spinner } from "react-bootstrap"
import { VerticalEllipsisDropdownToggle } from "../../../commons/components"
import { FaBan, FaCheck, FaEdit, FaUserEdit } from "react-icons/fa"
import { RawUsuario, useChangeUserStatusMutation } from "../usuariosApi"
import { store } from "../../../bootstrap/store"
import { Link } from "react-router-dom"

type Props = {
  usuario: Omit<RawUsuario, "vendedor" | "proyectos">
}

export function RowOptions({
  usuario
}: Props) {

  const [changeStatus, changeStatusState] = useChangeUserStatusMutation()

  const options: React.ReactElement[] = [
    <Dropdown.Item key="editar" as={Link} to={`${usuario.id}/editar`}>
      <FaUserEdit className="me-2" style={{ position: "relative", top: "-0.125rem" }} />
      Editar
    </Dropdown.Item>
  ]
  if (usuario.estado == 1) {
    const icon = <FaBan className="me-2" style={{ position: "relative", top: "-0.125rem" }} />
    options.push(
      <Dropdown.Item key="desactivar" onClick={() => {
        console.log(store.getState())
        changeStatus({
          id: usuario.id,
          status: 2
        })
      }}>
        {icon}
        Desactivar
      </Dropdown.Item>
    )
  }
  else if (usuario.estado == 2) {
    const icon = <FaCheck className="me-2" style={{ position: "relative", top: "-0.125rem" }} />
    options.push(
      <Dropdown.Item key="activar" onClick={() => {
        changeStatus({
          id: usuario.id,
          status: 1
        })
      }}>
        {icon}
        Activar
      </Dropdown.Item>
    )
  }
  if (!options.length) return null
  return changeStatusState.isLoading ? <div className="text-center">
    <Spinner animation="border" size="sm" variant="primary" />
  </div> : <Dropdown>
    <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
    {ReactDOM.createPortal(
      <Dropdown.Menu style={{ textTransform: 'none' }}>
        {options}
      </Dropdown.Menu>
      , document.body)}
  </Dropdown>
}