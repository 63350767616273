import { useCallback, useEffect, useMemo, useRef, useState } from "react"
import { Alert, Breadcrumb, Button, Col, Form, Image, InputGroup, Row, Tab, Tabs } from "react-bootstrap"
import { Link, useLocation, useParams } from "react-router-dom"
import { Controller, FieldError, useForm } from "react-hook-form"
import { TablaAmortizacion } from "./TablaAmortizacion"
import { Money, MoneyInput } from "../../../commons/money"
import { ManzanasSelect } from "../../manzanas/components/ManzanasSelect"
import { ManzanaView } from "../../manzanas/types"
import { LotesSelect } from "../../lotes/components"
import { LoteView } from "../../lotes/types"
import moment from "moment"
import { PercentageInput } from "../../../commons/components/PercentageInput"
import { ClienteSelect } from "../../clientes/components/ClienteSelect"
import { VendedorSelect, VendedorView } from "../../vendedores/components/VendedorSelect"
import { ClienteView } from "../../clientes/services/clientesApi"
import { useProyectoContext } from "../../proyectos/proyectosApi"
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from "yup"
import { useRegistrarVentaMutation } from "../ventasApi"
import { RawReserva } from "../../reservas/types"
import { parseReserva } from "../../reservas/reservaApi"
import { usePreview, useServerValidationErrors } from "../../../commons/hooks"
import { LoadingButton } from "../../../commons/components"
import { FaArrowRight, FaCashRegister, FaExclamationTriangle, FaSave } from "react-icons/fa"
import Decimal from "decimal.js"
import { disableOnTesting } from "../../../commons/utils"

const schema = yup.object({
  tipo: yup.number().oneOf([1, 2], "Tipo de venta invalido.").required(),
  fecha: yup.date().undefined().required(),
  manzana: yup.mixed().required(),
  lote: yup.mixed().required(),
  precio: yup.mixed()
    .test("amountRequired", "El campo '${path}' es requerido.", function (value, context) {
      return !!(value as any).amount
    })
    .test("min", "El precio debe ser mayor a 0.", function (value, context) {
      return !(value as any).amount || (value as any).amount.gt("0")
    })
    .required(),
  cliente: yup.mixed().required(),
  vendedor: yup.mixed().required(),

  cuotaInicial: yup.mixed().label("cuota inicial").when("tipo", {
    is: 2,
    then: schema => schema.test("amountRequired", "El campo '${path}' es requerido.", function (value, context) {
      return !!(value as any).amount
    })
      .test("min", "La ${path} debe ser un valor positivo.", function (value, context) {
        return !(value as Money).amount || (value as Money).amount!.gt("0")
      })
      .test("lessThanPrice", "La cuota inicial excede el valor del terreno.", function (value, context) {
        return !value.amount || !context.parent.precio.amount || !value.amount!.gt(context.parent.precio.amount)
      })
      .required()
  }),
  plazo: yup.number().undefined().when("tipo", {
    is: 2,
    then: schema => schema.test("Divisible", "El plazo no es divisible entre el periodo de pago.", function (value, context) {
      return !value || !context.parent.periodoPago || value % context.parent.periodoPago === 0
    }).required()
  }),
  periodoPago: yup.number().when("tipo", {
    is: 2,
    then: schema => schema.oneOf([1, 2, 3, 6], "El periodo de pago solo puede ser mensual, bimestral, trimestral o semestral")
      .required()
  }),
  tasaInteres: yup.string().label("tasa de interés").when("tipo", {
    is: 2,
    then: schema => schema.test("min", "La tasa de interes debe ser mayor a 0.", function (value, context) {
      return !value || isNaN(+value) || new Decimal(value).gte("0")
    }).required()
  })
})

type Inputs = {
  tipo: 1 | 2
  fecha: Date | string
  precio: Money
  manzana: ManzanaView | null
  lote: LoteView | null
  diaPago: number
  plazo: number
  periodoPago: 1 | 2 | 3
  tasaInteres: string
  cuotaInicial: Money
  valorCuotas: Money
  totalCredito: Money
  cliente: ClienteView | null
  vendedor: VendedorView | null
}

export const VentasForm = () => {
  const { proyectoId } = useParams()
  const location = useLocation()
  const { reserva } = (location.state || {}) as {
    reserva?: RawReserva
  }

  const parsedReserva = useMemo(()=>reserva && parseReserva(reserva), [reserva])

  const projectDefaults = useProyectoContext()!
  const moneda = useRef<string>(projectDefaults.moneda)
  const precioNull = useMemo(() => new Money(null, moneda.current), [moneda.current])

  const {
    control,
    getValues,
    formState,
    handleSubmit,
    register,
    reset,
    resetField,
    setValue,
    setError,
    trigger,
    watch
  } = useForm<Inputs>({
    resolver: yupResolver(schema),
    defaultValues: {
      fecha: moment().format("YYYY-MM-DD"),
      lote: parsedReserva?.lote ?? null,
      manzana: reserva?.lote.manzana ?? null,
      vendedor: reserva?.vendedor ?? null,
      cliente: reserva?.cliente ?? null,
      tipo: "1" as any,
      periodoPago: 1,
      tasaInteres: new Decimal(projectDefaults.tasaInteres).mul("100").toFixed(2),
      cuotaInicial: precioNull,
      precio: precioNull,
      valorCuotas: precioNull,
      totalCredito: precioNull
    }
  })
    
  useEffect(() => {
    if(parsedReserva){
      (async function (){
        const [precio, cuotaInicial] = await Promise.all([
          parsedReserva.saldoContado.exchangeTo(moneda.current),
          parsedReserva.saldoCredito.exchangeTo(moneda.current)
        ])
          resetField("precio", {defaultValue: precio })
          resetField("cuotaInicial", {defaultValue: cuotaInicial })
      })()
    }
    else{
      resetField("cuotaInicial", {defaultValue: projectDefaults.cuotaInicial})
    }
  }, [])

  const tipo = watch("tipo")
  const fecha = watch("fecha")
  const manzana = watch("manzana")
  const lote = watch("lote")
  const precio = watch("precio")

  const cuotaInicial = watch("cuotaInicial")
  const tasa = watch("tasaInteres")

  const plazo = watch("plazo")
  const periodoPago = watch("periodoPago")

  const valorCuota = watch("valorCuotas")
  const totalCredito = watch("totalCredito")

  const getDefaultPrecio = (lote: LoteView | null) => {
    // if(reserva) return reserva.precio.exchangeTo(moneda.current)
    const value = lote ? (lote.precio || lote.precioSugerido) : precioNull
    return value.exchangeTo(moneda.current)
  }

  const actualizarMonedaVenta = useCallback(async (currency: string) => {
    const monedaOriginal = parsedReserva?.importe.currency ?? projectDefaults.moneda
    const exchangeMode = moneda.current == monedaOriginal ? "sell" : "buy"
    const [precioExchanged, cuotaInicialExchanged] = await Promise.all([
      precio.exchangeTo(currency, { exchangeMode }),
      cuotaInicial.exchangeTo(currency, { exchangeMode })
    ])
    setValue("precio", precioExchanged)
    setValue("cuotaInicial", cuotaInicialExchanged)
    moneda.current = currency
  }, [parsedReserva, projectDefaults, precio, cuotaInicial])

  const [guardar, guardarState] = useRegistrarVentaMutation()

  useServerValidationErrors(guardarState, setError, useCallback((key: string) => {
    if (key == "loteId") return "lote"
    if (key == "manzanaId") return "manzana"
    if (key == "vendedorId") return "vendedor"
    if (key == "clienteId") return "cliente"
    return key
  }, []))

  useEffect(() => {
    const subscription = watch((values, info) => {
      if (info.name === "manzana" && typeof manzana !== "number" && values.lote) {
        setValue("lote", null, {
          shouldValidate: formState.isSubmitted
        })
      }
      else if (info.name === "lote") {
        const lote = values.lote as LoteView | null
        getDefaultPrecio(lote).then((value)=>{
          setValue("precio", value, { shouldValidate: formState.isSubmitted })
        })
      }
      else if (info.name === "periodoPago" && formState.isSubmitted) {
        trigger("plazo")
      }
      // console.log("Watch Observer", values, info, formState.isSubmitted)
    })
    return () => subscription.unsubscribe()
  }, [formState.isSubmitted])

  // console.log(formState.isSubmitted, formState.submitCount)

  const renderAlert = () => {
    if (guardarState.isSuccess) {
      return <Alert className="d-flex" variant="success">
        <div>La solicitud se completo exitosamente</div>
        <div className="ms-auto">
          <Link className="btn btn-primary" to={`/caja/registrar?codigo_pago=CLI-${guardarState.data.clienteId}`} target="_blank">
            <FaCashRegister className="me-2" style={{ position: "relative", top: "-0.125rem" }} />
            Ir a caja
          </Link>
        </div>
      </Alert>
    }
    if (guardarState.isError) {
      const error = guardarState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../ventas" }}>Ventas</Breadcrumb.Item>
      <Breadcrumb.Item active>Registro</Breadcrumb.Item>
    </Breadcrumb>
    {renderAlert()}
    <Form className="mt-2" aria-label="Formulario de registro de venta"
      onSubmit={handleSubmit((values) => {
        // const formData: FormData = new FormData()
        // formData.append("tipo", String(values.tipo))
        // formData.append("fecha", moment(values.fecha).format("YYYY-MM-DD"))
        // formData.append("lote_id", !reserva ? String(values.lote!.id) : "")
        // formData.append("moneda", moneda.current.code)
        // formData.append("cliente_id", !reserva ? String(values.cliente!.id) : "")
        // formData.append("vendedor_id", !reserva ? String(values.vendedor!.id) : "")
        // formData.append("reserva_id", reserva ? String(reserva.id) : "")

        // if(values.tipo == 1){
        //   formData.append("importe", values.precio.amount!.toFixed(2))
        // } else if(values.tipo == 2){
        //   formData.append("importe", values.cuotaInicial.amount!.toFixed(2))
        //   formData.append("importe_pendiente", values.precio.sub(values.cuotaInicial).amount!.toFixed(2))
        //   formData.append("credito[plazo]", String(values.plazo))
        //   formData.append("credito[periodo_pago]", String(values.periodoPago))
        //   formData.append("credito[tasa_interes]", String(values.tasaInteres))
        //   formData.append("credito[dia_pago]", String(values.diaPago))
        // }
        const body: any = {
          tipo: String(values.tipo),
          fecha: moment(values.fecha).format("YYYY-MM-DD"),
          lote_id: !reserva ? String(values.lote!.id) : "",
          moneda: moneda.current,
          cliente_id: !reserva ? String(values.cliente!.id) : "",
          vendedor_id: !reserva ? String(values.vendedor!.id) : "",
          reserva_id: reserva ? String(reserva.id) : ""
        }
        if (values.tipo == 1) {
          body.importe = values.precio.amount!.toFixed(2)
        } else if (values.tipo == 2) {
          body.importe = values.cuotaInicial.amount!.toFixed(2)
          body.importe_pendiente = values.precio.sub(values.cuotaInicial).amount!.toFixed(2)
          body.credito = {
            plazo: String(values.plazo),
            periodo_pago: String(values.periodoPago),
            tasa_interes: new Decimal(values.tasaInteres).dividedBy("100").toFixed(4),
            dia_pago: String(values.diaPago)
          }
        }
        guardar({
          body,
          context: {
            proyectoId: proyectoId!
          }
        })
          .unwrap()
          .then(() => {
            if ((location.state as any)?.reserva) delete (location.state as any).reserva
            reset()
          })
          .catch(() => { })

      })}
    >
      <Row className="gx-3">
        <Col sm={6}>
          <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
            <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Moneda</Form.Label>
            <Row className="gx-3" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
              <Col>
                <Form.Check
                  id="dolar"
                  type="radio"
                  label={"Dolar"}
                  value={"USD"}
                  checked={moneda.current == "USD"}
                  name="moneda"
                  onChange={(e) => {
                    if (e.target.checked) actualizarMonedaVenta("USD")
                  }}
                />
              </Col>
              <Col>
                <Form.Check
                  id="boliviano"
                  type="radio"
                  label={"Boliviano"}
                  value={"BOB"}
                  checked={moneda.current == "BOB"}
                  name="moneda"
                  onChange={(e) => {
                    if (e.target.checked) actualizarMonedaVenta("BOB")
                  }} />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-3">
        <Form.Group as={Col} xs={6} md={3} className="mb-3" controlId="fechaVenta">
          <Form.Label>Fecha</Form.Label>
          <Form.Control type="date"
            isInvalid={!!formState.errors.fecha}
            className="text-uppercase"
            {...register("fecha")}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.fecha?.message}</Form.Control.Feedback>
        </Form.Group>
        <Form.Group data-testid="manzana-form-group" as={Col} xs={6} md={3} className="mb-3">
          <Form.Label htmlFor="manzana">Manzana</Form.Label>
          <Controller
            control={control}
            name="manzana"
            render={({ field: { ref, ...field }, fieldState }) => {
              return <>
                <ManzanasSelect
                  isClearable={!reserva}
                  isSearchable={!reserva}
                  menuIsOpen={reserva ? false : undefined}
                  placeholder=""
                  inputId="manzana"
                  isMulti={false}
                  className={"text-uppercase"}
                  {...field}
                  proyectoId={+proyectoId!}
                  isInvalid={!!fieldState.error}
                // filter={{solo si tiene lotes disponibles}}
                />
                <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
              </>
            }}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} md={3} className="mb-3">
          <Form.Label htmlFor="lote">Lote</Form.Label>
          <Controller
            control={control}
            name="lote"
            render={({ field: { ref, ...field }, fieldState }) => {
              return <>
                <LotesSelect
                  isClearable={!reserva}
                  isSearchable={!reserva}
                  menuIsOpen={reserva ? false : undefined}
                  placeholder=""
                  inputId="lote"
                  isMulti={false}
                  className={"text-uppercase"}
                  {...field}
                  value={field.value as any}

                  condition={typeof manzana === "number" || !!manzana?.id}
                  proyectoId={+proyectoId!}
                  filter={{ manzanaId: typeof manzana === "number" ? manzana : manzana?.id, estado: 1 }}
                  isInvalid={!!fieldState.error}
                />
                <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
              </>
            }}
          />
        </Form.Group>
        
          <Controller
            key={typeof lote === "number" ? undefined : lote?.id}
            control={control}
            name={"precio"}
            render={({ field, fieldState }) => {
              return <Form.Group as={Col} xs={6} md={3} className="mb-3">
                <Form.Label htmlFor="precio">Precio</Form.Label>
                <MoneyInput
                  id="precio"
                  {...field}
                  onChange={(value) => {
                    // field.onChange(
                    //   value.amount ? value : getDefaultPrecio(lote)
                    // )
                    field.onChange(value)
                    formState.isSubmitted && trigger("cuotaInicial")
                  }}
                  allowEdit
                  readOnly
                  isInvalid={!!fieldState.error}
                />
                <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
              </Form.Group>
            }}
          />
      </Row>
      <Row className="gx-3">
        <Form.Group as={Col} md={6} className="mb-3">
          <Form.Label htmlFor="vendedor">Vendedor</Form.Label>
          <Controller
            control={control}
            name="vendedor"
            render={({ field: { ref, ...field }, fieldState }) => {
              return <VendedorSelect
                isClearable={!reserva}
                isSearchable={!reserva}
                menuIsOpen={reserva ? false : undefined}
                placeholder=""
                inputId="vendedor"
                {...field}
                className={fieldState.error ? "is-invalid" : ""}
                feedback={fieldState.error?.message}
              />
            }}
          />
        </Form.Group>
        <Form.Group as={Col} md={6} className="mb-3">
          <Form.Label htmlFor="cliente">Cliente</Form.Label>
          <Controller
            control={control}
            name="cliente"
            render={({ field: { ref, ...field }, fieldState }) => {
              return <ClienteSelect
                isClearable={!reserva}
                isSearchable={!reserva}
                menuIsOpen={reserva ? false : undefined}
                placeholder=""
                inputId="cliente"
                {...field}
                className={fieldState.error ? "is-invalid" : ""}
                feedback={fieldState.error?.message}
              />
            }}
          />
        </Form.Group>
      </Row>
      <Row className="gx-3">
        <Col sm={6}>
          <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
            <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Tipo de venta</Form.Label>
            <Row className="gx-3" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
              <Col>
                <Form.Check id="ventaAlContado" type="radio" label="Venta al contado"
                  value={1}
                  {...register("tipo")}
                  isInvalid={!!formState.errors.tipo && getValues().tipo == 1}
                  feedbackType="invalid"
                  feedback={getValues().tipo == 1 ? formState.errors.tipo?.message : ""}
                />
              </Col>
              <Col>
                <Form.Check id="ventaAlCredito" type="radio" label="Venta al crédito"
                  value={2}
                  {...register("tipo")}
                  isInvalid={!!formState.errors.tipo && getValues().tipo == 2}
                  feedbackType="invalid"
                  feedback={getValues().tipo == 2 ? formState.errors.tipo?.message : ""}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      {watch("tipo") == 2 ? <h2 className="mb-3">Parámetros del crédito</h2> : null}
      <Row className={["gx-3", tipo != 2 && "d-none"].filter(cn => !!cn).join(" ")}>
        <Col xs={6} md={3} lg={2}>
          <Row className="gx-3">
            <Form.Group as={Col} xs={6} className="mb-3" controlId="diaPago">
              <Form.Label title="Día de pago">Día de pago</Form.Label>
              <Form.Select
                id="diaPago"
                className="text-end"
                isInvalid={!!formState.errors.diaPago}
                {...register("diaPago")}
              >
                {Array.from({ length: 31 }, (_, i) => <option key={i} value={i + 1}>{i + 1}</option>)}
              </Form.Select>
              <Form.Control.Feedback type={"invalid"}>{formState.errors.diaPago?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group data-testid="plazo-form-group" as={Col} xs={6} className="mb-3">
              <Form.Label htmlFor="plazo">Plazo</Form.Label>
              <Form.Control
                id="plazo"
                className="text-end"
                isInvalid={!!formState.errors.plazo}
                {...register("plazo")}
              />
              <Form.Control.Feedback type={"invalid"}>{formState.errors.plazo?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Col>
        <Form.Group as={Col} xs={6} md={3} xl={2} className="mb-3">
          <Form.Label htmlFor="periodoPago">Periodo de pago</Form.Label>
          <Form.Select
            id="periodoPago"
            isInvalid={!!formState.errors.periodoPago}
            {...register("periodoPago")}
          >
            <option value={1}>MENSUAL</option>
            <option value={2}>BIMESTRAL</option>
            <option value={3}>TRIMESTRAL</option>
            <option value={6}>SEMESTRAL</option>
          </Form.Select>
          <Form.Control.Feedback type="invalid">{formState.errors.periodoPago?.message}</Form.Control.Feedback>
        </Form.Group>
        <Controller
          control={control}
          name="tasaInteres"
          render={({field, fieldState})=>{
            return <Form.Group data-testid="tasainteres-form-group" as={Col} xs={6} md={3} xl={2} className="mb-3" controlId="tasainteres">
              <Form.Label>Tasa de interés anual</Form.Label>
              <PercentageInput
                {...field}
                readOnly
                allowEdit
                className="text-end"
                isInvalid={!!fieldState.error} />
              <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
            </Form.Group>
          }}
        />
        <Controller
          control={control}
          name={"cuotaInicial"}
          render={({ field, fieldState }) => {
            return <Form.Group data-testid="cuotainicial-form-group" as={Col} xs={6} md={3} xl={2} className="mb-3" controlId="cuotaInicial">
              <Form.Label>Cuota inicial</Form.Label>
              <MoneyInput
                {...field}
                allowEdit
                readOnly
                isInvalid={!!fieldState.error}
              />
              <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
            </Form.Group>
          }}
        />
        <Form.Group as={Col} xs={6} md={3} xl={2} className="mb-3">
          <Form.Label htmlFor="valorCuotas">Valor de las cuotas</Form.Label>
          <MoneyInput
            readOnly
            id="valorCuotas"
            className="text-end"
            name="valorCuotas"
            value={valorCuota}
          />
        </Form.Group>
        <Form.Group as={Col} xs={6} md={3} xl={2} className="mb-3">
          <Form.Label htmlFor="totalCredito">Total crédito</Form.Label>
          <MoneyInput
            readOnly
            id="totalCredito"
            className="text-end"
            name="totalCredito"
            value={totalCredito}
          />
        </Form.Group>
        <Col xs={12}>
          <TablaAmortizacion
            fecha={fecha}
            diaPago={watch("diaPago")}
            prestamo={precio}
            plazo={plazo}
            periodicidad={periodoPago}
            tasa={new Decimal(tasa || "0").dividedBy("100").toFixed(4)}
            cuotaInicial={cuotaInicial}
            onComputed={(pago, total) => {
              setValue("valorCuotas", pago)
              setValue("totalCredito", total)
            }}
          />
        </Col>
      </Row>
      <Row className="gx-3">
        <Col sm="auto">
          <LoadingButton
            type="submit"
            disabled={!!Object.keys(formState.errors).length}
            isLoading={guardarState.isLoading}
            icon={<FaSave className="me-2 mt-n1" />}
          >
            Guardar
          </LoadingButton>
        </Col>
      </Row>
    </Form>
  </>
}