import { useRef } from "react"
import { Dropdown, Spinner } from "react-bootstrap"
import ReactDOM from "react-dom"
import { FaEdit, FaTrash } from "react-icons/fa"
import { VerticalEllipsisDropdownToggle } from "../../../commons/components"
import { RawExchangeRate, useEliminarExchangeRateMutation } from "../services"
import { ExchangeRateFormModalRef, ExchangeRateForm } from "./ExchangeRateForm"

type Props = {
  data: RawExchangeRate
}

export function RowOptions({
  data
}:Props){  
  const formModalRef = useRef<ExchangeRateFormModalRef>(null)

  const [deleteRecord, deleteState] = useEliminarExchangeRateMutation()

  const options: React.ReactElement[] = [
    <Dropdown.Item
      key="edit"
      onClick={()=>{
        formModalRef.current?.open()
      }}
    >
      <FaEdit className="mt-n1 me-2"/> Editar
    </Dropdown.Item>,
    <Dropdown.Item
        key="delete"
        className="dropdown-danger-item"
        onClick={()=>{
          deleteRecord(data.id)
        }}
      >
        <FaTrash className="mt-n1 me-2"/> Eliminar
      </Dropdown.Item>
  ]

  if(deleteState.isLoading){
    return <div className="text-center">
      <Spinner animation="border" size="sm" variant="primary" />
    </div> 
  }

  return <>
    <Dropdown>
      <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
      {ReactDOM.createPortal(
        <Dropdown.Menu style={{textTransform: 'none'}}>
          {options}
        </Dropdown.Menu>
      , document.body)}
    </Dropdown>
    <ExchangeRateForm ref={formModalRef} data={data} />
  </>
}