import { useCallback } from "react"
import { Alert, Breadcrumb, Col, Form, Row } from "react-bootstrap"
import { useForm, Controller } from "react-hook-form"
import { FaCheckCircle, FaSave } from "react-icons/fa"
import { LoadingButton } from "../../../commons/components"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { RolSelect } from "../../roles/components"
import { Rol } from "../../roles/rolesApi"
import { RawUsuario, useActualizarUsuarioMutation, useRegistrarUsuarioMutation } from "../usuariosApi"
import { Link } from "react-router-dom"
import { VendedorSelect, VendedorView } from "../../vendedores/components/VendedorSelect"
import { ProyectoSelect, RawProyecto } from "../../proyectos/components"
import { useServerValidationErrors } from "../../../commons/hooks"


const schema = yup.object({
  username: yup.string().label("nombre de usuario").required(),
  email: yup.string().label("correo electrónico").required().email("Correo electrónico invalido."),
  password: yup.string().label("contraseña").when("$editing", {
    is: false,
    then: (schema) => schema.required().min(8),
    otherwise: (schema) => schema.nullable()
  }),
  passwordConfirmation: yup.string().when("password", {
    is: (value: string) => !!value,
    then: (schema) => schema.oneOf([yup.ref("password")], "Las contraseñas no coinciden."),
    otherwise: (schema) => schema.nullable()
  }),
  roles: yup.array().min(1, "Debe seleccionar al menos un rol.")
})

type FormValues = {
  username: string
  email: string
  password: string
  passwordConfirmation: string
  roles: Rol[]
  vendedor: VendedorView | null
  proyectos: RawProyecto[]
}

type Props = {
  data?: RawUsuario
  // readOnly?: boolean
}
export const UsuarioForm = (props: Props)=>{
  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<FormValues>({
    context: {
      "editing": !!props.data
    },
    defaultValues: {
      username: props.data?.username ?? "",
      email: props.data?.email ?? "",
      password: "",
      passwordConfirmation: "",
      roles: props.data?.roles ?? [],
      vendedor: props.data?.vendedor ?? null,
      proyectos: props.data?.proyectos ?? []
    },
    resolver: yupResolver(schema)
  })

  const [create, createState] = useRegistrarUsuarioMutation()
  const [update, updateState] = useActualizarUsuarioMutation()

  const mutationState = !props.data ? createState : updateState

  useServerValidationErrors(mutationState, setError, useCallback((key: string)=>{
    if(key == "vendedorId") return "vendedor"
    if(key == "proyectoIds") return "proyectos"
    if(/roles.\d+/.test(key)) return "roles"
    return key
  }, []))  

  const renderAlert = () => {
    if (mutationState.isSuccess) {
      return <Alert className="text-nowrap" variant="success">
        <FaCheckCircle className="me-1" style={{ position: "relative", top: "-0.125rem" }} />
        La solicitud se completó exitosamente.
      </Alert>
    }
    if (mutationState.isError) {
      const error = mutationState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  const dirtyFields = formState.dirtyFields
  return <>
    {!props.data ? <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../usuarios" }}>Usuarios</Breadcrumb.Item>
      <Breadcrumb.Item active>Registro</Breadcrumb.Item>
    </Breadcrumb> : null}
    <Form aria-label="Formulario de registro de usuarios" onSubmit={handleSubmit((values)=>{
      if(!props.data){
        create({
          username: values.username,
          email: values.email,
          password: values.password,
          roles: values.roles.map(r => r.name),
          vendedorId: values.vendedor?.id,
          proyectoIds: values.proyectos.map(p => p.id)
        })
          .unwrap()
          .then(()=>{
            //Do somthing when request is success (E.g.: reset form, scroll to top, etc.)
            reset()
            window.scrollTo(0, 0)
          })
          .catch(()=>{
            //Do somthing when request failed (E.g. display alert, log error, etc.)
          })
      }
      else {
        update({
          id: props.data.id,
          username: dirtyFields.username ? values.username : undefined,
          email: dirtyFields.email ? values.email : undefined,
          password: dirtyFields.password ? values.password : undefined,
          roles: dirtyFields.roles ? values.roles.map(r => r.name) : undefined,
          vendedorId: dirtyFields.vendedor ? values.vendedor?.id : undefined,
          proyectoIds: dirtyFields.proyectos ? values.proyectos.map(p => p.id) : undefined
        })
          .unwrap()
          .then(()=>{
            //Do somthing when request is success (E.g.: reset form, scroll to top, etc.)
            reset(values)
            window.scrollTo(0, 0)
          })
          .catch(()=>{
            //Do somthing when request failed (E.g. display alert, log error, etc.)
          })
      }
    })}>
      {renderAlert()}
      <Form.Group className="mb-3" data-testid="username-form-group" controlId="username">
        <Form.Label>Nombre de usuario</Form.Label>
        <Form.Control
          {...register("username")}
          isInvalid={!!formState.errors.username}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.username?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group className="mb-3" data-testid="email-form-group" controlId="email">
        <Form.Label>Correo electrónico</Form.Label>
        <Form.Control
          {...register("email")}
          type="email"
          isInvalid={!!formState.errors.email}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.email?.message}</Form.Control.Feedback>
      </Form.Group>
      <Controller
        control={control}
        name="password"
        render={({field, fieldState})=>{
          return <Row className="gx-2">
            <Form.Group as={Col} md={6} className="mb-3" data-testid="password-form-group" controlId="password">
              <Form.Label>Contraseña</Form.Label>
              <Form.Control
                {...field}
                type="password"
                isInvalid={!!formState.errors.password}
                placeholder={props.data ? "Sin modificar" : ""}
              />
              <Form.Control.Feedback type="invalid">{formState.errors.password?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} md={6} className="mb-3" data-testid="passwordconfirmation-form-group" controlId="passwordconfirmation">
              <Form.Label>Confirmar contraseña</Form.Label>
              <Form.Control
                {...register("passwordConfirmation")}
                type="password"
                isInvalid={!!formState.errors.passwordConfirmation}
                placeholder={props.data && !field.value ? "Sin modificar" : ""}
              />
              <Form.Control.Feedback type="invalid">{formState.errors.passwordConfirmation?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>
        }}
      />
      <Controller
        control={control}
        name="roles"
        render={({field: {ref, ...field}, fieldState})=>{
          return <Form.Group className="mb-3" data-testid="roles-form-group" controlId="roles">
            <Form.Label>Roles</Form.Label>
            <RolSelect
              {...field}
              closeMenuOnSelect={false}
              isMulti
              inputId="roles"
              isInvalid={!!formState.errors.roles}
            />
            <Form.Control.Feedback type="invalid">{formState.errors.roles?.message}</Form.Control.Feedback>
          </Form.Group>
        }}
      />
      <h2 className="fs-5">Recursos asociados</h2>
      <Controller
        control={control}
        name="vendedor"
        render={({field: {ref, ...field}, fieldState})=>{
          return <Form.Group className="mb-3" data-testid="vendedor-form-group" controlId="vendedor">
            <Form.Label>Vendedor</Form.Label>
            <VendedorSelect
              {...field}
              inputId="vendedor"
              className={formState.errors.vendedor ? "is-invalid" : ""}
              feedback={formState.errors.vendedor?.message}
            />
          </Form.Group>
        }}
      />
      <Controller
        control={control}
        name="proyectos"
        render={({field: {ref, ...field}, fieldState})=>{
          return <Form.Group className="mb-3" data-testid="proyectos-form-group" controlId="proyectos">
            <Form.Label>Proyectos</Form.Label>
            <ProyectoSelect
              {...field}
              closeMenuOnSelect={false}
              controlShouldRenderValue
              isMulti
              inputId="proyectos"
              className={formState.errors.proyectos ? "is-invalid" : ""}
              feedback={formState.errors.proyectos?.message}
            />
          </Form.Group>
        }}
      />
      <LoadingButton
        type="submit"
        disabled={!formState.isDirty || !!Object.keys(formState.errors).length}
        isLoading={mutationState.isLoading}
        icon={<FaSave style={{position: "relative", top: "-0.125rem"}} />}
      >
        Guardar
      </LoadingButton>
    </Form>
  </>
}