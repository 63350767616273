import Decimal from "decimal.js";
import moment, { Moment } from "moment";

export class PlanPagosBuilder/*<F={
  numero: number
  vencimiento: Moment
  pago: Money
  interes: Money
  amortizacion: Money
  saldo: Money
}>*/ {
  static readonly PERIODO_MENSUAL = 1
  static readonly PERIODO_BIMESTRAL = 2
  static readonly PERIODO_TRIMESTRAL = 3
  static readonly PERIODO_SEMESTRAL = 6

  protected offset: number
  protected numeroCuotas: number
  protected enero: Moment
  protected pago: Decimal|null=null

  constructor(
    protected fecha: Moment,
    protected importe: Decimal,
    protected tasaInteres: Decimal,
    protected plazo: number,
    protected periodo: number,
    protected diaPago: number,
    // //@ts-ignore
    // protected format: (cuota: {
    //     numero: number
    //     vencimiento: Moment
    //     pago: Money
    //     interes: Money
    //     amortizacion: Money
    //     saldo: Money
    //   }
    // )=>F=idempotent
  ) {
    this.numeroCuotas = this.plazo / this.periodo
    let start = this.fecha.clone().add(28, "days")
    // if(start.date() > this.diaPago){
    //   start = start.add(1, "month")
    // }
    // this.offset = start.month()
    this.offset = start.month() + (start.date() > this.diaPago ? 1 : 0)
    this.enero = moment([start.get("year"), 0, this.diaPago]).startOf("day")
    this.build.bind(this)
  }

  build(){
    const output: {
      numero: number
      vencimiento: Moment
      diasTranscurridos: number
      pago: Decimal
      interes: Decimal
      amortizacion: Decimal
      saldo: Decimal
    }[] = new Array(this.numeroCuotas)
    const _this = this
    let saldo = this.importe
    
    function* recursiveBuild(cuota: number, vencimientoCuotaAnterior: Moment){
      if(cuota > _this.numeroCuotas){
        const numerator = new Decimal(1)
        const denominator = new Decimal(0)
        yield [numerator, denominator] 
        // yield new Array(_this.numeroCuotas)
      }
      else{
        const vencimiento = _this.enero.clone().add(_this.periodo*(cuota)+_this.offset - 1, "month")
        const diasTranscurridos = vencimiento.diff(vencimientoCuotaAnterior, "days")
        const tasa = _this.tasaInteres.mul(diasTranscurridos).div(360)
        const fas = tasa.plus(1)

        const gen = recursiveBuild(cuota + 1, vencimiento)
        const [numerator, denominator] = gen.next().value as [Decimal, Decimal]

        yield [numerator.mul(fas), denominator.add(numerator)]
        
        const interes = saldo.mul(tasa).toDecimalPlaces(2)
        const saldoMasInteres = saldo.add(interes)
        const pagoCuota = _this.pago!.gt(saldoMasInteres.sub("0.99")) || cuota == _this.numeroCuotas ? saldoMasInteres : _this.pago!
        const amortizacion = pagoCuota!.sub(interes)
        saldo = saldo.sub(amortizacion)
        output[cuota-1] = {//_this.format({
          numero: cuota,
          vencimiento,
          diasTranscurridos,
          pago: pagoCuota,
          interes,
          amortizacion,
          saldo
        }//)
        gen.next()
      }
    }

    const gen = recursiveBuild(1, this.fecha)
    const [numerator, denominator] = gen.next().value as [Decimal, Decimal]
    this.pago = this.importe.mul(numerator.div(denominator)).toDecimalPlaces(2)
    gen.next()
    return output
  }
}