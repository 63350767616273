import { useEffect, useMemo, useRef, useState } from "react"
import { Alert, Breadcrumb, Col, Form, Row, Spinner } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { FaSave } from "react-icons/fa"
import { Link, Navigate, useLocation, useNavigate, useParams } from "react-router-dom"
import Select, { components } from "react-select"
import { LoadingButton, PdfModal, PdfModalRef } from "../../../commons/components"
import { MoneyInput, Money } from "../../../commons/money"
import { Credito, useGetCreditoQuery, useProgramarPagoExtraMutation } from "../creditosApi"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useServerValidationErrors } from "../../../commons/hooks"
import { RawMoney } from "../../../commons/services/types"
import { skipToken } from "@reduxjs/toolkit/dist/query"
import Decimal from "decimal.js"

type FormValues = {
  tipoAjuste: number
  periodo: {numero: number, saldoCapital: RawMoney}|null
  importe: Money
}

// const MenuList = (
//   props: any
// ) => {
//   return (
//     <components.MenuList data-testid="periodos-dropdown" {...props} />
//   );
// };

// const LoadingIndicator = (
//   props: any
// ) => {
//   return (
//     <components.LoadingIndicator data-testid="periodos-loader" {...props} />
//   );
// };

const schema = yup.object({
  tipoAjuste: yup.number().required(),
  periodo: yup.object({numero: yup.number().undefined().required()}).nullable().required(),
  importe: yup.mixed().test("required", "El campo '${path}' es requerido.", function(value: Money|'', context){
    return !value || !!value.amount?.greaterThan("0");
  }).test("max", "El importe no puede ser mayor que ${max}.", function(value, context){
    const rawSaldoCapital = context.parent.periodo?.saldoCapital
    const saldoCapital = rawSaldoCapital && new Money(rawSaldoCapital.amount, value.currency).round(2)
    if(saldoCapital && value?.amount?.greaterThan(saldoCapital.amount)){
      return context.createError({params: {max: saldoCapital.toFixed(2)}})
    }
    return true
  })
})

export const PagoExtraForm = ()=>{
  const {
    id
  } = useParams()

  const navigate = useNavigate()
  const location = useLocation()

  const pdfModal = useRef<PdfModalRef>(null)

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      tipoAjuste: "1" as any,
      periodo: null
    }
  })

  const getCredito = useGetCreditoQuery(location.state?.credito ? skipToken : +id)
  const credito = (location.state as { credito: Credito }|undefined)?.credito ?? getCredito.currentData
  
  // useEffect(()=>{
  //   const credito = location.state?.credito
  //   if(credito){
  //     pdfModal.current?.open(credito.urlPlanPago, "Plan de pagos")
  //   }
  // }, [location.state])

  useEffect(()=>{
    if(credito){
      const currencyCode = credito.importe.currency
      reset({
        tipoAjuste: "1" as any,
        periodo: null,
        importe: new Money(null, currencyCode)
      })
    }
  }, [credito, reset])

  const [programar, programarState] = useProgramarPagoExtraMutation()

  useServerValidationErrors(programarState, setError)

  const cuotas = useMemo(()=>{
    const cuotas = credito?.cuotas ?? []
    const options = []
    let i = cuotas.length-1
    while(
      i >= 0
    ){
      if(cuotas[i].vencida) break;
      
      const saldoCapital = new Decimal(cuotas[i].saldoCapital.amount).toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
      if(saldoCapital.gt("0")){
        options.push(cuotas[i])
      }
      const pagoExtra = new Decimal(cuotas[i].pagoExtra.amount).toDecimalPlaces(2, Decimal.ROUND_HALF_UP)
      if(pagoExtra.greaterThan("0")) break;
      i--
    }

    return options.reverse()
  }, [credito])

  if(getCredito.isFetching) {
    return <><Spinner animation="border" size="sm" /> Cargando...</>
  }
  if(getCredito.error) {
    if("status" in getCredito.error) {
      switch(getCredito.error.status){
        case 404: return <Navigate replace to="/404" />
        default: return <Alert variant="danger">
          Ocurrio un error al recuperar los datos del credito:
          {(getCredito.error.data as any).message}
        </Alert>
      }
    }
    return <Alert variant="danger">
      Ocurrio un error al recuperar los datos del credito:
      {getCredito.error.message}
    </Alert>
  }

  const renderAlert = ()=>{
    const alerts = []
    if(programarState.isError) {
      const error = programarState.error as any
      const message: Array<JSX.Element|string> = ["Ocurrio un error al realizar la solicitud"]
      if(error.status != 422) message.push(":", <br key="line-break"/>, error.message ?? error.data.message)
      else message.push(".")
      alerts.push(<Alert key="error" variant="danger">
        {message}
      </Alert>)
    }
    if(programarState.isSuccess){
      alerts.push(<Alert key="success" variant="success">
        La solicitud se completo exitosamente
      </Alert>)
    }
    if(credito?.estado == 2){
      alerts.push(<Alert key="anulado" variant="warning">
        Este crédito ha sido anulado.
      </Alert>)
    }
    if(cuotas.length == 0){
      alerts.push(<Alert key="noop" variant="warning">
        No puede programar más pagos extras.
      </Alert>)
    }
    return alerts
  }

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item linkAs="span">Créditos</Breadcrumb.Item>
      <Breadcrumb.Item linkAs="span">{id}</Breadcrumb.Item>
      <Breadcrumb.Item active>Programar pago extra</Breadcrumb.Item>
    </Breadcrumb>
    <Form aria-label="Formulario de registro de pagos extras" onSubmit={handleSubmit((values)=>{
      programar({
        tipoAjuste: values.tipoAjuste,
        periodo: values.periodo!.numero,
        importe: values.importe.amount!.toFixed(2),
        creditoId: credito!.codigo
      }).unwrap().then((credito)=>{
        reset()
        pdfModal.current?.open(credito.urlPlanPago, "Plan de pagos")
        // navigate(`../creditos/${credito.codigo}/pagos-extras`, {
        //   replace: true,
        //   state: {
        //     credito
        //   }
        // })
      },(e)=>{ })
    })}>
      {renderAlert()}
      <Row className="gx-2">
        <Col xs={12}>
          <Form.Group data-testid="tipo-ajuste-radiogroup" as="fieldset" className="border rounded px-2 pb-2 mb-3">
            <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Tipo de ajuste</Form.Label>
            <Row className="gx-2" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
              <Col xs={12} sm={6} lg={3} className="mb-3">
                <Form.Check id="prorrateo" type="radio"
                  label="Disminución de las cuotas"
                  value={1}
                  {...register("tipoAjuste")}
                  isInvalid={!!formState.errors.tipoAjuste && getValues().tipoAjuste == 1}
                  feedbackType="invalid"
                  feedback={getValues().tipoAjuste == 1 ? formState.errors.tipoAjuste?.message : ""}
                />
              </Col>
              <Col xs={12} sm={6} lg={3} className="mb-3">
                <Form.Check id="disminucionPlazo" type="radio"
                  label="Disminución del plazo"
                  value={2}
                  {...register("tipoAjuste")}
                  isInvalid={!!formState.errors.tipoAjuste && getValues().tipoAjuste == 2}
                  feedbackType="invalid"
                  feedback={getValues().tipoAjuste == 2 ? formState.errors.tipoAjuste?.message : ""}
                />
              </Col>
              <Col xs={12} sm={6} lg={3} className="mb-3">
                <Form.Check id="diferidoParcial" type="radio"
                  label="Pago de interés"
                  value={3}
                  {...register("tipoAjuste")}
                  isInvalid={!!formState.errors.tipoAjuste && getValues().tipoAjuste == 3}
                  feedbackType="invalid"
                  feedback={getValues().tipoAjuste == 3 ? formState.errors.tipoAjuste?.message : ""}
                />
              </Col>
              <Col xs={12} sm={6} lg={3} className="mb-3">
                <Form.Check id="diferidoTotal" type="radio"
                  label="Pago de interés acumulado"
                  value={4}
                  {...register("tipoAjuste")}
                  isInvalid={!!formState.errors.tipoAjuste && getValues().tipoAjuste == 4}
                  feedbackType="invalid"
                  feedback={getValues().tipoAjuste == 4 ? formState.errors.tipoAjuste?.message : ""}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-2">
        <Controller
          control={control}
          name="periodo"
          render={({field: {ref, ...field}, fieldState})=>{
            return <>
              <Form.Group as={Col} xs={6} sm={4} md={3} className="mb-3" controlId="periodo">
                <Form.Label>Periodo</Form.Label>
                <Select
                  {...field}
                  placeholder=""
                  loadingMessage={()=>"Cargando..."}
                  menuPortalTarget={document.body}
                  styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
                  // components={{MenuList, LoadingIndicator}}
                  inputId="periodo"
                  className={fieldState.error ? "is-invalid" : ""}
                  isLoading={getCredito.isFetching}
                  options={cuotas}
                  getOptionLabel={(option) => String(option.numero)}
                  getOptionValue={(option) => String(option.numero)}
                />
                <Form.Control.Feedback data-testid="periodo-error" type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
              </Form.Group>
              {field.value ? <Form.Group as={Col} xs={6} sm={4} md={3} className="mb-3" controlId="saldo-capital">
                <Form.Label>Capital no amortizado</Form.Label>
                <MoneyInput
                  readOnly
                  value={new Money(field.value.saldoCapital.amount, field.value.saldoCapital.currency)}
                />
              </Form.Group> : null}
            </>
          }}
        />
        <Controller
          control={control}
          name="importe"
          render={({field, fieldState})=>{
            return <Form.Group data-testid="importe-form-group" as={Col} xs={6} sm={4} md={3} className="mb-3" controlId="importe">
              <Form.Label>Importe</Form.Label>
              {field.value ? <MoneyInput
                {...field}
                isInvalid={!!fieldState.error}
              /> : null}              
              <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
            </Form.Group>
          }}
        />
      </Row>
      <Row className="gx-2">
        <Col sm="auto">
          <LoadingButton type="submit" isLoading={programarState.isLoading} icon={<FaSave style={{position: "relative", top: "-0.125em"}} />}>
            Guardar
          </LoadingButton>
        </Col>
      </Row>
    </Form>
    <PdfModal ref={pdfModal} />
  </div>
}