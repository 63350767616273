import Decimal from "decimal.js"
import { Breadcrumb, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap"
import { FaCashRegister, FaWhatsapp } from "react-icons/fa"
import { Link, useParams } from "react-router-dom"
import { LoadingButton, NotFound } from "../../../commons/components"
import { Cuota, useHabilitarPagosMutation } from "../../creditos/creditosApi"
import { useGetClienteQuery } from "../services/clientesApi"


const CuotaRow = ({creditoId, cuota}: {
  creditoId: number
  cuota: Cuota
})=>{
  const amount = new Decimal(cuota.total.amount)
  const [habilitarPagos, habilitarPagosState] = useHabilitarPagosMutation()
  return <tr key={cuota.id}>
  <th scope="row">{cuota.numero}</th>
  <td>{cuota.vencimiento}</td>
  <td>{new Decimal(cuota.importe.amount).plus(cuota.pagoExtra.amount).toFixed(2)} {cuota.importe.currency}</td>
  <td
    title={`Importe pendiente = ${new Decimal(cuota.saldo.amount).plus(cuota.saldo.amount).toFixed(2)} ${cuota.saldo.currency}&#10;&#13;Multa por mora = ${new Decimal(cuota.multa.amount).plus(cuota.multa.amount).toFixed(2)} ${cuota.multa.currency}`}
  >
    {amount.toFixed(2)} {cuota.saldo.currency}
  </td>
  <td>{cuota.vencida ? "Vencida" : cuota.activa ? "Activa" : "Inactiva"}</td>
  <td style={{padding: 5}}>
    {!cuota.vencida && !cuota.activa && !cuota.adelantable && amount.greaterThan("0") && <LoadingButton 
      icon={null}
      isLoading={habilitarPagosState.isLoading}
      className="text-nowrap" size="sm"
      onClick={()=>{
        habilitarPagos({
          creditoId: creditoId,
          cuota: cuota.numero
        })
      }}
    >
      Habilitar pagos
    </LoadingButton>}</td>
</tr>
}

export const ClienteView = ()=>{
  const params = useParams()
  const getCliente = useGetClienteQuery(+params.clienteId)
  const cliente = getCliente.currentData


  const renderContent = ()=>{
    const error = getCliente.error
    if(getCliente.isFetching){
      return <div className="text-nowrap">
        <Spinner animation="border" size="sm" /> Cargando...
      </div>
    }
    else if(error){
      if("data" in error){
        if(error.status === 404){
          return <NotFound
            message="Cliente no encontrado"
          />
        }
      }
      else {

      }
    }
    else if(cliente){
      return <>
        <h2 className="fs-5 mb-2">Cliente</h2>
        <Row className="gx-2 mb-2">
          <Col sm={3}>
            <div className="fw-semibold mb-2">{cliente.documentoIdentidad.tipoText}:</div>
            <div>{cliente.documentoIdentidad.numero}</div>
          </Col>
          <Col sm={6}>
            <div className="fw-semibold mb-2">Nombre:</div>
            <div>{cliente.nombreCompleto}</div>
          </Col>
          <Col sm={3}>
            <div className="fw-semibold mb-2">Teléfono:</div>
            <div><a 
              target="_blank"
              href={`https://wa.me/${cliente.telefono}/?text=Estimado(a)%20${cliente.nombre.trim().toLocaleLowerCase().split(/\s+/).map(w => w[0].toLocaleUpperCase() + w.substring(1)).join(" ")},`}
            >
              <FaWhatsapp className="me-1 mt-n1" />
              {cliente.telefono}
            </a></div>
          </Col>
        </Row>
        <h2 className="fs-5 mb-2">Créditos</h2>
        {cliente.creditos.map(({credito, ...venta})=>{
          return <Card key={venta.id} className="mb-2">
            <Card.Header>Crédito {credito.codigo}</Card.Header>
            <Card.Body>
              <Row className="gx-2 mb-2">
                <Col sm={3}>
                  <div className="fw-semibold mb-2">Fecha:</div>
                  <div>{venta.fecha}</div>
                </Col>
                <Col sm={6}>
                  <div className="fw-semibold mb-2">Proyecto:</div>
                  <div>{venta.proyecto.nombre}</div>
                </Col>
                <Col sm={3}>
                  <div className="fw-semibold mb-2">Lote:</div>
                  <div>Mz {venta.lote.manzana.numero}, Lt {venta.lote.numero}</div>
                </Col>
              </Row>
              <h3 className="fs-6 mb-2">Plan de pagos</h3>
              <Table>
                <thead>
                  <tr>
                    <th style={{width:0}}>#</th>
                    <th>Vencimiento</th>
                    <th>Importe</th>
                    <th>Saldo</th>
                    <th>Estado</th>
                    <th style={{width:0}}></th>
                  </tr>
                </thead>
                <tbody>
                  {credito.cuotas.map(cuota=>{
                    return <CuotaRow key={cuota.id} cuota={cuota} creditoId={credito.codigo} />
                  })}
                </tbody>
              </Table>
            </Card.Body>
          </Card>
        })}
        {/* <Button
          className="position-fixed shadow"
          style={{bottom: "1.5rem", right: "1.5rem", borderRadius: "50%", aspectRatio: "1"}}
          >
          <FaCashRegister className="mt-n1"/>
        </Button> */}
        <Link title="Ir a caja" className="btn btn-primary btn-lg position-fixed shadow" 
          style={{bottom: "1.5rem", right: "1.5rem", borderRadius: "50%", aspectRatio: "1"}}
          to={`/caja/registrar?codigo_pago=CLI-${params.clienteId}`} target="_blank"
        >
          <FaCashRegister/>
        </Link>
      </>
    }
    return null
  }

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../clientes" }}>Clientes</Breadcrumb.Item>
      <Breadcrumb.Item active>{params.clienteId}</Breadcrumb.Item>
    </Breadcrumb>
    {renderContent()}
  </div>
}