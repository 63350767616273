import { LatLngTuple, Map } from "leaflet"
import { useCallback } from "react"
import { MapContainer, Polygon, TileLayer, Tooltip } from "react-leaflet"
import { useGetLotesQuery } from "../../lotes/services"
import { useProyectoContext } from "../proyectosApi"
import L from "leaflet"

export const PlanoView = ()=>{
  const {id, ubicacion} = useProyectoContext()!

  const getLotes = useGetLotesQuery({proyectoId: id})

  const mapRef = useCallback((ref: Map| null) =>{
    if(getLotes.currentData?.records.length && ref){

      const bounds = getLotes.currentData.records.filter(lote => !!lote.geocerca).map((lote)=>{
        // L.geoJSON(lote.geocerca).addData()
        // const vertices = lote.geocerca ? lote.geocerca.map((v)=>[v.latitud, v.longitud] as LatLngTuple) : []
        // return [
        //   ...bounds,
        //   ...vertices
        // ]
        L.geoJSON(lote.geocerca as any, {
          pointToLayer: function(geoJsonPoint, latlng) {
            console.log(latlng)
            return L.marker(latlng);
          }
        }).addTo(ref)
        return lote.geocerca
      })
      if(bounds.length){
        ref.fitBounds(L.geoJSON(bounds as any).getBounds())
      }
      
    }
  }, [getLotes.currentData])

  if(getLotes.currentData){
    return <MapContainer
      ref={mapRef}
      preferCanvas
      zoom={10}
      center={[ubicacion.latitud, ubicacion.longitud]}
      style={{height: 480}}
    >
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
        // url={apiRoute("/{z}/{x}/{y}.png")}
      />
      {/* {getLotes.currentData.records.filter(lote=>!!lote.geocerca).map((lote: any)=>{
        console.log(lote.geocerca)
        return null
        // return <Polygon
        //   key={lote.id}
        //   positions={lote.geocerca.map((v: any)=>[v.latitud, v.longitud])}
        //   fillColor={
        //     lote.estado.code == 1 ? "#5cb85c" : "#5cb85c"
        //   }
        //   color={
        //     lote.estado.code == 1 ? "#5cb85c" : "#5cb85c"
        //   }
        // >
        //   <Tooltip>Mz {lote.manzana.numero}, Lt. {lote.numero}</Tooltip>
        // </Polygon>
      })} */}
    </MapContainer>
  }
  return null
}