import { useEffect } from "react"
import Select, { GroupBase, Props as SelectProps} from "react-select"
import { Categoria, useGetCategoriasQuery } from "../categoriaApi"

type Option = Categoria

type Props<IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>> = SelectProps<Option, IsMulti, Group> & {
  proyectoId: number
}

export function CategoriaSelect(props: Props) {

  const buscar = useGetCategoriasQuery({proyectoId: props.proyectoId})

  return <Select
    isClearable
    {...props}
    value={typeof props.value === "number" ? undefined : props.value}
    isLoading={buscar.isFetching}
    options={buscar.currentData?.records.slice().sort(({codigo: codigoA}, {codigo: codigoB})=>codigoA.length > codigoB.length ? 1 : codigoA.length < codigoB.length ? -1 : codigoA.localeCompare(codigoB))}
    loadingMessage={()=>"Cargando..."}
    placeholder=""
    formatOptionLabel={(option) => `Categoria ${option.codigo}`}
    getOptionValue={(option) => String(option.id)}
    getOptionLabel={(option) => String(option.codigo)}
    menuPortalTarget={document.body} 
    styles={{ menuPortal: base => ({ ...base, zIndex: 999 }) }}
  />
}