import React, { ComponentProps } from 'react'
import { ListGroup } from 'react-bootstrap';
import { Link, useMatch, useResolvedPath } from 'react-router-dom'

type MenuItem = {
  id: string,
  title: string,
  path: string,
  icon?: React.ReactNode,
  items?: undefined
} | {
  id: string,
  title: string,
  path?: string,
  icon?: React.ReactNode,
  items: {
    id: string,
    title: string,
    path: string,
    icon?: React.ReactNode,
  }[]
}

const SidebarItem = (item: MenuItem)=>{
  const path = useResolvedPath(item.path ? item.path + "/*" : "")
  const match = useMatch(path.pathname)

  return <>
    {item.path !== undefined ?
      <ListGroup.Item as={Link} to={item.path} 
        action
        active={!!match}
        className="text-nowrap sidebar-navitem"
        style={{
          borderLeft: !!match ? "0.25rem solid #EDE967" : 0
        }}
        key={item.id}>
          <span className="sidebar-navitem-icon">{item.icon}</span><span className="sidebar-navitem-title">{item.title}</span>
      </ListGroup.Item> : 
      <div
        className="list-group-item border-0 text-nowrap"
        key={item.id}>
          <div className="sidebar-navgroup-title text-muted">{item.title}</div>
      </div>
    }
    {item.items && item.items.map(item=>{
      return <SidebarItem key={item.id} {...item} />
    })}
  </>
}

export type SidebarProps = ComponentProps<"div"> & {
  side?: 'left' | 'right',
  header?: string | JSX.Element,
  items?: MenuItem[],
  renderItem?: (item: MenuItem) => JSX.Element
}
export const Sidebar = (props: SidebarProps) => {
  const {className="", side="right", header="", items=[], renderItem} = props
  const borderClass = {left: 'border-left', right: 'border-right'}
  return <div className={`${className}  ${borderClass[side]}`} id="sidebar-wrapper">
    <div className="sidebar-heading">
      <div className="sidebar-heading-content">{header}</div>
    </div>
    <hr className="mx-2"></hr>
    <div className="sidebar-menu">
      <ListGroup variant="flush">
        {items.map(item=>(
          <SidebarItem key={item.id} {...item} />
        ))}      
      </ListGroup>
    </div>
  </div>
  
}