import { ReactNode, useRef } from "react"
import { ModalContext } from "./ModalContext"

const { Provider } = ModalContext

type Props = {
  children: ReactNode
}

export const ModalProvider = ({children}: Props)=>{

  const valueRef = useRef({
    observers: { },
  })

  return <Provider value={valueRef.current}>
    {children}
  </Provider>
}