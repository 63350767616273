import React from "react"
import { Button, ButtonProps } from "react-bootstrap";
import { FaEllipsisV } from "react-icons/fa";

export const VerticalEllipsisDropdownToggle = React.forwardRef<HTMLButtonElement, ButtonProps>(({children, className, ...props}, ref) => (
  <Button
    className="py-0 px-1 border-0"
    style={{lineHeight: 1, verticalAlign: "text-top"}}
    {...props}
    ref={ref}
  >
    <FaEllipsisV />
  </Button>
));

export default VerticalEllipsisDropdownToggle