import { useEffect, useRef, useState } from "react"
import { Breadcrumb } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { useParams } from "react-router-dom"
import { IndexTemplate, TableView } from "../../../commons/components"
import { Page } from "../../../commons/services/types"
import { manzanaPolicy } from "../policies"
import { Filter, useGetManzanasQuery } from "../services"
import { ManzanaFormModal } from "./ManzanaFormModal"

export const ManzanaIndex = ()=>{
  const { proyectoId } = useParams() as any

  const [page, setPage] = useState<Page>({
    current: 1,
    size: 10
  })

  const [search, setSearch] = useState("")

  const formModalRef = useRef<{open():void}>(null)

  const getManzanas = useGetManzanasQuery({ search, page, proyectoId })
  // const [isRefetching, refetch] = useRefetch(getManzanas)
  const isRefetching = getManzanas.isFetching && !getManzanas.isLoading

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item active>Manzanas</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      policy={manzanaPolicy}
      onRegister={()=>formModalRef.current?.open()}
      page={page}
      total={getManzanas.currentData?.meta.totalRecords ?? -1}
      onPageChange={setPage}
      onSearch={setSearch}
      onRefetch={getManzanas.refetch}
      renderView={()=>{
        return <TableView
          expectedLength={page.size}
          isLoading={getManzanas.isFetching}
          data={isRefetching ? undefined : getManzanas.currentData?.records}
          hasError={getManzanas.isError}
          renderHeaders={()=>{
            return <tr>
              <th>#</th>
              <th>Número</th>
              <th>Cantidad de lotes</th>
              <th></th>
            </tr>
          }}
          renderRow={(item, index)=>{
            return <tr key={item?.id ?? index}>
              <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
              <td>{item ? `Mz. ${item.numero}` : <Skeleton />}</td>
              <td>{item ? item.totalLotes : <Skeleton />}</td>
              <td></td>
            </tr>
          }}
        />
      }}
    />
    <ManzanaFormModal ref={formModalRef} />
  </div>
}