import * as yup from "yup";
import { Alert, Breadcrumb, Form } from "react-bootstrap";
import { FaCheckCircle, FaSave } from "react-icons/fa";
import { Link } from "react-router-dom";
import { useForm, Controller } from "react-hook-form"
import { LoadingButton } from "../../../commons/components";
import { yupResolver } from "@hookform/resolvers/yup";
import { PermissionSelect } from "./PermissionSelect";
import { Rol, useActualizarRolMutation, useRegistrarRolMutation } from "../rolesApi";
import { useServerValidationErrors } from "../../../commons/hooks";

const schema = yup.object({
  name: yup.string().label("nombre").required(),
  description: yup.string().label("descripción").nullable(),
  permissions: yup.array().min(1, "Debe asignar al menos un permiso.")
})

type FormValues = {
  name: string
  description: string
  permissions: {
    name: string
  }[]
}
type Props = {
  data?: Rol
  readOnly?: boolean
}
export function RolForm(props: Props){
  const {
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<FormValues>({
    defaultValues: {
      name: props.data?.name ?? "",
      description: props.data?.description ?? "",
      permissions: props.data?.permissions ?? []
    },
    resolver: yupResolver(schema)
  })

  const [create, createState] = useRegistrarRolMutation()
  const [update, updateState] = useActualizarRolMutation()

  const mutationState = props.data ? updateState: createState

  useServerValidationErrors(mutationState, setError)

  const renderAlert = () => {
    if (mutationState.isSuccess) {
      return <Alert className="text-nowrap" variant="success">
        <FaCheckCircle className="me-1" style={{ position: "relative", top: "-0.125rem" }} />
        La solicitud se completó exitosamente.
      </Alert>
    }
    if (mutationState.isError) {
      const error = mutationState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <>
    {!props.data ? <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{
        to: "../roles"
      }}>Roles</Breadcrumb.Item>
      <Breadcrumb.Item active>Registro</Breadcrumb.Item>
    </Breadcrumb> : null}
    <Form aria-label="Formulario de registro de roles" onSubmit={handleSubmit((values) => {
      if(props.data) {
        update({
          id: props.data.id,
          name: values.name,
          description: values.description,
          permissions: values.permissions.map(p => p.name)
        })
          .unwrap()
          .then(()=>{
            reset(values)
            window.scrollTo(0, 0)
          })
          .catch(()=>{ })
      }
      else {
        create({
          name: values.name,
          description: values.description,
          permissions: values.permissions.map(p => p.name)
        })
          .unwrap()
          .then(()=>{
            reset()
            window.scrollTo(0, 0)
          })
          .catch(()=>{ })
      }  
    })}>
      {renderAlert()}
      <Form.Group data-testid="name-form-group" controlId="name" className="mb-3">
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          disabled={props.data && props.readOnly}
          readOnly={props.data && props.readOnly}
          isInvalid={!!formState.errors.name}
          {...register("name")}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.name?.message}</Form.Control.Feedback>
      </Form.Group>
      <Form.Group data-testid="description-form-group" controlId="description" className="mb-3">
        <Form.Label>Descripción</Form.Label>
        <Form.Control
          as="textarea"
          disabled={props.data && props.readOnly}
          readOnly={props.data && props.readOnly}
          isInvalid={!!formState.errors.description}
          {...register("description")}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.description?.message}</Form.Control.Feedback>
      </Form.Group>
      <Controller
        control={control}
        name="permissions"
        render={({field: {ref, ...field}, fieldState})=>{
          return <Form.Group data-testid="permissions-form-group" controlId="permissions" className="mb-3">
            <Form.Label>Permisos</Form.Label>
            <PermissionSelect
              // isClearable={!(props.data && props.readOnly)}
              // isSearchable={!(props.data && props.readOnly)}
              // menuIsOpen={props.data && props.readOnly ? false : undefined}
              isDisabled={props.data && props.readOnly}
              isMulti
              closeMenuOnSelect={false}
              inputId="permissions"
              isInvalid={!!formState.errors.permissions}
              {...field}
            />
            <Form.Control.Feedback type="invalid">{formState.errors.permissions?.message}</Form.Control.Feedback>
          </Form.Group>
        }}
      />
      
      {!props.readOnly ? <LoadingButton
        type="submit"
        disabled={!formState.isDirty || !!Object.keys(formState.errors).length}
        isLoading={mutationState.isLoading}
        icon={<FaSave style={{position: "relative", top: "-0.125rem"}} />}
      >
        Guardar
      </LoadingButton> : null}
    </Form>
  </>
}