import { skipToken } from "@reduxjs/toolkit/dist/query"
import Decimal from "decimal.js"
import moment from "moment"
import { useRef } from "react"
import { Breadcrumb, Col, Image, Modal, Row, Spinner, Table } from "react-bootstrap"
import { FaEye } from "react-icons/fa"
import { Link, useParams } from "react-router-dom"
import { NotFound } from "../../../commons/components"
import { Money } from "../../../commons/money"
import { useGetTransaccionQuery } from "../cajaApi"
import { MedioPagoFormModal, MedioPagoFormModalRef } from "./MedioPagoFormModal"

export function TransaccionView(){
  const params = useParams()
  const id = +params.id
  const getTransaccion = useGetTransaccionQuery(id || skipToken)
  const transaccion = getTransaccion.currentData;

  const medioPagoFormModalRef = useRef<MedioPagoFormModalRef>(null)

  // if(!transaccion) return null
  const renderContent = ()=>{
    const error = getTransaccion.error
    if(getTransaccion.isFetching){
      return <div className="text-nowrap">
        <Spinner animation="border" size="sm" /> Cargando...
      </div>
    }
    else if(error){
      if("data" in error){
        if(error.status === 404){
          return <NotFound
            message="Transaccion no encontrada"
          />
        }
      }
      else {

      }
    }
    else if(transaccion){
      return <div>
        <Row as="dl" className="gx-2">
          <Col xs={12} sm={4} lg={3}>
            <dt>Fecha</dt>
            <dd>{moment(transaccion.fecha, "YYYY-MM-DD").format("L")}</dd>
          </Col>
          <Col xs={12} sm={4} lg={3}>
            <dt>Importe total</dt>
            <dd>{new Decimal(transaccion.importe.amount).toFixed(2)} {transaccion.importe.currency}</dd>
          </Col>
          <Col xs={12} sm={4} lg={3}>
            <dt>Estado</dt>
            <dd>{{
              0: "Procesando",
              1: "Registrado",
              2: "Anulado"
            }[transaccion.estado] ?? ""}</dd>
          </Col>
          <Col xs={12}>
            <dt>Cliente</dt>
            <dd>{transaccion.cliente.nombreCompleto}</dd>
          </Col>
        </Row>
        <h2 className="fs-6">Conceptos</h2>
        <Table responsive>
          <thead>
            <tr>
              <th style={{width: 0}}>#</th>
              <th style={{minWidth: 240}}>Referencia</th>
              <th>Importe<div className="text-nowrap text-muted small fw-semibold">(en la moneda original)</div></th>
              <th>Importe<div className="text-nowrap text-muted small fw-semibold">(en la moneda de pago)</div></th>
            </tr>
          </thead>
          <tbody>
            {transaccion.detalles.map((detalle, index)=>{
              return <tr key={detalle.id}>
                <th scope="row">{index + 1}</th>
                <td>{detalle.referencia}</td>
                <td className="text-nowrap text-end">{new Decimal(detalle.importe.amount).toFixed(2)} {detalle.importe.currency}</td>
                <td className="text-nowrap text-end" title={detalle.importeMonedaTransaccion.amount}>{new Decimal(detalle.importeMonedaTransaccion.amount).toFixed(2)} {detalle.importeMonedaTransaccion.currency}</td>
              </tr>
            })}
          </tbody>
        </Table>
        <h2 className="fs-6">Medios de pago</h2>
        <Table responsive>
          <thead>
            <tr>
              <th style={{width: 0}}>#</th>
              <th>Forma de pago</th>
              <th style={{width: 0, minWidth: 150}}>Importe</th>
              <th style={{width: 0}}></th>
            </tr>
          </thead>
          <tbody>
            {transaccion.detallesPago.map((detalle, index)=>{
              return <tr key={index}>
                <th scope="row">{index + 1}</th>
                <td>{detalle.formaPagoText}</td>
                <td className="text-end">{new Decimal(detalle.importe.amount).toFixed(2)} {detalle.importe.currency}</td>
                <td className="text-center">
                  {detalle.formaPago == 2 ? <a onClick={()=>{
                    console.log(detalle.comprobante)
                    medioPagoFormModalRef.current?.open({
                      formaPago: {
                        id: detalle.formaPago,
                        label: detalle.formaPagoText
                      },
                      importe: detalle.importe as any,
                      numeroComprobante: detalle.numeroComprobante,
                      comprobante: detalle.comprobante as any ?? null
                    })
                  }}>
                    <FaEye />
                  </a> : null}
                </td>
              </tr>
            })}
          </tbody>
        </Table>
        <MedioPagoFormModal ref={medioPagoFormModalRef} readOnly />
      </div>
    }
    return null
  }

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../caja" }}>Caja</Breadcrumb.Item>
      <Breadcrumb.Item active>{id}</Breadcrumb.Item>
    </Breadcrumb>
    {renderContent()}
  </div>
}