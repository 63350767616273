import { createAction } from "@reduxjs/toolkit"
import { fetchBaseQuery } from "@reduxjs/toolkit/query"
import Qs from "qs"
import { keysToCamel, keysToUnderscore } from "../utils"

export const unauthorized =  createAction("UNAUTHORIZED" as const)

function getCookie(key: string) {
  var b = document.cookie.match("(^|;)\\s*" + key + "\\s*=\\s*([^;]+)");
  return b?.pop() ?? "";
}

export function createBaseQuery(baseUrl: string) {
  const baseQuery: typeof fetchBaseQuery = (options) => {
    const bq = fetchBaseQuery({
      prepareHeaders: (headers) => {
        const csrfToken = decodeURIComponent(getCookie("XSRF-TOKEN"))
        headers.set("X-XSRF-TOKEN", csrfToken)
        // headers.set('Access-Control-Allow-Credentials', 'true')
        headers.set("Accept", "application/json")
        return headers
      },
      ...options,
      fetchFn: async (...args) => {
        if((typeof args[0] === "string" ? args[0] : (args[0] as Request).url).endsWith("/login")) fetch(`${baseUrl.replace(/\/$/, '')}/sanctum/csrf-cookie`, {
          credentials: "include"
        })
        return fetch(...args)
      },
      baseUrl,
      paramsSerializer: params => {
        return Qs.stringify(keysToUnderscore(params), {
          arrayFormat: "brackets",
          encode: false,
          filter: (prefix, value) => {
            if (typeof value === "boolean") {
              return value ? 1 : 0
            }
            if (value === "") return undefined
            return value
          }
        });
      }
    })

    return async (args, api, extraOptions) => {
      if(typeof args === "string") {
        args = {
          url: args,
        }
      }
      if(args.body && !(args.body instanceof FormData)) args.body = keysToUnderscore(args.body)
      args.credentials = "include"
      args.mode = "cors"
      
      const result = await bq(args, api, extraOptions)
      if (result.error && (result.error.status === 401 || result.error.status === 419)) {
        api.dispatch(unauthorized())
      }
      
      result.data = keysToCamel(result.data)
      result.error = keysToCamel(result.error)
      
      return result
    }
  }
  return baseQuery
}