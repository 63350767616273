/**
 * 
 * @author Junaid Atari
 * @link https://github.com/blacksmoke26
 * @since 2020-09-20
 */

import { useCallback, useSyncExternalStore } from 'react';

export type Breakpoint = 'xs' | 'sm' | 'md' | 'lg' | 'xl' | 'xxl';

export const SIZE_XS: Breakpoint = 'xs';
export const SIZE_SM: Breakpoint = 'sm';
export const SIZE_MD: Breakpoint = 'md';
export const SIZE_LG: Breakpoint = 'lg';
export const SIZE_XL: Breakpoint = 'xl';
export const SIZE_XXL: Breakpoint = 'xxl';

const resolveBreakpoint = ( windowWidth: number, breakpoint: Breakpoint ): boolean => {
  switch(breakpoint){
    case SIZE_SM: return windowWidth >= 576
    case SIZE_MD: return windowWidth >= 768
    case SIZE_LG: return windowWidth >= 992
    case SIZE_XL: return windowWidth >= 1200
    case SIZE_XXL: return windowWidth >= 1440
    default: return true
  }
};

/** Get Media Query Breakpoints in React */
const useBreakpointResolver = () => {
  const windowWidth = useSyncExternalStore((listener)=>{
    window.addEventListener('resize', listener)
    return () => window.removeEventListener('resize', listener)
  }, ()=>window.innerWidth)

  return useCallback((breakpoint: Breakpoint) => resolveBreakpoint(windowWidth, breakpoint), [windowWidth])
};

export default useBreakpointResolver;
