import "./configs/yup"
import "./configs/array"
import "./configs/moment"
import React from 'react';
import { BrowserRouter as Router } from "react-router-dom"
import './bootstrap/components/custom.scss';
import {AppRoutes} from './bootstrap/components/AppRoutes';
import { store } from './bootstrap/store';
import reportWebVitals from './reportWebVitals';
import { Provider } from 'react-redux'
import 'react-loading-skeleton/dist/skeleton.css'
import { ModalProvider } from './commons/modal-context';
import { createRoot } from 'react-dom/client';

const container = document.getElementById('root');
const root = createRoot(container!);
root.render(<React.StrictMode>
  <Router>
    <Provider store={store}>
      <ModalProvider>
        <AppRoutes />
      </ModalProvider>
    </Provider>
  </Router>
</React.StrictMode>);

// if (process.env.NODE_ENV === 'development') {
//   // import('./__mocks__/browser').then(({worker}) => {
//   //   worker.start()
//   // })
//   const { worker } = require('./__mocks__/browser')
//   worker.start()
// }

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
