import { appApi, Page, PaginatedResponse, PaginationParams } from "../../commons/services";

type UsuarioFilter = {
}

export type Usuario = {
  id: number
  username: string
  email: string
  password: string
  roles: string[]
  vendedorId?: number
  estado: number
  proyectoIds: number[]
}

export type RawUsuario = Omit<Usuario, "password"|"roles"> & {
  estadoText: string
  roles: {
    id: number,
    name: string
  }[],
  vendedor: {
    id: number
    nombreCompleto: string
  } | null
  proyectos: {
    id: number
    nombre: string
  }[]
}

export const usuariosApi = appApi.enhanceEndpoints({
  addTagTypes: ["Usuario"]
}).injectEndpoints({
  endpoints: (builder) => ({
    getUsuarios: builder.query<PaginatedResponse<Omit<RawUsuario, "vendedor" | "proyectos">>, PaginationParams<UsuarioFilter>>({
      query: (params) => {
        return {
          url: "/usuarios",
          params
        }
      },
      providesTags: (results) => (results?.records ?? [])
        .map(({ id }) => ({ type: "Usuario" as const, id: String(id) }))
        .append({ type: "Usuario" as const, id: "*" })
    }),
    getUsuario: builder.query<RawUsuario, number>({
      query: (usuarioId) => `/usuarios/${usuarioId}`,
      providesTags: (result) => result ? [{ type: "Usuario" as const, id: String(result.id) }] : []
    }),

    //Mutations
    registrarUsuario: builder.mutation<RawUsuario, Omit<Usuario, "id" | "estado">>({
      query: (body) => ({
        method: "POST",
        url: "/usuarios",
        body
      }),
      invalidatesTags: [{ type: "Usuario" as const, id: "*" }]
    }),
    actualizarUsuario: builder.mutation<RawUsuario, Partial<Omit<Usuario, "id" | "estado">> & Pick<Usuario, "id">>({
      query: ({id, ...body}) => ({
        method: "PUT",
        url: `/usuarios/${id}`,
        body
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled
          for(let tag of (getState().appApi.provided.Usuario[args.id] ?? [])){
            const regex = /(?<endpoint>.*)\((?<queryArgs>.*)\)/
            const {groups: {endpoint, queryArgs}} = regex.exec(tag) as any
            if(endpoint === "getUsuarios"){
              dispatch(usuariosApi.util.updateQueryData('getUsuarios', JSON.parse(queryArgs), (draft) => {
                Object.assign(draft, {
                  records: draft.records.map(u => {
                    return u.id === args.id ? {
                      ...u,
                      ...args
                    } : u
                  }),
                })
              }))
            }
            else if(endpoint === "getUsuario"){
              dispatch(usuariosApi.util.updateQueryData('getUsuario', +queryArgs, (draft) => {
                Object.assign(draft, args)
              }))
            }
          }
        }
        catch (e) { }
      }
    }),
    changeUserStatus: builder.mutation<void, {
      id: number
      status: 1 | 2
    }>({
      query: ({ id, status }) => ({
        method: "PUT",
        url: `/usuarios/${id}/${status == 1 ? "activar" : "desactivar"}`,
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled
          for(let tag of (getState().appApi.provided.Usuario[args.id] ?? [])){
            const regex = /(?<endpoint>.*)\((?<queryArgs>.*)\)/
            const {groups: {endpoint, queryArgs}} = regex.exec(tag) as any
            if(endpoint === "getUsuarios"){
              dispatch(usuariosApi.util.updateQueryData('getUsuarios', JSON.parse(queryArgs), (draft) => {
                Object.assign(draft, {
                  records: draft.records.map(u => {
                    return u.id === args.id ? {
                      ...u,
                      estado: args.status
                    } : u
                  }),
                })
              }))
            }
            else if(endpoint === "getUsuario"){
              dispatch(usuariosApi.util.updateQueryData('getUsuario', +queryArgs, (draft) => {
                Object.assign(draft, {
                  estado: args.status
                })
              }))
            }
          }
        }
        catch (e) { }
      }
    })
  })
})

export const {
  useGetUsuariosQuery,
  useGetUsuarioQuery,
  useRegistrarUsuarioMutation,
  useActualizarUsuarioMutation,
  useChangeUserStatusMutation
} = usuariosApi