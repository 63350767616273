import { LatLngExpression } from "leaflet"
import { useCallback, useRef } from "react"
import { Alert, Form } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { FaCheckCircle, FaSave } from "react-icons/fa"
import { LoadingButton } from "../../../commons/components"
import { LocationControl } from "../../../commons/components/LocationControl"
import { useActualizarProyectoMutation, useProyectoContext } from "../proyectosApi"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useServerValidationErrors } from "../../../commons/hooks"

const schema = yup.object({
  nombre: yup.string().required(),
  ubicacion: yup.mixed().label("ubicación").required(),
})

type FormValues = {
  nombre: string
  // socio: string
  ubicacion: LatLngExpression|null
}
export const GeneralSettingsForm = ()=>{
  const proyecto = useProyectoContext()!
  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<FormValues>({
    defaultValues: {
      nombre: proyecto.nombre,
      ubicacion: proyecto.ubicacion ? [proyecto.ubicacion.latitud, proyecto.ubicacion.longitud] : null
    },
    resolver: yupResolver(schema)
  })

  const [update, updateState] = useActualizarProyectoMutation()

  useServerValidationErrors(updateState, setError, useCallback((key: string) => {
    if (key === "ubicacion.latitud" || key == "ubicacion.longitud") return "ubicacion"
    return key
  }, []))

  // const showMutationAlerts = useRef<boolean>(false)
  // if(formState.isDirty){
  //   showMutationAlerts.current = false
  // }

  const renderAlert = () => {
    // if(!showMutationAlerts.current){
    //   return null
    // }
    if(updateState.isSuccess){
      return <Alert className="text-nowrap" variant="success">
        <FaCheckCircle className="me-1" style={{position: "relative", top: "-0.125rem"}} />
        Guardado.
      </Alert>
    }
    if(updateState.isError){
      const error = updateState.error as any
      const message: Array<JSX.Element|string> = ["Ocurrio un error al realizar la solicitud"]
      if(error.status != 422) message.push(":", <br key="lnbr"/>, error.message ?? error.data.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <>
    <Form aria-label="Formulario de ajustes generales del proyecto" onSubmit={handleSubmit(async (values)=>{
      try{
        await update({
          id: proyecto.id,
          nombre: values.nombre,
          ubicacion: /*values.ubicacion &&*/ (values.ubicacion instanceof Array ? {
            latitud: values.ubicacion[0],
            longitud: values.ubicacion[1]
          } : {
            latitud: values.ubicacion!.lat,
            longitud: values.ubicacion!.lng
          })
          // ...Object.keys(formState.dirtyFields).filter(k => !!formState.dirtyFields[k]).map((key)=>{
          //   if(key === "ubicacion"){
          //     return values.ubicacion && (values.ubicacion instanceof Array ? {
          //       latitud: values.ubicacion[0],
          //       longitud: values.ubicacion[1]
          //     } : {
          //       latitud: values.ubicacion.lat,
          //       longitud: values.ubicacion.lng
          //     })
          //   }
          //   return values[key as any]
          // })
        })
        .unwrap()
        // .then(()=>{
        reset(values)
        // }, ()=>{})
      }
      catch(e){ }
      finally{
        // showMutationAlerts.current = true
      }
    })}>
      {renderAlert()}
      <Form.Group data-testid="nombre-form-group" className="mb-3" controlId="nombre">
        <Form.Label>Nombre</Form.Label>
        <Form.Control
          {...register("nombre")}
          isInvalid={!!formState.errors.nombre}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.nombre?.message}</Form.Control.Feedback>
      </Form.Group>
      <Controller
        control={control}
        name="ubicacion"
        render={({field: {ref, ...field}, fieldState})=>{
          return <Form.Group data-testid="ubicacion-form-group" className="mb-3" controlId="ubicacion">
            <Form.Label>Ubicación</Form.Label>
            <LocationControl
              {...field}
              zoom={5}
              center={field.value ?? [-17.7837273,-63.1822753]}
              isInvalid={!!fieldState.error}
            />
            <Form.Control.Feedback type="invalid">{fieldState.error?.message?.split("\n").map((e, i)=><div key={i}>{e}</div>)}</Form.Control.Feedback>
          </Form.Group>
        }}
      />
      <LoadingButton
        type="submit"
        disabled={!formState.isDirty}
        isLoading={updateState.isLoading}
        icon={<FaSave style={{position: "relative", top: "-0.125rem"}} />}
      >
        Guardar
      </LoadingButton>
    </Form>
  </>
}