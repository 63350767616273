import { useEffect } from "react"
import Select, { GroupBase, Props as SelectProps} from "react-select"
import { useGetParsedLotesQuery, Filter } from "../services"
import { LoteView } from "../types"

type Option = LoteView

type Props<IsMulti extends boolean = boolean, Group extends GroupBase<Option> = GroupBase<Option>> = Omit<SelectProps<Option, IsMulti, Group>, "value"> & {
  condition: boolean
  proyectoId: number,
  filter?: Filter,
  value: SelectProps<Option, IsMulti, Group>["value"] | number
  isInvalid: boolean
}

export function LotesSelect(props: Props) {

  const buscar = useGetParsedLotesQuery({proyectoId: props.proyectoId, filter: props.filter}, {
    skip: !props.condition
  })

  useEffect(()=>{
    const {value} = props
    if(typeof value === "number" && buscar.currentData){
      const option = buscar.currentData.records.find(d => d.id === value)
      if(option) props.onChange?.(option, {action: "select-option", option})
      else props.onChange?.(null, {action: "deselect-option", option: undefined})
    }
  }, [buscar.currentData, props.value])

  return <Select
    isClearable
    {...props}
    value={typeof props.value === "number" ? undefined : props.value}
    isLoading={buscar.isFetching}
    options={buscar.currentData?.records.slice().sort(({numero: numeroA}, {numero: numeroB})=>numeroA.length > numeroB.length ? 1 : numeroA.length < numeroB.length ? -1 : numeroA.localeCompare(numeroB))}
    loadingMessage={()=>"Cargando..."}
    placeholder=""
    formatOptionLabel={(option) => `${option.categoria.codigo} - Lote ${option.numero}`}
    getOptionValue={(option) => String(option.id)}
    getOptionLabel={(option) => String(option.numero)}
    className={["form-control", props.isInvalid ? "is-invalid" : undefined, props.className].filter(cn => !!cn).join(' ')}
    menuPortalTarget={document.body}styles={{
      menuPortal: base => ({ ...base, zIndex: 999 }),
      container: base => ({...base, padding: 0}),
      control: (base, state)=>{
        delete base["boxShadow"]
        return {
          ...base,
          backgroundColor: state.isDisabled ? "#e9ecef" : base.backgroundColor,
          borderRadius: "0.375rem",
          borderWidth: 0,
        }
      },
      multiValue: (base, state) => state.isDisabled ? { ...base, backgroundColor: "rgba(0,0,0,0.1)"} : base,
      ...(props.styles ?? {})
    }}
  />
}