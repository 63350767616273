import moment from "moment"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useCallback, useEffect } from "react"
import { Alert, Breadcrumb, Col, Form, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { FaCashRegister, FaSave } from "react-icons/fa"
import { Link } from "react-router-dom"
import { LoadingButton } from "../../../commons/components"
import { ClienteSelect } from "../../clientes/components/ClienteSelect"
import { ClienteView } from "../../clientes/services/clientesApi"
import { Money, MoneyInput } from "../../../commons/money"
import { LotesSelect } from "../../lotes/components"
import { LoteView } from "../../lotes/types"
import { ManzanasSelect } from "../../manzanas/components/ManzanasSelect"
import { ManzanaView } from "../../manzanas/types"
import { VendedorSelect, VendedorView } from "../../vendedores/components/VendedorSelect"
import { useRegistrarReservaMutation } from "../reservaApi"
import { useProyectoContext } from "../../proyectos/proyectosApi"
import { useServerValidationErrors } from "../../../commons/hooks"

type FormValues = {
  fecha: string | Date
  manzana: ManzanaView | null
  lote: LoteView | null
  moneda: string
  precio: Money
  cuotaInicial: Money
  importe: Money
  saldoContado: Money
  saldoCredito: Money
  vendedor: VendedorView | null
  cliente: ClienteView | null
  vencimiento: string | Date
}

const schema = yup.object({
  fecha: yup.date().required(),
  vencimiento: yup.date().required().min(yup.ref("fecha")),
  moneda: yup.string().required().oneOf(["USD", "BOB"]),
  lote: yup.mixed().required(),
  cliente: yup.mixed().required(),
  vendedor: yup.mixed().required(),
  importe: yup.mixed().required(),
  saldoContado: yup.mixed().required(),
  saldoCredito: yup.mixed().required()
})

export function ReservaForm(){

  const proyecto = useProyectoContext()!

  const today = moment()

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(schema) as any,
    defaultValues: {
      fecha: today.format("YYYY-MM-DD"),
      vencimiento: today.add(proyecto.duracionReservas, "days").format("YYYY-MM-DD"),
      moneda: proyecto.moneda,
      manzana: null,
      lote: null,
      importe: new Money(null, proyecto.moneda),
      precio: new Money(null, proyecto.moneda),
      cuotaInicial: new Money(null, proyecto.moneda),
      saldoContado: new Money(null, proyecto.moneda),
      saldoCredito: new Money(null, proyecto.moneda),
      vendedor: null,
      cliente: null
    }
  })

  useEffect(() => {
    const subscription = watch(async (values, info) => {
      const moneda = values.moneda!
      if(info.name === "fecha"){
        setValue("vencimiento", moment(values.fecha).add(proyecto.duracionReservas, "days").format("YYYY-MM-DD"))
      }
      else if (info.name === "manzana" && values.lote) {
        setValue("lote", null, {
          shouldValidate: formState.isSubmitted
        })
      }
      else if(info.name === "lote"){
        const lote  = values.lote as LoteView | null
        if(!lote){
          setValue("importe", new Money(null, moneda), {
            shouldValidate: formState.isSubmitted
          })
          setValue("precio", new Money(null, moneda), {
            shouldValidate: formState.isSubmitted
          })
          setValue("cuotaInicial", new Money(null, moneda), {
            shouldValidate: formState.isSubmitted
          })
        }
        else{
          const precio = await (lote.precio ?? lote.precioSugerido).exchangeTo(moneda)
          const cuotaInicial = await proyecto.cuotaInicial.exchangeTo(moneda)
          const reserva = await proyecto.precioReservas.exchangeTo(moneda)
          
          setValue("importe", reserva, {
            shouldValidate: formState.isSubmitted
          })
          setValue("precio", precio, {
            shouldValidate: formState.isSubmitted
          })
          setValue("cuotaInicial", cuotaInicial, {
            shouldValidate: formState.isSubmitted
          })
        }
      }
      else if(info.name === "moneda"){        
        const precio = values.precio as Money
        const cuotaInicial = values.cuotaInicial as Money
        const reserva = values.importe as Money

        setValue("importe", await reserva.exchangeTo(moneda, {
          exchangeMode: proyecto.moneda === moneda ? "buy" : "sell"
        }), {
          shouldValidate: formState.isSubmitted
        })
        setValue("precio", await precio.exchangeTo(moneda, {
          exchangeMode: proyecto.moneda === moneda ? "buy" : "sell"
        }), {
          shouldValidate: formState.isSubmitted
        })
        setValue("cuotaInicial", await cuotaInicial.exchangeTo(moneda, {
          exchangeMode: proyecto.moneda === moneda ? "buy" : "sell"
        }), {
          shouldValidate: formState.isSubmitted
        })
      }
      else if(info.name === "importe"){  
        const precio = values.precio as Money
        const cuotaInicial = values.cuotaInicial as Money
        const reserva = values.importe as Money

        if(precio.currency === reserva.currency){
          const saldoContado = precio.sub(reserva)
          setValue("saldoContado", saldoContado.amount?.lessThan("0") ? new Money("0", moneda) : saldoContado, {
            shouldValidate: formState.isSubmitted
          })
        }

        if(cuotaInicial.currency === reserva.currency){
          const saldoCredito = cuotaInicial.sub(reserva)
          setValue("saldoCredito", saldoCredito.amount?.lessThan("0") ? new Money("0", moneda) : saldoCredito, {
            shouldValidate: formState.isSubmitted
          })
        }

      }
      else if(info.name === "precio"){
        const precio = values.precio as Money
        const reserva = values.importe as Money

        const saldoContado = precio.sub(reserva)
        setValue("saldoContado", saldoContado.amount?.lessThan("0") ? new Money("0", moneda) : saldoContado, {
          shouldValidate: formState.isSubmitted
        })
      }
      else if(info.name === "cuotaInicial"){
        const cuotaInicial = values.cuotaInicial as Money
        const reserva = values.importe as Money

        const saldoCredito = cuotaInicial.sub(reserva)
        setValue("saldoCredito", saldoCredito.amount?.lessThan("0") ? new Money("0", moneda) : saldoCredito, {
          shouldValidate: formState.isSubmitted
        })
      }
    })
    return () => subscription.unsubscribe()
  }, [formState.isSubmitted, proyecto])

  const [ guardar, guardarState ] = useRegistrarReservaMutation()

  useServerValidationErrors(guardarState, setError, useCallback((key: string) => {
    if (key == "loteId") return "lote"
    if (key == "manzanaId") return "manzana"
    if (key == "vendedorId") return "vendedor"
    if (key == "clienteId") return "cliente"
    return key
  }, []))

  const renderAlert = ()=>{
    if(guardarState.isSuccess){
      return <Alert className="d-flex" variant="success">
        <div>La solicitud se completo exitosamente</div>
        <div className="ms-auto">
          <Link className="btn btn-primary" to={`/caja/registrar?codigo_pago=CLI-${guardarState.data.clienteId}`} target="_blank">
            <FaCashRegister className="me-2" style={{position: "relative", top: "-0.125rem"}}/>
            Ir a caja
          </Link>
        </div>
      </Alert>
    }
    if(guardarState.isError){
      const error = guardarState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{to: "../reservas"}}>Reserva</Breadcrumb.Item>
      <Breadcrumb.Item active>Registro</Breadcrumb.Item>
    </Breadcrumb>
    <Form onSubmit={handleSubmit((values)=>{
      guardar({
        fecha: typeof values.fecha === "string" ? values.fecha : moment(values.fecha).format("YYYY-MM-DD"),
        loteId: values.lote!.id,
        clienteId: values.cliente!.id,
        vendedorId: values.vendedor!.id,
        moneda: values.moneda,
        importe: values.importe.amount!.toFixed(2),
        saldoContado: values.saldoContado.amount!.toFixed(2),
        saldoCredito: values.saldoCredito.amount!.toFixed(2),
        vencimiento: typeof values.vencimiento === "string" ? values.vencimiento : moment(values.vencimiento).format("YYYY-MM-DD"),
        proyectoId: proyecto.id
      }).unwrap().then(()=>{
        reset()
        window.scrollTo(0, 0)
      }, ()=>{})
    }, console.error)}>
      {renderAlert()}
      <Row className="gx-2">
        <Col md={6}>
          <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
            <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Moneda</Form.Label>
            <Row className="gx-2" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
              <Col>
                <Form.Check 
                  id="dolar"
                  type="radio"
                  // label={currencies["USD"].name.toLowerCase().replace(/^\w/, c => c.toUpperCase())}
                  label="Dolar"
                  value="USD"
                  {...register("moneda")}
                />
              </Col>
              <Col>
                <Form.Check
                  id="boliviano"
                  type="radio"
                  label="Boliviano"
                  value={"BOB"}
                  {...register("moneda")}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-2">
        <Col lg={6}>
          <Row className="gx-2">
            <Form.Group data-testid="fecha-form-group" as={Col} xs={6} sm={4} md={3} lg={4} className="mb-3" controlId="fecha">
              <Form.Label>Fecha</Form.Label>
              <Form.Control
                type="date"
                {...register("fecha")}
                isInvalid={!!formState.errors.fecha}
              />
              <Form.Control.Feedback>{formState.errors.fecha?.message}</Form.Control.Feedback>
            </Form.Group>
            <Col sm={8} md={6} lg={8} >
              <Row className="gx-2">                
                <Form.Group data-testid="manzana-form-group" as={Col} xs={6} className="mb-3">
                  <Form.Label htmlFor="manzana">Manzana</Form.Label>
                  <Controller
                    control={control}
                    name="manzana"
                    render={({ field: {ref, ...field}, fieldState }) => {
                      return <>
                        <ManzanasSelect
                          inputId="manzana"
                          isMulti={false}
                          className={"text-uppercase"}
                          {...field}
                          proyectoId={proyecto.id}
                          isInvalid={!!fieldState.error}
                        />
                        <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                      </>
                    }}
                  />
                </Form.Group>
                <Form.Group data-testid="lote-form-group" as={Col} xs={6} className="mb-3">
                  <Form.Label htmlFor="lote">Lote</Form.Label>
                  <Controller
                    control={control}
                    name="lote"
                    render={({ field: {ref, ...field}, fieldState }) => {
                      return <>
                        <LotesSelect
                          inputId="lote"
                          isMulti={false}
                          className={"text-uppercase"}
                          {...field}
                          value={field.value as any}

                          condition={!!watch("manzana")}
                          proyectoId={proyecto.id}
                          filter={{ manzanaId: watch("manzana")?.id, estado: 1 }}
                          isInvalid={!!fieldState.error}
                        />
                        <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                      </>
                    }}
                  />
                </Form.Group>
              </Row>
            </Col>
          </Row>
        </Col>
        <Form.Group data-testid="precio-form-group" as={Col} xs={6} sm={4} md={3} lg={2} className="mb-3" controlId="importe">
          <Form.Label>Precio</Form.Label>
          <Controller
            control={control}
            name="precio"
            render={({field, fieldState})=>{
              return <MoneyInput
                {...field}
              />
            }}
          />
        </Form.Group>
        <Form.Group data-testid="cuotainicial-form-group" as={Col} xs={6} sm={4} md={3} lg={2} className="mb-3" controlId="importe">
          <Form.Label>Cuota inicial</Form.Label>
          <Controller
            control={control}
            name="cuotaInicial"
            render={({field, fieldState})=>{
              return <MoneyInput
                {...field}
              />
            }}
          />
        </Form.Group>
        
          <Controller
            control={control}
            name="importe"
            render={({field, fieldState})=>{
              return <Form.Group data-testid="reserva-form-group" as={Col} xs={6} sm={4} md={3} lg={2} className="mb-3" controlId="importe">
                <Form.Label>Reserva</Form.Label><MoneyInput
                  {...field}
                  isInvalid={!!fieldState.error}
                />
                <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
              </Form.Group>
            }}
          />
      </Row>
      <Row className="gx-2">
        <Form.Group data-testid="vendedor-form-group" as={Col} md={6} className="mb-3">
          <Form.Label htmlFor="vendedor">Vendedor</Form.Label>
          <Controller
            control={control}
            name="vendedor"
            render={({field: {ref, ...field}, fieldState})=>{
              return <VendedorSelect
                inputId="vendedor"
                {...field}
                className={fieldState.error ? "is-invalid" : ""}
                feedback={fieldState.error?.message}
              />
            }}
          />
        </Form.Group>
        <Form.Group data-testid="cliente-form-group" as={Col} md={6} className="mb-3">
          <Form.Label htmlFor="cliente">Cliente</Form.Label>
          <Controller
            control={control}
            name="cliente"
            render={({field: {ref, ...field}, fieldState})=>{
              return <ClienteSelect
                inputId="cliente"
                {...field}
                className={fieldState.error ? "is-invalid" : ""}
                feedback={fieldState.error?.message}
              />
            }}
          />
        </Form.Group>
      </Row>
      <h2>Importe pendiente de pago</h2>
      <Row className="gx-2">
        <Form.Group data-testid="vencimiento-form-group" as={Col} xs={6} sm={4} md={3} lg={2} className="mb-3" controlId="vencimiento">
          <Form.Label>Vencimiento</Form.Label>
          <Form.Control
            type="date"
            {...register("vencimiento")}
            isInvalid={!!formState.errors.vencimiento}
          />
          <Form.Control.Feedback type="invalid">{formState.errors.vencimiento?.message}</Form.Control.Feedback>
        </Form.Group>
        <Controller
          control={control}
          name="saldoContado"
          render={({field, fieldState})=>{
            return <Form.Group data-testid="saldocontado-form-group" as={Col} xs={6} sm={4} md={3} lg={2} className="mb-3" controlId="precio">
              <Form.Label>Venta al contado</Form.Label>
              <MoneyInput
                readOnly
                {...field}
                onChange={undefined}
                isInvalid={!!fieldState.error}
              />                
              <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
            </Form.Group>
          }}
        />        
        <Controller
          control={control}
          name="saldoCredito"
          render={({field, fieldState})=>{
            return <Form.Group data-testid="saldocredito-form-group" as={Col} xs={6} sm={4} md={3} lg={2} className="mb-3" controlId="cuotaInicial">
              <Form.Label>Venta al credito</Form.Label>
              <MoneyInput
                readOnly
                {...field}           
                onChange={undefined}
              />
              <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
            </Form.Group>
          }}
        />
      </Row>
      <Row className="gx-2">
        <Col sm="auto">
          <LoadingButton type="submit" className="w-100" isLoading={guardarState.isLoading} icon={<FaSave style={{position: "relative", top: "-0.125em"}} />}>
            Guardar
          </LoadingButton>
        </Col>
      </Row>
    </Form>
  </div>
}