import ReactDOM from "react-dom"
import {Dropdown} from "react-bootstrap"
import {Link} from "react-router-dom"
import {VerticalEllipsisDropdownToggle} from "../../../commons/components"
import { VentaIcon } from "../../../commons/components/icons"
import { RawReserva } from "../types"

type Props = {
  reserva: RawReserva
}

export function RowOptions({
  reserva
}:Props){
  return <Dropdown>
    <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
    {ReactDOM.createPortal(
      <Dropdown.Menu style={{textTransform: 'none'}}>
        {
          reserva.estado == 1 ?
            <Dropdown.Item as={Link} to="../ventas/registrar" state={{reserva}}>
              <span className="me-2"><VentaIcon style={{position: "relative", top: "0.125rem"}} /></span>
              <span>Concretar venta</span>
            </Dropdown.Item> :
            null
        }
      </Dropdown.Menu>
    , document.body)}
  </Dropdown>
}