import ReactDOM from "react-dom"
import {Dropdown} from "react-bootstrap"
import {VerticalEllipsisDropdownToggle} from "../../../commons/components"
import { FaEdit } from "react-icons/fa"
import { Link, useParams } from "react-router-dom"
import { Plano } from "../planosApi"

type Props = {
  plano: Plano
}

export function RowOptions({
  plano
}:Props){
  const {proyectoId} = useParams()
  const options: React.ReactElement[] = []
  if(!plano.isLocked){
    options.push(
      <Dropdown.Item key="edit" as={Link} to={`${plano.id}/editar`}>
        <FaEdit className="me-2" style={{position: "relative", top: "-0.125rem"}} />
        Editar
      </Dropdown.Item>
    )
  }
  if(!options.length) return null
  return <Dropdown>
    <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
    {ReactDOM.createPortal(
      <Dropdown.Menu style={{textTransform: 'none'}}>
        {options}
      </Dropdown.Menu>
    , document.body)}
  </Dropdown>
}