import React, { useState} from "react"
import { Button, Col, Collapse, Form, InputGroup, Row, Table } from "react-bootstrap"
import { Link, LinkProps } from "react-router-dom"
import { FaPlus, FaFilter, FaSearch, FaSync} from "react-icons/fa"
import { Pagination } from "./Pagination"
import { Page } from "../services"
import { User, ProtectedContent } from "../auth"

type ActionButtonConfig = {
  asLink: true,
  icon: React.ReactNode
  to: LinkProps["to"]
  state?: LinkProps["state"]
  authorize?: (user: User) => boolean | undefined
} | {
  asLink?: false
  icon: React.ReactNode
  onClick: ()=>void
  authorize?: (user: User) => boolean | undefined
}

type Props = {
  page?: Page
  pageSizes?: number[]
  total?: number
  actions?: ActionButtonConfig[]
  renderView: () => React.ReactElement
  renderFilterForm?: () => React.ReactElement
  onRefetch: () => void
  onPageChange?: (page: Page) => void
  onSearch?: (search: string) => void
} & ({  
  registerPath?: undefined
  onRegister(): void
  policy: {
    view(user: User): boolean | undefined
    register(user: User): boolean | undefined
  }
} | {  
  registerPath: string
  onRegister?: undefined
  policy: {
    view(user: User): boolean | undefined
    register(user: User): boolean | undefined
  }
} | {
  registerPath?: undefined
  onRegister?: undefined
  policy: {
    view(user: User): boolean | undefined
  }
})

export function IndexTemplate({
  total,
  page,
  pageSizes=[10,20,30,50,100],
  policy,
  registerPath,
  onRegister,
  actions,
  onSearch,
  onRefetch,
  onPageChange,
  renderView,
  renderFilterForm
}: Props) {
  
  const [ search, setSearch ] = useState("")
  const [ showFilterForm, setShowFilterForm] = useState(false)

  const renderActionButton = (action: ActionButtonConfig)=>{
    const button = <Col xs="auto" >
      {action.asLink ? 
        <Button 
          //@ts-expect-error
          as={Link}
          target="_blank"
          to={action.to}
          state={action.state}
        >
          {action.icon}
        </Button> :
        <Button 
          onClick={action.onClick}
        >
          {action.icon}
        </Button>}
    </Col>
    return action.authorize ? <ProtectedContent authorize={action.authorize}>
      {button}
    </ProtectedContent> : button
  }

  return <div>
    <div className="d-flex my-2">
      <Row className="ms-auto flex-nowrap gx-2" >
        <ProtectedContent
          authorize={policy.view}
        >
          <Col xs="auto" >
            <Button onClick={()=>{
              onRefetch()
            }}><FaSync /></Button>
          </Col>
          {renderFilterForm ? <Col xs="auto" >
            <Button onClick={()=>{
              setShowFilterForm(visible=>!visible)
            }}><FaFilter /></Button>
          </Col> : null}
        </ProtectedContent>
        {actions?.map(renderActionButton)}
        {registerPath ? <ProtectedContent
          authorize={(policy as any).register}
        >
          <Col xs="auto">
            <Button
              //@ts-expect-error
              as={Link}
              to={registerPath}
            >
                <FaPlus className="me-1" style={{position: "relative", top: "-0.125rem"}} />
                Registrar
            </Button>
          </Col>
        </ProtectedContent> : null}
        {onRegister ? <ProtectedContent
          authorize={(policy as any).register}
        >
          <Col xs="auto">
            <Button
              onClick={onRegister}
            >
              <FaPlus className="me-1" style={{position: "relative", top: "-0.125rem"}} />
              Registrar
            </Button>
          </Col>
        </ProtectedContent> : null}
      </Row>
    </div>
    <ProtectedContent
      authorize={policy.view}
    >
      {renderFilterForm ? <Collapse in={showFilterForm}>
        <div className="mb-2">
          {renderFilterForm()}
        </div>
      </Collapse> : null}
      <Row className="mb-2">
        {onSearch ? <Col className="mb-2">
          <InputGroup style={{minWidth: "15rem"}}>
              <Form.Control aria-label="Busqueda" type="search" value={search} onChange={(e)=>{
                setSearch(e.target.value)
              }} />
              <Button onClick={()=>{
                onSearch(search)
              }}><FaSearch /></Button>
          </InputGroup>
        </Col> : null}
        {page ? <Col className="ms-auto" xs={"auto"}>
          <div className="d-flex flex-row flex-nowrap align-items-center">
            <span>Mostrar</span>
            <Form.Select aria-label="Tamaño de pagina"
              className="mx-2"
              value={page.size} onChange={(e) => {
                const value = e.target.value
                onPageChange?.({
                  current: 1,
                  size: parseInt(value)
                })
              }}
            >
              {pageSizes.map((size)=><option key={size} value={size}>{size}</option>)}
            </Form.Select>
            <span>resultados</span>
          </div>
        </Col> : null}
      </Row>
      {renderView()}
      {page ? <Row className="mt-2">
        <Col className="me-auto" xs="auto">
          {total! > 0 ? <span style={{whiteSpace: "nowrap"}}>Se encontraron {total!} resultados</span> : ""}
        </Col>
        <Col className="ms-auto" xs="auto">
          <Pagination
            current={page.current}
            total={Math.ceil(total! / page.size)}
            onChange={(current) => onPageChange?.({ ...page, current })}
          />
        </Col>
      </Row> : null}
    </ProtectedContent>
  </div>
}