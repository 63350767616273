import { useEffect } from "react"
import { Alert, Button, Col, Form, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { FaPlus, FaSave } from "react-icons/fa"
import { LoadingButton } from "../../../commons/components"
import { Money, MoneyInput } from "../../../commons/money"
import { useProyectoContext } from "../../proyectos/proyectosApi"
import { Categoria, useActualizarCategoriaMutation, useRegistrarCategoriaMutation } from "../categoriaApi"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useServerValidationErrors } from "../../../commons/hooks"

const schema = yup.object({
  codigo: yup.string()
    .label("código")
    .required()
    .max(4),
  descripcion: yup.string()
    .label("descripción")
    .max(255),
  precioM2: yup.mixed()
    .label('precio por m²')
    .test("min", "El campo '${path}' debe ser mayor a 0.", function(value: Money){
      return !value.amount || value.amount.gt("0")
    })
    .test("required", "El campo '${path}' es requerido.", function(value, context){
      return !!value.amount
    })
})

type FormValues = {
  codigo: string
  precioM2: Money
  descripcion: string
}
type Props = {
  categoria: Categoria | null
  onReset: () => void
}
export const CategoriaForm = ({
  categoria,
  onReset
}: Props) => {
  const proyecto = useProyectoContext()!

  const defaultValues: FormValues = {
    codigo: "",
    descripcion: "",
    precioM2: new Money(null, proyecto.moneda)
  }

  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    watch
  } = useForm<FormValues>({
    defaultValues,
    resolver: yupResolver(schema)
  })

  useEffect(() => {
    if (categoria) {
      //Usar reset en lugar de setValue para resetar el submitCount
      reset({
        codigo: categoria.codigo,
        descripcion: categoria.descripcion,
        precioM2: new Money(categoria.precioM2.amount, proyecto.moneda)
      })
      // setValue("codigo", categoria.codigo)
      // setValue("descripcion", categoria.descripcion)
      // setValue("precioM2", new Money(categoria.precioM2.amount, proyecto.moneda))
    }
    else {
      reset(defaultValues)
    }
  }, [categoria])

  const [create, createState] = useRegistrarCategoriaMutation()
  const [update, updateState] = useActualizarCategoriaMutation()

  useServerValidationErrors(categoria ? updateState : createState, formState.isSubmitted ? setError : undefined)

  const renderAlerts = ()=>{
    const alerts = []
    if(formState.isSubmitted){
      const state = categoria ? updateState : createState
      if(state.isError) {
        const error = state.error as any
        const message: Array<JSX.Element|string> = ["Ocurrio un error al realizar la solicitud"]
        if(error.status != 422) message.push(":", <>
          <br/>
          {error.message ?? error.data.message ?? "Error de red."}
        </>)
        else message.push(".")
        alerts.push(<Alert key="error" variant="danger">
          {message}
        </Alert>)
      }
    }
    return alerts
  }

  return <Form aria-label="Formulario categoria de lotes" onSubmit={handleSubmit((values) => {
    if (categoria) {
      update({
        id: categoria.id,
        codigo: values.codigo,
        precioM2: values.precioM2.amount!.toFixed(2),
        descripcion: values.descripcion,
        proyectoId: proyecto.id
      }).unwrap().then(() => {
        onReset()
      }, () => { })
    }
    else {
      create({
        codigo: values.codigo,
        precioM2: values.precioM2.amount!.toFixed(2),
        descripcion: values.descripcion,
        proyectoId: proyecto.id
      }).unwrap().then(() => {
        reset(defaultValues)
      }, () => { })
    }
  })}>
    {renderAlerts()}
    <Row className="gx-2">
      <Form.Group data-testid="codigo-form-group" as={Col} xs={6} lg={4} xl={3} className="mb-3" controlId="codigo">
        <Form.Label>Código</Form.Label>
        <Form.Control 
          className="text-uppercase"
          isInvalid={!!formState.errors.codigo}
          {...register("codigo")}
        />
        <Form.Control.Feedback type="invalid">{formState.errors.codigo?.message}</Form.Control.Feedback>
      </Form.Group>
      <Controller
        control={control}
        name="precioM2"
        render={({ field, fieldState }) => {
          return <Form.Group data-testid="precio-form-group" as={Col} xs={6} lg={4} xl={3} className="mb-3" controlId="precio-m2">
            <Form.Label>Precio por m²</Form.Label>
            <MoneyInput
              {...field}
              isInvalid={!!fieldState.error}
            />            
            <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
          </Form.Group>
        }}
      />
    </Row>
    <Form.Group data-testid="descripcion-form-group" className="mb-3" controlId="descripcion">
      <Form.Label>Descripción</Form.Label>
      <Form.Control
        as="textarea"
        className="text-uppercase"
        isInvalid={!!formState.errors.descripcion}
        {...register("descripcion")}
      />
      <Form.Control.Feedback type="invalid">{formState.errors.descripcion?.message}</Form.Control.Feedback>
    </Form.Group>

    <Row className="gx-2">
      <Col xs="auto">
        <LoadingButton type="submit" disabled={!formState.isDirty} className="w-100" isLoading={categoria ? updateState.isLoading : createState.isLoading} icon={<FaSave style={{ position: "relative", top: "-0.125em" }} />}>
          Guardar
        </LoadingButton>
      </Col>
      <Col xs="auto">
        <Button type="button" variant="outline-secondary" disabled={!formState.isDirty && !categoria} className="w-100" onClick={() => {
          categoria ? onReset() : reset()
        }}>
          <FaPlus className="me-1" style={{ position: "relative", top: "-0.125em" }} />
          Nuevo
        </Button>
      </Col>
    </Row>
  </Form>
}