import { skipToken } from "@reduxjs/toolkit/dist/query";
import Decimal from "decimal.js";
import moment from "moment";
import { useRef, useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Skeleton from "react-loading-skeleton";
import { IndexTemplate, TableView } from "../../../commons/components";
import { Page } from "../../../commons/services";
import { exchangeRatePolicy } from "../policies";
import { useGetExchangeRatesQuery } from "../services";
import { ExchangeRateForm, ExchangeRateFormModalRef } from "./ExchangeRateForm";
import { RowOptions } from "./RowOptions";

export function ExchangeRateIndex() {
  const [page, setPage] = useState<Page>({
    current: 1,
    size: 10
  })

  const [search, setSearch] = useState("")

  const formModalRef = useRef<ExchangeRateFormModalRef>(null)

  const buscar = useGetExchangeRatesQuery({ search, page })
  const isRefetching = buscar.isFetching && !buscar.isLoading

  return <>
    <Breadcrumb>
      <Breadcrumb.Item active>Tipos de cambio</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      policy={exchangeRatePolicy}
      onRegister={() => {
        formModalRef.current?.open()
      }}
      page={page}
      total={buscar.currentData?.meta.totalRecords ?? -1}
      onPageChange={setPage}
      onSearch={setSearch}
      onRefetch={buscar.refetch}
      renderView={() => {
        return <TableView
          expectedLength={page.size}
          isLoading={buscar.isFetching}
          data={isRefetching ? undefined : buscar.currentData?.records}
          hasError={buscar.isError}
          renderHeaders={() => {
            return <tr>
              <th style={{width: 0}}>#</th>
              <th>Valido desde</th>
              <th>Moneda de orígen</th>
              <th>Moneda de destino</th>
              <th>Cotización</th>
              <th style={{width: 0}}></th>
            </tr>
          }}
          renderRow={(item, index) => {
            return <tr key={item?.id ?? index}>
              <th scope="row">{(page.current - 1) * page.size + index + 1}</th>
              <td>{item ? moment(item.validFrom).format("L") : <Skeleton />}</td>
              <td>{item ? `${item.source.name} (${item.source.code})` : <Skeleton />}</td>
              <td>{item ? `${item.target.name} (${item.target.code})` : <Skeleton />}</td>
              <td>{item ? item.indirect ? `${new Decimal(item.rate).toSignificantDigits()} ${item.source.code} = 1 ${item.target.code}` : `1 ${item.source.code} = ${new Decimal(item.rate).toSignificantDigits()} ${item.target.code}` : <Skeleton />}</td>
              <td>{item ? <RowOptions data={item} /> : null}</td>
            </tr>
          }}
        />
      }}
    />
    <ExchangeRateForm
      ref={formModalRef}
    />
  </>
}