import moment from "moment";
import { useCallback, useEffect, useMemo, useRef, useState, useDeferredValue } from "react";
import { Alert, Breadcrumb, Button, Col, Form, InputGroup, Row, Spinner, Table } from "react-bootstrap";
import { LoadingButton } from "../../../commons/components";
import { FaEdit, FaEye, FaPlus, FaSave, FaSearch, FaTimes, FaTrash } from "react-icons/fa";
import { Controller, useFieldArray, useForm } from "react-hook-form";
import { Link, useSearchParams } from "react-router-dom";
import { useLazyGetCuotasPendientesQuery, usePagarCuotasMutation } from "../cajaApi";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import { useServerValidationErrors } from "../../../commons/hooks";
import { PagableRow } from "./PagableRow";
import { Pagable } from "../types";
import { MedioPagoFormModal, MedioPagoFormModalRef } from "./MedioPagoFormModal";
import { MoneySync } from "../../../commons/components/icons";
import { Money } from "../../../commons/money";
import { MdOutlineOpenInNew } from "react-icons/md";

type FormValues = {
  fecha: string | Date
  codigoPago: string
  cliente: {
    id: number
    nombreCompleto: string
  } | null
  moneda: string
  detalles: {
    pagable: Pagable
    cargo: Money
    cargoMonedaPago: Money
    abono: Money
    abonoMonedaPago: Money
  }[]
  mediosPago: {
    formaPago: {
      id: number
      label: string
    }
    importe: Money
    numeroComprobante: number
    comprobante: File
  }[]

  totalDeuda: Money
  totalAPagar: Money
  totalPagado: Money
  registrarExcedente: boolean
  // diferencia: Money
}

const schema = yup.object({
  fecha: yup.date().label("fecha").required(),
  cliente: yup.mixed().required(),

  detalles: yup.array(yup.object({
    abono: yup.mixed().test("between", "El pago no puede ser menor que 0 ni mayor al importe de la cuenta.", function (value, context) {
      return value.amount?.gte("0") && value.amount?.lte(context.parent.pagable.total)
    })
  }))
    .test("payRequired", "Debe realizar al menos un pago", function (value, context) {
      return !value?.length || value.some(c => c.abono.amount?.gt("0"))
    }),
  // .test("excedentes", , function(){

  // }),
  mediosPago: yup.array().min(1).test("pago insuficiente", "Pagos insuficientes", function (value, context) {
    const { totalAPagar, totalPagado } = context.options.context as any
    return totalPagado.amount.gte(totalAPagar.round(2).amount)
  }),
  // diferencia: yup.mixed().test("non-negative", "El resto no puede ser negativo. Disminuya algun pago o verifique que el deposito ingresado es correcto.", function(value, context){
  //   return !value.amount || value.amount.gte("0")
  // })
})

export function CajaForm() {

  const [searchParams] = useSearchParams()

  // const monedaPago = currencies["BOB"]

  const medioPagoFormModalRef = useRef<MedioPagoFormModalRef>(null)

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    trigger,
    watch
  } = useForm<FormValues>({
    context: {
      get excedente() {
        return excedente
      },
      get totalAPagar() {
        return totalAPagar
      },
      get totalPagado() {
        return totalPagos
      }
    },
    resolver: yupResolver(schema),
    defaultValues: {
      fecha: moment().format("YYYY-MM-DD"),
      codigoPago: searchParams.get("codigo_pago") ?? "",
      cliente: null,
      registrarExcedente: true,
      moneda: "BOB",
      totalDeuda: new Money("0", "BOB"),
      totalAPagar: new Money("0", "BOB"),
      totalPagado: new Money("0", "BOB"),
      detalles: [],
      mediosPago: []
    }
  })

  const detallesFieldArray = useFieldArray({
    control,
    name: "detalles",
  })

  const mediosPago = useFieldArray({
    control,
    name: "mediosPago",
  })

  const codigoPago = watch("codigoPago")
  const fecha = watch("fecha") as string
  const [getCuentas, getCuentasState] = useLazyGetCuotasPendientesQuery()

  useEffect(() => {
    const codigoPago = searchParams.get("codigo_pago")
    if (codigoPago) {
      getCuentas({ fecha, codigoPago })
    }
  }, [searchParams])

  const moneda = useDeferredValue(watch("moneda"))

  const totalPagos = watch("totalPagado")
  // useEffect(() => {
  //   setValue("totalPagado", mediosPago.fields.reduce((total, { importe }, index) => {
  //     return total.add(importe)
  //   }, new Money("0", moneda)))
  //   //   setValue("totalPagado", totalPagos)
  // }, [mediosPago.fields.map(m => String(m.importe)).join("|"), moneda])

  const totalDeuda = watch("totalDeuda")
  const totalAPagar = watch("totalAPagar")

  const excedente = useMemo(() => {
    if (totalPagos.currency != totalAPagar.currency) return new Money("0", totalPagos.currency)
    return totalPagos.sub(totalAPagar)
  }, [String(totalAPagar), String(totalPagos)])

  const [guardar, guardarState] = usePagarCuotasMutation()

  useServerValidationErrors(guardarState, setError, useCallback((key: string) => {
    if (key == "importe") return "deposito"
    let matches = key.match(/detalles\.(\d+)\.importe/)
    if (matches?.length == 2) return `cuentas.${matches[1]}.pago`
    return key
  }, []))

  const cuentasResponse = getCuentasState.currentData
  useEffect(() => {
    if (getCuentasState.isFetching) {
      setValue("cliente", null)
      detallesFieldArray.replace([])
      setValue("totalDeuda", new Money("0", moneda))
      setValue("totalAPagar", new Money("0", moneda))
    }
    else if (cuentasResponse) {
      setValue("cliente", cuentasResponse.cliente)
      detallesFieldArray.replace(cuentasResponse.pagables.map((pagable) => ({
        pagable,
        cargo: new Money(pagable.total, pagable.moneda),
        cargoMonedaPago: new Money(null, moneda),
        abono: new Money(pagable.total, pagable.moneda),
        abonoMonedaPago: new Money(null, moneda)
      })))
    }
  }, [cuentasResponse, getCuentasState.isFetching])

  function exchangeCargos() {
    detallesFieldArray.replace(detallesFieldArray.fields.map((field) => ({
      ...field,
      cargoMonedaPago: new Money(null, moneda),
      abonoMonedaPago: new Money(null, moneda)
    })))
    return Promise.all(getValues("detalles").map(async ({ pagable, abono, cargo }) => {
      const [abonoMonedaPago, cargoMonedaPago] = await Promise.all([
        abono.exchangeTo(moneda),
        cargo.exchangeTo(moneda)
      ])
      return {
        pagable,
        abono,
        abonoMonedaPago,
        cargo,
        cargoMonedaPago
      }
    }))
  }

  
  const getCuentasFulfilledTimestamp = useDeferredValue(getCuentasState.fulfilledTimeStamp)
  useEffect(() => {
    let cancel = false

    exchangeCargos().then((detalles)=>{
      if(!cancel){
        detallesFieldArray.replace(detalles)
        const [totalCargos, totalAbonos] = detalles.reduce(([totalCargos, totalAbonos], detalle) => {
          return [
            totalCargos.add(detalle.cargoMonedaPago),
            totalAbonos.add(detalle.abonoMonedaPago)
          ]
        }, [
          new Money("0", moneda),
          new Money("0", moneda),
        ])
        setValue("totalDeuda", totalCargos)
        setValue("totalAPagar", totalAbonos)
      }
    })
    return () => {
      cancel = true
    }
  }, [getCuentasFulfilledTimestamp, moneda])

  useEffect(() => {
    mediosPago.replace(mediosPago.fields.map((field) => ({
      ...field,
      importe: new Money(field.importe.amount, moneda)
    })))
    setValue("totalPagado", new Money(totalPagos.amount, moneda))
  }, [moneda])

  const renderAlert = () => {
    const alerts = []
    if (getCuentasState.isError) {
      const error = getCuentasState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      message.push(":", <br key="line-break" />, error.message ?? error.data.message)
      alerts.push(<Alert key="error-pagables" variant="danger">
        {message}
      </Alert>)
    }
    if (guardarState.isError) {
      const error = guardarState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <>
        <br />
        {error.message ?? error.data.message}
      </>)
      else message.push(".")
      alerts.push(<Alert key="error" variant="danger">
        {message}
      </Alert>)
    }
    else if (guardarState.isSuccess) {
      alerts.push(<Alert key="success" variant="success">La solicitud se completó exitosamente</Alert>)
    }
    return alerts
  }

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../caja" }}>Caja</Breadcrumb.Item>
      <Breadcrumb.Item active>Cobros</Breadcrumb.Item>
    </Breadcrumb>
    <Form aria-label="Formulario de registro de pagos" onSubmit={handleSubmit((values) => {
      const formData: FormData = new FormData()
      formData.append("fecha", moment(values.fecha).format("YYYY-MM-DD"))
      formData.append("moneda", values.moneda)
      formData.append("cliente_id", String(values.cliente!.id))
      formData.append("registrar_excedentes", values.registrarExcedente ? "1" : "0")
      for (let i in values.detalles) {
        const { pagable, abono: importe } = values.detalles[i]
        if (importe.amount?.gt("0")) {
          formData.append(`detalles[${i}][id]`, String(pagable.id))
          formData.append(`detalles[${i}][type]`, String(pagable.type))
          formData.append(`detalles[${i}][importe]`, importe.amount!.toFixed(2))
        }
      }
      for (let i in values.mediosPago) {
        const {
          formaPago,
          importe,
          numeroComprobante,
          comprobante
        } = values.mediosPago[i]
        formData.append(`medios_pago[${i}][forma_pago]`, String(formaPago.id))
        formData.append(`medios_pago[${i}][importe]`, String(importe.amount!.toFixed(2)))
        if (formaPago.id == 2) {
          formData.append(`medios_pago[${i}][numero_comprobante]`, String(numeroComprobante))
          formData.append(`medios_pago[${i}][comprobante]`, comprobante, comprobante.name)
        }
      }
      guardar(formData).unwrap().then(() => {
        reset()
        window.scrollTo(0, 0)
      }, () => { })
    }, console.error)}>
      {renderAlert()}
      <Row className="gx-2">
        <Col sm={6}>
          <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
            <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Moneda de pago</Form.Label>
            <Row className="gx-2" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
              <Col>
                <Form.Check
                  id="dolar"
                  type="radio"
                  label={"Dolar"}
                  value={"USD"}
                  {...register("moneda")}
                />
              </Col>
              <Col>
                <Form.Check
                  id="boliviano"
                  type="radio"
                  label={"Boliviano"}
                  value={"BOB"}
                  {...register("moneda")}
                />
              </Col>
            </Row>
          </Form.Group>
        </Col>
      </Row>
      <Row className="gx-2">
        <Col xs md={6} lg={4}>
          <Row className="gx-2">
            <Form.Group as={Col} className="mb-3" xs={6} controlId="fecha">
              <Form.Label>Fecha</Form.Label>
              <Form.Control
                type="date"
                {...register("fecha")}
                isInvalid={!!formState.errors.fecha}
              />
              <Form.Control.Feedback type="invalid">{formState.errors.fecha?.message}</Form.Control.Feedback>
            </Form.Group>
            <Form.Group as={Col} className="mb-3" xs={6} controlId="codigoPago">
              <Form.Label>Código de pago</Form.Label>
              <Form.Control
                {...register("codigoPago")}
                isInvalid={!!formState.errors.codigoPago}
              />
              <Form.Control.Feedback type="invalid">{formState.errors.codigoPago?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Col>
        <Col xs="auto">
          <Button aria-label="Buscar" className="btn-inline"
            disabled={getCuentasState.isFetching}
            onClick={()=>{
              getCuentas({codigoPago, fecha})
            }}
          >
            {getCuentasState.isFetching ? <Spinner size="sm" animation="border" /> : <FaSearch />}
          </Button>
        </Col>
      </Row>
      <Row className="gx-2">
        <Controller
          control={control}
          name="cliente"
          render={({ field, fieldState }) => {
            return <Form.Group as={Col} className="mb-3" xs={12}>
              <Form.Label>Cliente</Form.Label>
              <InputGroup hasValidation>
                <Form.Control readOnly isInvalid={!!fieldState.error} name="cliente" value={field.value?.nombreCompleto ?? ""} />
                {field.value && <Link
                  to={`../clientes/${field.value?.id}`}
                  target="_blank"
                  className="btn btn-link"
                  style={{borderColor: "var(--bs-gray-400)"}}
                >
                  {/* <BsBoxArrowUpRight className="mt-n1" /> */}
                  <MdOutlineOpenInNew className="mt-n1 fs-4" />
                </Link>}
                <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          }}
        />
      </Row>
      <div className="mb-3">
        <Table responsive className="m-0" style={{ minWidth: 700 }}>
          <caption>Cuentas</caption>
          <thead>
            <tr>
              <th style={{ width: 0 }}>#</th>
              <th className="col-7">Referencia</th>
              <th className="col-1">Vencimiento</th>
              <th className="col-1">Importe <span className="text-nowrap text-muted small fw-semibold">(en la moneda original)</span></th>
              <th className="col-1">Importe <span className="text-nowrap text-muted small fw-semibold">(en la moneda de pago)</span></th>
              <th className="col-1">Pago<MoneySync
                role="button"
                aria-label="Ajustar pagos"
                className="btn btn-link ms-1 border-0 p-0"
                style={{ position: "relative", top: "-0.125rem" }}
                onClick={()=>{
                  async function distribuirPagos(){
                    let remaining = totalPagos.clone()
                    const detalles = []
                    for(let index in detallesFieldArray.fields) {
                      const field = detallesFieldArray.fields[index]
                      const cargo = field.cargo
                      const cargoMonedaPago = field.cargoMonedaPago

                      if(remaining.amount!.lessThan(cargoMonedaPago.amount!)){
                        if(remaining.amount!.lte("0")){
                          detalles.push({
                            ...field,
                            abono: new Money("0", cargo.currency),
                            abonoMonedaPago: new Money("0", moneda)
                          })
                        }
                        else{
                          const abono = await remaining.exchangeTo(cargo.currency, {
                            exchangeMode: "buy",
                            preserveOriginal: false,
                            decimalPlaces: 2
                          })
                          const abonoMonedaPago = await abono.exchangeTo(remaining.currency)

                          detalles.push({
                            ...field,
                            abono,
                            abonoMonedaPago
                          })
                          remaining = remaining.sub(abonoMonedaPago)
                        }
                      }
                      else{
                        detalles.push({
                          ...field,
                          abono: cargo,
                          abonoMonedaPago: cargoMonedaPago
                        })
                        remaining = remaining.sub(cargoMonedaPago)
                      }
                    }
                    detallesFieldArray.replace(detalles)
                    return detalles
                  }
                  distribuirPagos().then((detalles)=>{
                    const [totalCargos, totalAbonos] = detalles.reduce(([totalCargos, totalAbonos], detalle) => {
                      return [
                        totalCargos.add(detalle.cargoMonedaPago),
                        totalAbonos.add(detalle.abonoMonedaPago)
                      ]
                    }, [
                      new Money("0", moneda),
                      new Money("0", moneda),
                    ])
                    setValue("totalDeuda", totalCargos)
                    setValue("totalAPagar", totalAbonos)
                  })
                }}
              />
              </th>
              <th className="col-1">Saldo</th>
              {/* <th style={{width: 0}}></th> */}
            </tr>
          </thead>
          <tbody>
            {detallesFieldArray.fields.length === 0 ?
              getCuentasState.isSuccess ? <tr>
                <td className="bg-ligth text-center" colSpan={100}>
                  No existen cuentas por pagar con el codigo ingresado
                </td>
              </tr> : null :
              detallesFieldArray.fields.map((cuenta, index) => {
                return <Controller
                  key={String(cuenta.id)}
                  control={control}
                  name={`detalles.${index}`}
                  render={({field, fieldState})=>{
                    return <PagableRow
                      name={field.name}
                      value={field.value}
                      index={index}
                      moneda={moneda}
                      onChange={(value)=>{
                        field.onChange(value)
                        const abonoMPDelta = value.abonoMonedaPago.sub(field.value.abonoMonedaPago)
                        setValue("totalAPagar", totalAPagar.add(abonoMPDelta))
                      }}
                    />
                  }}
                />
              })
            }
          </tbody>
          <tfoot>
            <tr>
              <th className="text-end" colSpan={4} scope="row">Σ Total</th>
              <td className="text-nowrap text-end" title={String(totalDeuda)}>{totalDeuda.toFixed(2)}</td>
              <td className="text-nowrap text-end" title={String(totalAPagar)} style={{ paddingRight: "calc(1.25rem + 2em)" }}>{totalAPagar.toFixed(2)}</td>
              <td>

              </td>
            </tr>
          </tfoot>
        </Table>
        <Form.Text className="text-danger">{formState.errors.detalles?.message}</Form.Text>
      </div>
      <div className="mb-3">
        {/* <div className="d-flex align-items-center">
          <Button aria-label="Agregar medio de pago" className="ms-auto" onClick={()=>{
            medioPagoFormModalRef.current?.open({
              formaPago: {} as any,
              importe: new Money(null, monedaPago),
              numeroComprobante: "" as any,
              comprobante: "" as any
            })
          }}><FaPlus/></Button>
        </div> */}
        <Table responsive className="mb-0">
          <caption>Medios de pago</caption>
          <thead>
            <tr>
              <th style={{ width: 0 }}>#</th>
              <th>Forma de pago</th>
              <th style={{ width: 0, minWidth: 150 }}>Importe</th>
              {/* <th>Número</th>
              <th>Comprobante</th> */}
              <th className="text-nowrap" style={{ width: 0 }}>
                {/* <FaPlus role="button" aria-label="Agregar medio de pago"
                  className="text-primary"
                  style={{position: "relative", top: "-0.125rem"}}
                  onClick={()=>{
                    medioPagoFormModalRef.current?.open({
                      formaPago: {} as any,
                      importe: new Money(null, currencies[watch("moneda")]),
                      numeroComprobante: "" as any,
                      comprobante: "" as any
                    })
                  }}
                />
                {" "}
                <FaTrash role="button" aria-label="Quitar todo"
                  className="text-danger"
                  style={{position: "relative", top: "-0.125rem"}}
                  onClick={()=>{
                    mediosPago.replace([])
                  }}
                /> */}
              </th>
            </tr>
          </thead>
          <tbody>
            {mediosPago.fields.map((field, index) => {
              return <tr key={field.id}>
                <th scope="row">{index + 1}</th>
                <td>{field.formaPago.label}</td>
                <td className="text-nowrap text-end">{field.importe.toFixed(2)}</td>
                {/* <td>{field.numeroComprobante}</td>
                <td>{field.comprobante && field.comprobante.name}</td> */}
                <td className="text-nowrap">
                  <FaEdit role="button" aria-label="Editar"
                    className="text-primary"
                    style={{ position: "relative", top: "-0.125rem" }}
                    onClick={() => {
                      medioPagoFormModalRef.current?.open({
                        formaPago: field.formaPago,
                        importe: field.importe,
                        numeroComprobante: field.numeroComprobante,
                        comprobante: field.comprobante
                      }, index, {
                        formaPago: formState.errors.mediosPago?.[index]?.formaPago as any,
                        importe: formState.errors.mediosPago?.[index]?.importe,
                        numeroComprobante: formState.errors.mediosPago?.[index]?.numeroComprobante,
                        comprobante: formState.errors.mediosPago?.[index]?.comprobante,
                      })
                    }}
                  />
                  {" "}
                  <FaTimes role="button" aria-label="Quitar"
                    className="text-danger"
                    style={{ position: "relative", top: "-0.125rem" }}
                    onClick={() => {
                      const importe = mediosPago.fields[index].importe
                      mediosPago.remove(index)
                      setValue("totalPagado", totalPagos.sub(importe))
                    }}
                  />
                </td>
              </tr>
            })}
          </tbody>
          <tfoot>
            <tr>
              <th className="text-end" colSpan={2} scope="row">Σ Total</th>
              <td className="text-nowrap text-end">{totalPagos.toFixed(2)}</td>
              <td className="text-nowrap">
                <FaPlus role="button" aria-label="Agregar medio de pago"
                  className="text-primary"
                  style={{ position: "relative", top: "-0.125rem" }}
                  onClick={() => {
                    medioPagoFormModalRef.current?.open({
                      formaPago: {} as any,
                      importe: new Money(null, watch("moneda")),
                      numeroComprobante: "" as any,
                      comprobante: "" as any
                    })
                  }}
                />
                {" "}
                <FaTrash role="button" aria-label="Quitar todo"
                  className="text-danger"
                  style={{ position: "relative", top: "-0.125rem" }}
                  onClick={() => {
                    mediosPago.replace([])
                    setValue("totalPagado", new Money("0", moneda))
                  }}
                />
              </td>
            </tr>
          </tfoot>
        </Table>
        <Form.Text className="text-danger">{(formState.errors.mediosPago as any)?.message}</Form.Text>
      </div>
      {
        !excedente.amount!.lessThan("0.005") ?
          <Form.Check
            className="mb-3"
            label={<span>Agregar el excedente de <span title={String(excedente)}>{excedente.toFixed(2)}</span> a la cuenta del cliente</span>}
            {...register("registrarExcedente")}
          /> :
          null
      }
      <Row>
        <Col sm="auto">
          <LoadingButton type="submit" className="w-100" isLoading={guardarState.isLoading} icon={<FaSave style={{ position: "relative", top: "-0.125em" }} />}>
            Guardar
          </LoadingButton>
        </Col>
      </Row>
    </Form>
    <MedioPagoFormModal ref={medioPagoFormModalRef} onSubmit={(newValues, index) => {
      if (index === undefined) {
        mediosPago.append(newValues)
        setValue("totalPagado", totalPagos.add(newValues.importe))
      }
      else {
        const importe = mediosPago.fields[index].importe
        mediosPago.update(index, newValues)
        setValue("totalPagado", totalPagos.add(newValues.importe.sub(importe)))
      }
    }} />
  </div>
}