import Decimal from "decimal.js"
import { useState } from "react"
import { Button, Col, Form, Row, Table } from "react-bootstrap"
import { FaEdit, FaPlus, FaSave, FaTimes, FaTrash } from "react-icons/fa"
import Skeleton from "react-loading-skeleton"
import { IndexTemplate, LoadingButton, TableView } from "../../../commons/components"
import { useProyectoContext } from "../../proyectos/proyectosApi"
import { Categoria, useGetCategoriasQuery } from "../categoriaApi"
import { categoriaPolicy } from "../policies"
import { CategoriaForm } from "./CategoriaForm"

export const CategoriaIndex = ()=>{

  const proyecto = useProyectoContext()!
 
  const buscar = useGetCategoriasQuery({proyectoId: proyecto.id})
  const isRefetching = buscar.isFetching && !buscar.isLoading
  const [selected, setSelected] = useState<Categoria|null>(null)

  return <>
    <CategoriaForm categoria={selected} onReset={()=>{setSelected(null)}} />
    <IndexTemplate
      onRefetch={buscar.refetch}
      policy={categoriaPolicy}
      renderView={()=><TableView
        expectedLength={5}
        data={isRefetching ? undefined : buscar.currentData?.records}
        isLoading={buscar.isFetching}
        hasError={buscar.isError}
        renderHeaders={()=><tr>
          <th className="w-0">#</th>
          <th className="col-2">Codigo</th>
          <th className="col-2">Precio por m²</th>
          <th className="col-8">Descripción</th>
          <th className="w-0"></th>
        </tr>}
        renderRow={(categoria, index)=>{
          return <tr key={categoria?.codigo || index}>
            <th scope="row">{index + 1}</th>
            <td>{categoria ? categoria.codigo : <Skeleton />}</td>
            <td className="text-end text-nowrap">{categoria ? `${new Decimal(categoria.precioM2.amount).toFixed(2)} ${categoria.precioM2.currency}` : <Skeleton />}</td>
            <td>{categoria ? categoria.descripcion ?? <em>Sin descripción</em> : <Skeleton />}</td>
            <td className="text-nowrap">{categoria ? <>
              <FaEdit role="button" className="text-primary" onClick={()=>{
                setSelected(categoria)
              }} />
              {/* <FaTimes role="button" className="ms-1 text-danger"  /> */}
            </> : null}</td>
          </tr>
        }}
      />}
    />
  </>
}