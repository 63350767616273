import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Skeleton from "react-loading-skeleton"
import { IndexTemplate, TableView } from "../../../commons/components";
import { useRefetch } from "../../../commons/utils";
import { listaMoraPolicy } from "../policies";
import { RowOptions } from "./RowOptions";
import { useGetListaMoraQuery, Filter } from "../services";
import Decimal from "decimal.js";
import moment, { Moment } from "moment";

export function ListaMoraIndex(){
  const [ page, setPage ] = useState({
    current: 1,
    size: 10
  })

  const [filter, setFilter] = useState<Filter>({})

  const buscar = useGetListaMoraQuery({page, filter})

  const [isRefetching, refetch] = useRefetch(buscar)


  return <div>
    <Breadcrumb>
      <Breadcrumb.Item active>Lista de mora</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      total={buscar.data?.meta.totalRecords || -1}
      page={page}
      pageSizes={[10,20,30,50,100]}
      policy={listaMoraPolicy}
      onPageChange={setPage}
      onRefetch={refetch}
      renderView={()=>{
        const response = buscar.currentData
        return <TableView
          expectedLength={page.size}
          data={isRefetching ? undefined : response?.records}
          errorMessage={(buscar.error as any)?.message ?? (buscar.error as any)?.data.message }
          isLoading={buscar.isFetching}
          hasError={buscar.isError}
          renderHeaders={()=><tr>
            <th style={{width: 0}}>#</th>
            <th>Cliente</th>
            <th>Resumen</th>
            <th>Adeudos</th>
            <th>Multas</th>
            <th>Total</th>
            <th style={{width: 0}}></th>
          </tr>}
          renderRow={(item, index)=>{
            console.log(index, item)
            return <tr key={item?.cliente.id || index}>
              <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
              <td>{item ? <>
                <div>{item.cliente.nombreCompleto}</div>
                <div className="small text-muted" style={{textTransform: "none"}}>{item.cliente.documentoIdentidad.tipoText}: {item.cliente.documentoIdentidad.numero}, teléfono: {item.cliente.telefono}</div>
              </> : <Skeleton containerTestId="skeleton-loader" />}</td>
              <td>{item ? <>
                <div>{item.creditos.length} {item.creditos.length > 1 ? "créditos" : "crédito"} en mora</div>
                <div className="small text-muted" style={{textTransform: "none"}}>{item.creditos.reduce((oldest, current)=>{
                  const vencimiento = moment(current.cuotasVencidas[0].vencimiento);
                  if(vencimiento.isBefore(oldest)) return vencimiento
                  return oldest
                }, moment()).add(1, "day").startOf("day").fromNow()}</div>
              </> : <Skeleton containerTestId="skeleton-loader" />}</td>
              <td>{item ? Object.keys(item.resumen).map((key) => <div key={key}>{new Decimal(item.resumen[key].saldo).toFixed(2)} {key}</div>) : <Skeleton containerTestId="skeleton-loader" />}</td>
              <td>{item ? Object.keys(item.resumen).map((key) => <div key={key}>{new Decimal(item.resumen[key].multa).toFixed(2)} {key}</div>) : <Skeleton containerTestId="skeleton-loader" />}</td>
              <td>{item ? Object.keys(item.resumen).map((key) => <div key={key}>{new Decimal(item.resumen[key].total).toFixed(2)} {key}</div>) : <Skeleton containerTestId="skeleton-loader" />}</td>
              <td>{item ? <RowOptions item={item} /> : null}</td>
            </tr>
          }}
        />
      }}
    />
  </div>
}