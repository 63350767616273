import { useEffect, useMemo, useState, useRef } from "react"
import { Button, FormControl, InputGroup } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { FaSearch } from "react-icons/fa"
import { LatLngExpression, Marker as LeafletMarker, Map } from "leaflet"
import { MapContainer, Marker, Popup, TileLayer, useMap, useMapEvents, ZoomControl } from "react-leaflet"
import { latLngExpressionToString } from "../utils"
import "leaflet/dist/leaflet.css";
import "../utils/leafletDefaults"

type Props = {
  initialized?: boolean
  center: LatLngExpression
  zoom: number
  isInvalid: boolean
  id?: string
  name: string
  value: LatLngExpression|null
  onChange(value?: LatLngExpression|null): void
}

const POSITION_CLASSES = {
  bottomleft: 'leaflet-bottom leaflet-left',
  bottomright: 'leaflet-bottom leaflet-right',
  topleft: 'leaflet-top leaflet-left',
  topright: 'leaflet-top leaflet-right',
}

const InputLocationControl = ({
  isInvalid,
  value,
  id,
  name,
  onChange
}: {
  isInvalid: boolean
  value: LatLngExpression | null
  id?: string
  name: string
  onChange: (value: LatLngExpression | null) => void 
}) => {

  const [search, setSearch] = useState("")

  const map = useMap()

  
  const [lat, lng] = value instanceof Array ? value : [value?.lat, value?.lng]

  useEffect(()=>{
    setSearch(latLngExpressionToString(value))
  }, [lat, lng])

  return <div className="leaflet-control bg-white"
      ref={(instance: HTMLDivElement)=>{
        if(instance){
          instance.onpointermove = (e:any)=>{
            e.stopPropagation()
          }
          instance.ondblclick = (e:any)=>{
            e.stopPropagation()
          }
        }
      }}
    >  
    <InputGroup>      
      <FormControl
        aria-label="Coordenadas"
        // id={id}
        isInvalid={isInvalid}
        name={name}
        value={search}
        onChange={(e)=>{
          setSearch(e.target.value)
        }}
      />
      <Button aria-label="Buscar coordenada" variant={isInvalid ? "danger" : "secondary"}
        onClick={()=>{
          const coords = search.split(",")
          if(coords.length == 2){
            const [rawLat, rawLng] = coords
            const lat = parseFloat(rawLat)
            const lng = parseFloat(rawLng)
            if(Math.abs(lat) <= 90 && Math.abs(lng) <= 180){
              map.setView([lat, lng], 13)
              onChange([lat, lng])
              return
            }
          }
          onChange(null)
        }}
      ><FaSearch/></Button>
    </InputGroup>
  </div>
}

const CustomMarker = (props: { position: LatLngExpression|null, onChange(position: LatLngExpression|null): void})=>{
  const markerRef =  useRef<LeafletMarker<any>>(null)
  const eventHandlers = useMemo(() => ({
    onclick() {
      const marker = markerRef.current
      if (marker != null) {
        marker.remove()
        props.onChange(null)
      }
    },
    dragend() {
      const marker = markerRef.current
      if (marker != null) {
        props.onChange(marker.getLatLng())
      }
    },
  }), [])

  if(!props.position) return null
  const [latitude, longitude] = Array.isArray(props.position) ? props.position : [props.position.lat, props.position.lng]
  return <Marker 
    draggable
    ref={markerRef}
    position={props.position}
    eventHandlers={eventHandlers}
  >
    {/* <Popup>
      A pretty CSS3 popup. <br /> Easily customizable.
      <a href={`maps:${latitude},${longitude}`}>Open with...</a>
    </Popup> */}
  </Marker>
}

function UpdateCenter({center}: {center: LatLngExpression}) {
  const map = useMap()

  const [lat, lng] = center instanceof Array ? center : [center.lat, center.lng]

  useEffect(()=>{
    map.setView(center)
  }, [lat, lng])

  return null
}

export const LocationControl = ({
  initialized,
  center,
  zoom,
  ...props
}: Props) => {

  const mapRef = useRef<Map|null>(null)

  return initialized !== false ? <div className={"border rounded " + (props.isInvalid ? " is-invalid border-danger overflow-hidden" : "")}>
    <MapContainer
      ref={(ref)=>{
        if(ref === mapRef.current) return
        mapRef.current?.removeEventListener("click");
        mapRef.current = ref
        if(mapRef.current){
          let timeoutId: ReturnType<typeof setTimeout>, counter: number=0
          mapRef.current.addEventListener("click", (e)=>{
            clearTimeout(timeoutId)
            if(e.originalEvent.target && (e.originalEvent.target as HTMLElement).closest(".leaflet-control")) return
            counter++
            timeoutId = setTimeout(()=>{
              if(counter == 1){
                props.onChange(e.latlng)
                mapRef.current && mapRef.current.flyTo(e.latlng, mapRef.current.getZoom())
              }
              counter = 0
            }, 150)
          })
        }
      }}
      center={center}
      zoom={zoom || 10}
      zoomControl={false}
      style={{height: 240, cursor: "crosshair"}}
    >
      <UpdateCenter center={center} />
      <TileLayer
        attribution='&copy; <a href="http://osm.org/copyright">OpenStreetMap</a> contributors'
        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
      />
      <div className="leaflet-control-container">
        <div className={POSITION_CLASSES["topleft"]} style={{zIndex: 999}}>
          <InputLocationControl {...props} />
        </div>
      </div>
      
      <CustomMarker position={props.value} onChange={(position)=>{
        props.onChange(position)
      }} />
      <ZoomControl position="bottomright" />
    </MapContainer>
  </div> : <Skeleton height={240} />
}