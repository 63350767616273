import { useCallback, useRef } from "react"

export const useRefetch = (query: {
  refetch(): void
  isFetching: boolean
})=>{
  const refetchingRef = useRef(false)

  if(!query.isFetching) refetchingRef.current = false

  const refetch = useCallback(()=>{
    refetchingRef.current = true
    query.refetch()
  }, [])

  return [ refetchingRef.current, refetch ] as [boolean, ()=>void]
}