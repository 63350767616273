import { createApi } from "@reduxjs/toolkit/query/react"
import { apiBaseQuery, appApi, PaginatedResponse, PaginationParams } from "../../../commons/services"
import { Credito } from "../../creditos/creditosApi"
import { Venta } from "../../ventas/types"

export type Cliente = {
  id: number
  tipo: 1 | 2
  tipoDocumento: 1 | 2
  numeroDocumento: string 
  apellidoPaterno: string | null
  apellidoMaterno: string | null
  nombre: string
  telefono: string
}

export type ClienteView = Cliente & {
  nombreCompleto: string
  documentoIdentidad: {
    numero: string
    tipo: number
    tipoText: string
  }
  codigoPago: string
  // riesgoMora: boolean
}

export type Filter = {
  riesgoMora?: boolean
}

export const clientesApi = appApi.enhanceEndpoints({
  addTagTypes: ["Clientes"],
}).injectEndpoints({
  endpoints: (builder) => ({
    getClientes: builder.query<PaginatedResponse<ClienteView>, PaginationParams<Filter>>({
      query: (params) => ({
        url: "clientes",
        params
      }),
      providesTags: (results) => [
        ...(results?.records.map(c => ({ type: "Clientes" as const, id: c.id })) || []),
        { type: "Clientes", id: "*" }
      ]
    }),
    getCliente: builder.query<ClienteView & {
      creditos: {
        id: number
        fecha: string
        proyecto: { nombre: string }
        lote: {
          numero: string
          manzana: { numero: string }
        }
        credito: Credito
      }[]
    }, number>({
      query: (id) => `clientes/${id}`,
      providesTags: (result) => result ? [
        { type: "Clientes", id: result.id }
      ] : []
    }),

    registrarCliente: builder.mutation<ClienteView, Omit<Cliente, "id">>({
      query: (body) => ({
        method: "POST",
        url: "clientes",
        body
      }),
      invalidatesTags: [{type: "Clientes", id: "*"}]
    })
  })
})

export const {
  useGetClientesQuery,
  useLazyGetClientesQuery,
  useGetClienteQuery,
  useRegistrarClienteMutation
} = clientesApi