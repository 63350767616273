import * as yup from 'yup';
import { yupMessages } from './messages'

yup.setLocale(yupMessages)

yup.addMethod(yup.mixed, 'undefined', function () {
  return this.transform(function (value, originalValue) {
    if (this.isType(value)) return value;
    return originalValue === "" ? undefined : value;
  });
});

// yup.addMethod(yup.date, 'undefined', function () {
//   return this.transform(function (value, originalValue) {
//     if (this.isType(value)) return value;
//     return originalValue === "" ? undefined : value;
//   });
// });