import { forwardRef, useState, useTransition } from "react"
import { Dropdown, Form, InputGroup, FormControlProps } from "react-bootstrap"
import { NumericFormat } from 'react-number-format';
import { BsPrefixProps, ReplaceProps } from "react-bootstrap/esm/helpers"
import { Money } from "./Money"
import { valueContainerCSS } from "react-select/dist/declarations/src/components/containers";

type Props = Omit<FormControlProps, "defaultValue" | "value" | "defaultValue" | "onChange"> & {
  // defaultValue?: Money
  allowEdit?: boolean
  value?: Money
  onChange?(value: Money): void
}


export const MoneyInput = forwardRef<HTMLInputElement, ReplaceProps<"input", BsPrefixProps<"input"> & Props>>(function({
    as,
    defaultValue,
    value,
    onChange,
    allowEdit,
    readOnly: defaultReadOnly,
    ...props
  },
  ref
){
  // const [pending, startTransition] = useTransition()
  const [readOnly, setReadOnly] = useState(defaultReadOnly)
  // const [innerValue, setInnerValue] = useState<string>()

  // const inputValue = innerValue ?? (value?.amount?.toFixed(2) || "")

  // return <InputGroup hasValidation>
  //   <Form.Control
  //     ref={ref}
  //     {...props}
  //     className={`text-end ${props.className}`}
  //     as="input"
  //     value={inputValue}
  //     readOnly={readOnly}
  //     onDoubleClick={() => {
  //       if(allowEdit) setReadOnly(false)
  //     }}
  //     onKeyDown={(e)=> {
  //       if(e.key === "Enter" && allowEdit) setReadOnly(false)
  //     }}
  //     onBlur={(e)=>{
  //       if(innerValue !== undefined && value){
  //         onChange?.((new Money(innerValue, value.currency)).round(2))
  //         setInnerValue(undefined)
  //       }
  //       props.onBlur?.(e)
  //     }}
  //     onChange={(e) => {
  //       // const regexp = /^\d+(\.\d{0,2})?$/
  //       if(!readOnly && !isNaN(+e.target.value)) {
  //         setInnerValue(e.target.value)
  //         // onChange?.(new Money(e.target.value, value.currency))
  //       }
  //     }}
  //     inputMode="numeric"
  //   />
  //   {props.name ? <input type="hidden" name={props.name+"Moneda"} value={value?.currency.code} /> : null}
  //   {onChangeCurrency && currencies ? <Dropdown>
  //     <Dropdown.Toggle tabIndex={-1} style={{fontSize: "0.75rem", padding: "0.5rem"}}>{value?.currency.code}</Dropdown.Toggle>
  //     <Dropdown.Menu align="end">
  //       {currencies.map((currency) => {
  //         return <Dropdown.Item key={currency.code} onClick={() => {
  //           onChangeCurrency(currency)
  //         }}>{currency.name} ({currency.code})</Dropdown.Item>
  //       })}
  //     </Dropdown.Menu>
  //   </Dropdown> : <InputGroup.Text>{value?.currency.code}</InputGroup.Text>}
  //   <Form.Control.Feedback data-testid={props.name ? `${props.name}-error` : ""} type={feedbackType}>{feedback}</Form.Control.Feedback>
  // </InputGroup>

  return <Form.Control
    as={NumericFormat}
    value={value?.amount?.toString() ?? ""}
    valueIsNumericString
    className={[/*readOnly ? "" : */"text-end", readOnly && props.isInvalid && "text-danger", props.className].filter(cn=>!!cn).join(" ")}
    suffix={" " + (value?.currency || " ???")}
    getInputRef={ref}
    decimalScale={2}
    fixedDecimalScale
    readOnly={readOnly}
    plaintext={readOnly}
    placeholder={`-.-- ${value?.currency || "???"}`}
    onDoubleClick={() => {
      if(allowEdit) setReadOnly(false)
    }}
    onKeyDown={(e)=> {
      if(e.key === "Enter" && allowEdit) {
        e.preventDefault()
        setReadOnly(false)
      }
    }}
    onValueChange={(values: any, info: any)=>{
      // setInnerValue(value)
      // startTransition()
      // console.log(values, info)
      if(info.event?.type == "change") onChange?.((new Money(values.value, value!.currency)))
    }}
    {...props}
  />
})