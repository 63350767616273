import Decimal from "decimal.js"
import { Card, Col, Row } from "react-bootstrap"
import { useProyectoContext } from "../proyectosApi"
import { PlanoView } from "./PlanoView"

export const ProyectoView = ()=>{
  const proyecto = useProyectoContext()!
  return <>
    <Row className="gx-3">
      <Col className="mb-3" sm={6}>
        <Card>
          <Card.Header>Información General</Card.Header>
          <Card.Body>
            <Row>

              <Col xs={12} className="mb-3">
                <div className="fw-semibold">Nombre</div>
                <div>{proyecto.nombre}</div>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <div className="fw-semibold">Ubicación</div>
                <div>{proyecto.ubicacion.latitud}, {proyecto.ubicacion.longitud}</div>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <div className="fw-semibold">Moneda</div>
                <div>{proyecto.currency.name} ({proyecto.currency.code})</div>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <div className="fw-semibold">Factor de redondeo</div>
                <div>{new Decimal(proyecto.redondeo).toFixed(2)}</div>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <div className="fw-semibold">Precio por reserva</div>
                <div>{proyecto.precioReservas.toFixed(2)}</div>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <div className="fw-semibold">Duración de las reservas</div>
                <div>{proyecto.duracionReservas} días</div>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <div className="fw-semibold">Cuota inicial</div>
                <div>{proyecto.cuotaInicial.toFixed(2)}</div>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <div className="fw-semibold">Tasa de interés</div>
                <div>{new Decimal(proyecto.tasaInteres).mul(100).toFixed(2)} %</div>
              </Col>
              <Col xs={12} lg={6} className="mb-3">
                <div className="fw-semibold">Tasa de interés moratorio</div>
                <div>{new Decimal(proyecto.tasaMora).mul(100).toFixed(2)} %</div>
              </Col>
            </Row>
          </Card.Body>
        </Card>
      </Col>
      <Col className="mb-3" sm={6}>
      <Card>
          <Card.Header>Gráficos</Card.Header>
          <Card.Body>
            
          </Card.Body>
        </Card>        
      </Col>
      <Col xs={12}>
      <Card>
          <Card.Header>Plano</Card.Header>
          <Card.Body>
            <PlanoView />
          </Card.Body>
        </Card>        
      </Col>
    </Row>

  </>
}