import { useEffect, useMemo, useState } from "react"

export type ModalState = {
  isOpen: boolean
}

type Observer = (state: ModalState) => void

export const createHooks = <Extra extends Record<string, any>>(name: string)=>{
  let observer: Observer | undefined;
  const useModalState = (defaultOpen?: boolean) => {
    const [state, setState] = useState({
      isOpen: !!defaultOpen
    })

    useEffect(()=>{
      observer = setState
      return () => {
        observer = undefined
      }
    }, [])
  
    return state as ModalState & Partial<Extra>
  }

  const useModalActions = () => {

    return useMemo(()=>{
      return {
        open: (state?: Partial<Extra>)=>{
          observer?.({
            ...state,
            isOpen: true
          })
        },
        close: () => {
          observer?.({
            isOpen: false
          })
        }
        
      }
    }, [])
  }

  return {
    useModalState,
    useModalActions
  }
}

