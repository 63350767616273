import { useState } from "react"
import { Breadcrumb } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { useParams } from "react-router-dom"
import { IndexTemplate, TableView } from "../../../commons/components"
import { Page } from "../../../commons/services"
import { useGetPlanosQuery } from "../planosApi"
import { planoPolicy } from "../policies"
import { RowOptions } from "./RowOptions"

export const PlanoIndex = ()=>{
  const {proyectoId} = useParams()
  const [page, setPage] = useState<Page>({
    current: 1,
    size: 10
  })
  const [filter, setFilter] = useState<{}>({})  
  const buscar = useGetPlanosQuery({proyectoId: +proyectoId})
  const isRefetching = buscar.isFetching && !buscar.isLoading

  return <>
    <Breadcrumb>
      <Breadcrumb.Item active>Planos</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      total={buscar.data?.meta.totalRecords ?? -1}
      page={page}
      pageSizes={[10,20,30,50,100]}
      registerPath="registrar"
      policy={planoPolicy}
      onPageChange={setPage}
      onRefetch={buscar.refetch}
      renderView={()=>{
        return <TableView
          expectedLength={page.size}
          data={isRefetching ? undefined : buscar.currentData?.records}
          isLoading={buscar.isFetching}
          hasError={buscar.isError}
          renderHeaders={()=><tr>
            <th style={{width: 0}}>#</th>
            <th>Titulo</th>
            <th>Descripcion</th>
            <th style={{width: 0}}>Vigente</th>
            <th style={{width: 0}}>Editable</th>
            <th style={{width: 0}}>Contiene errores</th>
            <th style={{width: 0}}></th>
          </tr>}
          renderRow={(item, index)=><tr key={item?.id || index}>
            <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
            <td>{item ? item.titulo : <Skeleton />}</td>
            <td>{item ? item.descripcion ?? <em>Sin descripción</em> : <Skeleton />}</td>
            <td>{item ? (item.isVigente ? "Sí" : "No") : <Skeleton />}</td>
            <td>{item ? (item.isLocked ? "No" : "Sí") : <Skeleton />}</td>
            <td>{item ? (item.hasErrors ? "Sí" : "No") : <Skeleton />}</td>
            <td>{item ? <RowOptions plano={item} /> : null}</td>
          </tr>}
        />
      }}   
    />
  </>
}