import { appApi, PaginatedResponse, PaginationParams } from "../../commons/services";
import { reservaApi } from "../reservas/reservaApi";
import { RawVenta } from "./types";

export type VentaFilter = {

}

export const ventasApi = appApi.enhanceEndpoints({
  addTagTypes: ['Ventas'],
}).injectEndpoints({
  endpoints: (build) => ({
    getVentas: build.query<PaginatedResponse<RawVenta>, { context: {proyectoId: string}, params: PaginationParams<VentaFilter>}>({
      query: ({context: {proyectoId}, params}) => ({
        url: `proyectos/${proyectoId}/ventas`,
        params
      }),
      providesTags: (results, error) => [
          ...(results ? results.records.map(v => ({ type: 'Ventas' as const, id: v.id})) : []),
          { type: 'Ventas', id: "*" }
        ]
    }),

    registrarVenta: build.mutation<RawVenta, {body: any, context: {proyectoId: string}}>({
      query: ({context: {proyectoId}, body}) => ({
        method: "POST",
        url: `proyectos/${proyectoId}/ventas`,
        body
      }),
      invalidatesTags: [{type: "Ventas" as const, id: "*"}],
      async onQueryStarted({body}, { dispatch, queryFulfilled }) {
        try {
          await queryFulfilled
          const reservaId = body.reserva_id
          if(reservaId){
            dispatch(
              reservaApi.util.invalidateTags([{type: "Reservas", id: reservaId as string}])
            )
          }
        } catch {}
      },
    }),
    anularVenta: build.mutation<NonNullable<RawVenta["anulacion"]>, {
      proyectoId: number
      ventaId: number
      motivo: string
    }>({
      query: ({proyectoId, ventaId, ...body}) => ({
        method: "POST",
        url: `proyectos/${proyectoId}/ventas/${ventaId}/anular`,
        body
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          const {data} = await queryFulfilled
          for(let tag of (getState().appApi.provided.Ventas[args.ventaId] ?? [])){
            const regex = /(?<endpoint>.*)\((?<queryArgs>.*)\)/
            const {groups: {endpoint, queryArgs}} = regex.exec(tag) as any as {groups: {endpoint: string, queryArgs: string}}
            if(endpoint === "getVentas"){
              dispatch(ventasApi.util.updateQueryData(endpoint, JSON.parse(queryArgs), (draft) => {
                Object.assign(draft, {
                  records: draft.records.map(r => {
                    return r.id === args.ventaId ? {
                      ...r,
                      estado: 2,
                      anulacion: data
                    } : r
                  }),
                })
              }))
            }
          }
        }
        catch (e) { }
      }
    }),
  })
})

export const {
  useGetVentasQuery,
  useRegistrarVentaMutation,
  useAnularVentaMutation
} = ventasApi