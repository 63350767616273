import Decimal from "decimal.js";
import moment from "moment";
import { CurrencyExchangeProvider } from "./CurrencyExchangeProvider";

export class Money {
  private static  _exchangeProvider: CurrencyExchangeProvider;

  public static set exchangeProvider(provider: CurrencyExchangeProvider){
    Money._exchangeProvider = provider
  }

  private originalValue?: Money;
  readonly amount: Decimal | null;
  readonly currency: string;

  constructor(amount: Decimal | string | null, currency: string) {
    // Decimal.set({rounding: Decimal.ROUND_DOWN})
    this.amount = amount ? new Decimal(amount) : null
    this.currency = currency
    // this.exchangeTo.bind(this)
  }

  add(money: Money): Money {
    if (this.currency !== money.currency) throw Error(`Must be same currency but get '${this.currency}' and '${money.currency}'`)
    return new Money((money.amount && this.amount && this.amount.add(money.amount)) ?? this.amount, this.currency)
  }
  sub(money: Money): Money {
    if (this.currency !== money.currency) throw Error(`Must be same currency but get '${this.currency}' and '${money.currency}'`)
    return new Money((money.amount && this.amount && this.amount.sub(money.amount)) ?? this.amount, this.currency)
  }
  mul(value: Decimal | string): Money {
    // value = typeof value === "string" ? Big(value) : value
    return new Money(this.amount && this.amount.mul(value), this.currency)
  }
  div(value: Decimal | string): Money {
    // value = typeof value === "string" ? Big(value) : value
    return new Money(this.amount && this.amount.div(value), this.currency)
  }

  gt(money:Money){
    if (this.currency !== money.currency) throw Error(`Must be same currency but get '${this.currency}' and '${money.currency}'`)
    return !!(this.amount && money.amount && this.amount.gt(money.amount))
  }

  round(dp: number=2, rm: Decimal.Rounding = Decimal.ROUND_HALF_UP): Money {
    return new Money(this.amount && this.amount.toDecimalPlaces(dp, rm), this.currency)
  }

  mround(round: Decimal.Value, rm: Decimal.Rounding = Decimal.ROUND_HALF_UP){
    const amount = this.amount && this.amount.dividedBy(round).toDecimalPlaces(0, rm).mul(round)
    // const amount = this.amount && this.amount.sub(this.amount.mod(round)).add(round)
    return new Money(amount, this.currency)
  }

  async exchangeTo<ShouldThrow extends boolean = true>(currency: string, options?: {
    // shouldThrow?: ShouldThrow
    preserveOriginal?: boolean
    date?: string
    exchangeMode?: "sell" | "buy"
    decimalPlaces?: number
  }): Promise<ShouldThrow extends true ? Money : Money | null>{
    const {
      // shouldThrow,
      preserveOriginal,
      date,
      exchangeMode,
      decimalPlaces
    } = {
      // shouldThrow: true,
      preserveOriginal: true,
      date: moment().format("YYYY-MM-DD"),
      exchangeMode: "sell" as const,
      ...(options || {})
    }
    if (preserveOriginal && this.originalValue) return this.originalValue.exchangeTo(currency, options)
    
    let result = new Money(this.amount ? 
        await Money._exchangeProvider.exchange(this.currency, currency, String(this.amount), date, exchangeMode == "sell") :
          // .catch(e => {
          //   if(shouldThrow){
          //     throw e
          //   }
          //   return null
          // }) :
        null, currency)

    if(result.amount && decimalPlaces){
      const rounded = result.round(decimalPlaces)
      const sigma = new Decimal("0.1").pow(decimalPlaces).dividedBy("2")
      result = rounded.amount!.minus(result.amount!).lessThan(await Money._exchangeProvider.exchange(this.currency, currency, String(sigma), date, exchangeMode == "sell")) ?
        rounded :
        result.round(decimalPlaces, Decimal.ROUND_DOWN)
    }

    if(preserveOriginal) result.originalValue = this

    return result
  }

  toFixed(dp: number, rm: Decimal.Rounding = Decimal.ROUND_HALF_UP){
    return `${this.amount ? this.amount.toFixed(dp, rm) : "-"} ${this.currency}`
  }

  toString(){
    return `${this.amount ?? "-"} ${this.currency}`
  }

  toJson(){
    return {
      amount: this.amount,
      currency: this.currency
    }
  }

  clone = (): Money => {
    const copy = new Money(this.amount, this.currency)
    copy.originalValue = this.originalValue
    return copy
  }

  equals(obj: unknown): boolean {
    return (obj instanceof Money)
      && ((this.amount !== null && obj.amount !== null && this.amount.equals(obj.amount)) || this.amount === obj.amount)
      && this.currency === obj.currency
  }
}