import { yupResolver } from "@hookform/resolvers/yup";
import moment from "moment";
import { forwardRef, useEffect, useImperativeHandle, useState } from "react";
import { Alert, Col, Form, InputGroup, Modal, Row } from "react-bootstrap";
import { Control, Controller, useController, useForm, useWatch } from "react-hook-form";
import { FaCheckCircle, FaSave } from "react-icons/fa";
import * as yup from "yup";
import { LoadingButton } from "../../../commons/components";
import { useServerValidationErrors } from "../../../commons/hooks";
import { Money, MoneyInput } from "../../../commons/money";
import { RawExchangeRate, useRegistrarExchangeRateMutation, useActualizarExchangeRateMutation } from "../services";
import { Currency } from "../types";
import { CurrencySelect } from "./CurrencySelect";

type FormValues = {
  validFrom: string
  source: Currency | null
  target: Currency | null
  rate: Money
  indirect: boolean
}

type Props = {
  defaultShow?: boolean
  data?: RawExchangeRate
}

export type ExchangeRateFormModalRef = {
  open(): void
  // close():void
}

const schema = yup.object({
  validFrom: yup.date().undefined().label("válido desde").required(),
  source: yup.mixed().label("moneda de origen").required(),
  target: yup.mixed().label("moneda de destino")
    .required()
    .notOneOf([yup.ref("source")], "La moneda de origen y la moneda de destino deben ser diferentes."),
  rate: yup.mixed().label("cotización").test("required", "El campo '${path}' es requerido.", function(value){
    return !!value.amount 
  }).test("min", "El campo '${path}' debe ser mayor que 0.", function(value){
    return !value.amount || value.amount.gt("0");
  })
})

export const ExchangeRateForm = forwardRef<ExchangeRateFormModalRef, Props>((props, ref)=>{
  const [show, setShow] = useState(!!props.defaultShow)
  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
    trigger,
    setError
  } = useForm<FormValues>({
    defaultValues:{
      validFrom: props.data?.validFrom ?? moment().format("YYYY-MM-DD"),
      source: props.data?.source ?? null,
      target: props.data?.target ?? null,
      indirect: props.data?.indirect ?? false,
      rate: props.data ? 
        new Money(props.data.rate, props.data.indirect ? props.data.source.code : props.data.target.code) :
        new Money(null, "")
    },
    resolver: yupResolver(schema)
  })

  useImperativeHandle(ref, ()=>({
    open(){
      setShow(true)
    }
  }), [])

  const [create, createState] = useRegistrarExchangeRateMutation()
  const [update, updateState] = useActualizarExchangeRateMutation()
  const mutationState = props.data ? updateState : createState

  useServerValidationErrors(mutationState, setError)

  const renderAlert = () => {
    if (mutationState.isSuccess) {
      return <Alert className="text-nowrap" variant="success">
        <FaCheckCircle className="me-1" style={{ position: "relative", top: "-0.125rem" }} />
        La solicitud se completó exitosamente.
      </Alert>
    }
    if (mutationState.isError) {
      const error = mutationState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <Modal
    centered
    show={show}
    onHide={()=>{
      setShow(false)
    }}
  >
    <Modal.Header>{props.data ? "Actualizacion" :  "Registro"}</Modal.Header>
    <Modal.Body>
      <Form aria-label="Formulario de registro de tipos de cambio" onSubmit={handleSubmit((values)=>{
        props.data ?
          update({
            id: props.data.id,
            validFrom: moment(values.validFrom).format("YYYY-MM-DD"),
            source: values.source!.code,
            target: values.target!.code,
            rate: String(values.rate.amount),
            indirect: values.indirect
          }).unwrap().then(()=>{
            reset({
              ...values,
              validFrom: moment(values.validFrom).format("YYYY-MM-DD")
            })
          }, ()=>{}) :
          create({
            validFrom: moment(values.validFrom).format("YYYY-MM-DD"),
            source: values.source!.code,
            target: values.target!.code,
            rate: String(values.rate.amount),
            indirect: values.indirect
          }).unwrap().then(()=>{
            reset()
          }, ()=>{})
      })}>
        {renderAlert()}
        <Row className="gx-3">
          <Form.Group as={Col} sm={6} className="mb-3" data-testid="validfrom-form-group" controlId="validfrom">
            <Form.Label>Válido desde</Form.Label>
            <Form.Control
              {...register("validFrom")}
              type="date"
              isInvalid={!!formState.errors.validFrom}
            />
            <Form.Control.Feedback type="invalid">{formState.errors.validFrom?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <Row className="gx-3">
          <Controller
            control={control}
            name="source"
            render={({field, fieldState})=>{
              return <Form.Group as={Col} sm={6} className="mb-3"  data-testid="source-form-group" controlId="source">
                <Form.Label>Moneda de origen</Form.Label>
                <CurrencySelect
                  {...field}
                  inputId="source"
                  onChange={(value)=>{
                    field.onChange(value)
                    formState.isSubmitted && trigger("target")
                  }}
                  isInvalid={!!fieldState.error}
                />
                <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
              </Form.Group>
            }}
          />
          <Controller
            control={control}
            name="target"
            render={({field, fieldState})=>{
              return <Form.Group as={Col} sm={6} className="mb-3"  data-testid="target-form-group" controlId="target">
                <Form.Label>Moneda de destino</Form.Label>
                <CurrencySelect
                  {...field}
                  inputId="target"
                  isInvalid={!!fieldState.error}
                />
                <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
              </Form.Group>
            }}
          />
        </Row>

        <Form.Check
          {...register("indirect")}
          id="indirect-rate"
          className="mb-3"
          label="Tipo de cambio indirecto"
          isInvalid={!!formState.errors.indirect}
          feedbackType="invalid"
          feedback={formState.errors.indirect?.message}
        />
        <RateControl control={control}/>
        <LoadingButton
          type="submit"
          disabled={!formState.isDirty || !!Object.keys(formState.errors).length}
          icon={<FaSave className="mt-n1" />}
          isLoading={mutationState.isLoading}
        >
          Guardar
        </LoadingButton>
      </Form>
    </Modal.Body>
  </Modal>
})

function RateControl({
  control
}: {
  control: Control<FormValues>
}){
  const {
    field,
    fieldState
  } = useController({
    control,
    name: "rate"
  })

  const source = useWatch({
    control,
    name: "source"
  })
  const target = useWatch({
    control,
    name: "target"
  })
  const indirect = useWatch({
    control,
    name: "indirect"
  })

  const value = field.value
  useEffect(()=>{
    const rateCurrency = indirect ? source?.code : target?.code
    if(value?.currency !== rateCurrency){
      field.onChange(new Money(value?.amount ?? null, rateCurrency ?? ""))
    }
  }, [source, target, indirect])

  return <Row className="gx-3">
    <Form.Group as={Col} sm={6} className="mb-3"  data-testid="rate-form-group"  controlId="cotizacion">
      <Form.Label>Cotización</Form.Label>
      <InputGroup>
        {!indirect ? <InputGroup.Text>1 {source?.code ?? "???"} =</InputGroup.Text> : null}
        <MoneyInput
          {...field}
          isInvalid={!!fieldState.error}
          //@ts-ignore
          decimalScale={6}
          //@ts-ignore
          fixedDecimalScale={false}
        />
        {indirect ? <InputGroup.Text>= 1 {target?.code ?? "???"}</InputGroup.Text> : null}
        <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
      </InputGroup>
    </Form.Group>
  </Row>
}