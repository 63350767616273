import { useCallback, useEffect, ReactElement } from 'react';
import { Form, InputGroup } from 'react-bootstrap';
import { components, GroupBase, OptionProps } from 'react-select';
import { Props as SelectProps } from 'react-select';
import {
  AsyncPaginate,
  UseAsyncPaginateParams,
  ComponentProps,
} from 'react-select-async-paginate';
import { VendedorView, useLazyGetVendedoresQuery } from '../services/vendedoresApi';

export type { VendedorView }

const OptionWithDescription = <
  IsMulti extends boolean,
  Group extends GroupBase<VendedorView>
>({...props}: OptionProps<VendedorView, IsMulti, Group>)=>{
  if((props.data as any).__isNew__) return <components.Option {...props}/>
  return <components.Option {...props}>
    <div>{props.label}</div>
    <div className={props.isSelected ? "text-light" : "text-muted"}>{(props.selectProps as any).getOptionSubLabel(props.data)}</div>
  </components.Option>
}

type Props<OptionType, IsMulti extends boolean, Group extends GroupBase<OptionType>> = SelectProps<OptionType, IsMulti, Group>
& UseAsyncPaginateParams<OptionType, Group, {page: number}>
& ComponentProps<OptionType, Group, IsMulti>;


type CustomProps = {
  feedback?: string
}

export function VendedorSelect<IsMulti extends boolean, Group extends GroupBase<VendedorView>>({
  feedback,
  ...props
}: Omit<Props<VendedorView, IsMulti, Group>, "loadOptions"> & CustomProps){
  const [buscar, buscarState] = useLazyGetVendedoresQuery()

  const loadOptions = useCallback<Props<VendedorView, IsMulti, Group>["loadOptions"]>(async (search, loadedOptions, additional)=>{
    if(!search) return { options: [] }
    const response = await buscar({
      search,
      page: {
        current: additional!.page,
        size: 100
      }
    }).unwrap()

    return {
      options: response.records,//[...loadedOptions, ...response.records],
      hasMore: !!response.meta.nextPage,
      additional: response.meta.nextPage !== undefined ? { page: response.meta.nextPage } : undefined
    }
  }, [buscarState])
  
  return <InputGroup hasValidation>
    <AsyncPaginate
      //@ts-ignore
      getOptionSubLabel={props.getOptionSubLabel ?? (data => `C.I. ${data.numeroDocumento}`)}
      isClearable
      {...props}
      className={["form-control", props.className].filter(cn=>!!cn).join(" ")}
      styles={{
        menuPortal: base => ({ ...base, zIndex: 999 }),
        container: base => ({...base, padding: 0, flex: 1}),
        control: (styles: any)=>{
          delete styles["boxShadow"]
          return {
            ...styles,
            borderRadius: "0.375rem",
            borderWidth: 0,
          }
        },
        ...(props.styles ?? {})
      }}
      components={{
        Option: OptionWithDescription
      }}
      menuPlacement="auto"
      placeholder=""
      getOptionValue={option => (option as any).value ?? String(option.id)}
      getOptionLabel={option => (option as any).label ?? `${option.id}, ${option.nombreCompleto}`}
      loadOptionsOnMenuOpen={false}
      debounceTimeout={300}
      additional={{page: 1}}
      loadOptions={loadOptions}
      menuPortalTarget={document.body}
    />
    {/* {buscarState.isError ? <Button><FaSync /></Button> : null} */}
    <Form.Control.Feedback type="invalid">{feedback}</Form.Control.Feedback>
  </InputGroup>
}