import { appApi } from "../../commons/services";
import { PaginatedResponse, RawMoney } from "../../commons/services/types";

export type Categoria = {
  id: number
  codigo: string
  descripcion: string
  precioM2: RawMoney
}

type CategoriaBody = Omit<Categoria, "precioM2"> & {
  precioM2: string
  proyectoId: number
}

const categoriaApi = appApi.enhanceEndpoints({
  addTagTypes: ["Categoria"]
}).injectEndpoints({
  endpoints: (builder)=>({
    getCategorias: builder.query<PaginatedResponse<Categoria>, {proyectoId: number}>({
      query: ({proyectoId})=>{
        return `proyectos/${proyectoId}/categorias`
      },
      providesTags: [{type: "Categoria", id: "*"}]
    }),

    registrarCategoria: builder.mutation<Categoria, Omit<CategoriaBody, "id">>({
      query: ({proyectoId, ...body}) => ({
        method: "POST",
        url: `proyectos/${proyectoId}/categorias`,
        body
      }),
      invalidatesTags: (result) => result ? [{type: "Categoria", id: "*"}] : []
    }),
    actualizarCategoria: builder.mutation<Categoria, CategoriaBody>({
      query: ({proyectoId, id, ...body}) => ({
        method: "PUT",
        url: `proyectos/${proyectoId}/categorias/${id}`,
        body
      }),
      invalidatesTags: (result) => result ? [{type: "Categoria", id: "*"}] : []
    })
  })
})

export const {
  useGetCategoriasQuery,
  useRegistrarCategoriaMutation,
  useActualizarCategoriaMutation
} = categoriaApi