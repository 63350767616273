import moment from "moment"
import { useRef, useState } from "react"
import { Breadcrumb } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { IndexTemplate, TableView } from "../../../commons/components"
import { ufvPolicy } from "../policies"
import { useGetUFVsQuery } from "../ufvApi"
import { UfvFormModal, UfvFormModalRef } from "./UfvFormModal"

export const UfvIndex = ()=>{
  const [page, setPage] = useState({current: 1, size: 10})

  const queryParams = {page}
  const buscar = useGetUFVsQuery(queryParams)
  const isRefetching = buscar.isFetching && !buscar.isLoading

  const ufvFormModalRef = useRef<UfvFormModalRef>(null)

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item active>UFV</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      total={buscar.data?.meta.totalRecords || -1}
      page={page}
      pageSizes={[10,20,30,50,100]}
      onRegister={()=>ufvFormModalRef.current?.open()}
      policy={ufvPolicy}
      onPageChange={setPage}
      onRefetch={buscar.refetch}
      renderView={()=>{
        return <TableView
          expectedLength={page.size}
          data={isRefetching ? undefined : buscar.currentData?.records}
          isLoading={buscar.isFetching}
          hasError={buscar.isError}
          renderHeaders={()=><tr>
            <th style={{width: 0}}>#</th>
            <th>Fecha</th>
            <th>Valor</th>
            <th style={{width: 0}}></th>
          </tr>}
          renderRow={(item, index)=><tr key={item?.id || index}>
            <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
            <td>{item ? moment(item.fecha, "YYYY-MM-DD").format("DD/MM/YYYY") : <Skeleton />}</td>
            <td className="text-end">{item ? item.valor : <Skeleton />}</td>
              <td>{item ? null : null}</td>
          </tr>}
        />
      }}
    />
    <UfvFormModal ref={ufvFormModalRef} />
  </div>
}