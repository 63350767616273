import { createBaseQuery } from "./createBaseQuery"
import { apiBaseUrl, authBaseUrl } from "../../configs/app"

export * from "./PaginatedResponse"

export type Page = {
  current: number,
  size: number
}

export type PaginationParams<Filter> = {
  search?: string
  filter?: Filter,
  page?: Page
}

export const apiBaseQuery = createBaseQuery(apiBaseUrl)
export const authBaseQuery = createBaseQuery(authBaseUrl)

export * from "./appApi"