import { Form, Button, Alert, Image, Spinner } from 'react-bootstrap'
import { useForm } from "react-hook-form"
import { Navigate, useLocation } from "react-router-dom"
import { useLoginMutation } from '../services'
// import { yupResolver } from '@hookform/resolvers/yup'
// import * as yup from "yup"
import { useUser } from "../hooks"
import "./auth.css"
import { LoadingButton } from '../../components'

type Credentials = {
  username: string,
  password: string
}

type Inputs = Credentials & {
  rememberMe: boolean
}

// const schema = yup.object().shape({
//   username: yup.string().required(),
//   password: yup.string().required()
// })

export const Login = () => {
  const location = useLocation()
  const from = (location.state as { from: string })?.from
  const user = useUser()
  const [login, loginState] = useLoginMutation()

  const {handleSubmit, register, formState} = useForm<Inputs>({
    // resolver: yupResolver(schema),
    defaultValues: {
      username:"",
      password: "",
      rememberMe: false
    }
  })

  if(user?.isReady) return <Navigate replace to={from || "/"} />

  return <div className="auth-wrapper bg-light">
      <div className="auth-inner shadow-sm">
        <Form onSubmit={handleSubmit((credentials)=>{
            login(credentials)
          })}
        >
          <div className="d-flex justify-content-center mb-1"><Image src="/logo192.png" /></div>
          <h3 className="mb-2 text-center text-uppercase" style={{ fontSize: "1.25rem" }}>Iniciar Sesión</h3>
          {loginState.isError ? <Alert variant="danger" >{(loginState.error as any)?.response?.message || (loginState.error as any)?.message}</Alert> :  null}
          <Form.Group className="mb-3">
            <Form.Label>Usuario</Form.Label>
            <Form.Control
              isInvalid={!!formState.errors.username}
              {...register("username")}
            />
            <Form.Control.Feedback type="invalid" >{formState.errors.username?.message}</Form.Control.Feedback>
          </Form.Group>
          <Form.Group className="mb-3">
            <Form.Label>Contraseña</Form.Label>
            <Form.Control type="password"
              isInvalid={!!formState.errors.password}              
              {...register("password")}
            />
            <Form.Control.Feedback type="invalid" >{formState.errors.password?.message}</Form.Control.Feedback>
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Check
              type={"checkbox"}
              label="Recordar sesión"
              {...register("rememberMe")}
            />
          </Form.Group>

          <LoadingButton
            type="submit"
            className="w-100"
            isLoading={loginState.isLoading}
          >
            Iniciar Sesión
          </LoadingButton>
          {/* <p className="forgot-password text-right">
        ¿Olvidó su <a href="#">contraseña?</a>
      </p> */}
        </Form>
      </div>
    </div>
}

export default Login