import { appApi, PaginatedResponse } from "../../commons/services";
import { PaginationParams } from "../../commons/services/types";

type RolFilter = {
}

export type Rol = {
  id: number
  name: string
  description: string
  permissions: {
    name: string
  }[]
}

export const rolesApi = appApi.enhanceEndpoints({
  addTagTypes: ["Rol"]
}).injectEndpoints({
  endpoints: (builder) => ({
    getRoles: builder.query<PaginatedResponse<Omit<Rol, "permissions">>, PaginationParams<RolFilter>>({
      query: (params={}) => {
        return {
          url: "/roles",
          params
        }
      },
      providesTags: (results) => (results?.records ?? [])
        .map(({ id }) => ({ type: "Rol" as const, id: String(id) }))
        .append({ type: "Rol" as const, id: "*" })
    }),
    getRol: builder.query<Rol, number>({
      query: (id)=>`roles/${id}`,
      providesTags: (results) => results ? [{ type: "Rol" as const, id: String(results.id) }] : []
    }),
    //Mutations
    registrarRol: builder.mutation<Rol, Omit<Rol, "id" | "permissions"> & {
      permissions: string[]
    }>({
      query: (body)=>({
        method: "POST",
        url: "/roles",
        body
      }),
      invalidatesTags: (results) => results ? [{ type: "Rol" as const, id: "*" }] : []
    }),
    actualizarRol: builder.mutation<Rol, Omit<Rol, "permissions"> & {
      permissions: string[]
    }>({
      query: ({id, ...body})=>({
        method: "PUT",
        url: `/roles/${id}`,
        body
      }),
      async onQueryStarted(args, { dispatch, queryFulfilled, getState }) {
        try {
          await queryFulfilled
          for(let tag of (getState().appApi.provided.Rol[args.id] ?? [])){
            const regex = /(?<endpoint>.*)\((?<queryArgs>.*)\)/
            const {groups: {endpoint, queryArgs}} = regex.exec(tag) as any
            if(endpoint === "getRoles"){
              dispatch(rolesApi.util.updateQueryData('getRoles', JSON.parse(queryArgs), (draft) => {
                Object.assign(draft, {
                  records: draft.records.map(u => {
                    return u.id === args.id ? {
                      ...u,
                      name: args.name,
                      description: args.description,
                    } : u
                  }),
                })
              }))
            }
            else if(endpoint === "getRol"){
              dispatch(rolesApi.util.updateQueryData('getRol', +queryArgs, (draft) => {
                Object.assign(draft, {
                  name: args.name,
                  description: args.description,
                  permissions: args.permissions.map(name => ({
                    name
                  }))
                })
              }))
            }
          }
        }
        catch (e) {
          // console.error(e)
        }
      }
    }),
  })
})

export const {
  useGetRolesQuery,
  useGetRolQuery,
  useRegistrarRolMutation,
  useActualizarRolMutation
} = rolesApi