import { yupResolver } from "@hookform/resolvers/yup";
import { watch } from "fs";
import { forwardRef, useImperativeHandle, useState } from "react";
import { Button, Form, Image, Modal } from "react-bootstrap"
import { Controller, useForm, FieldErrorsImpl, DeepRequired } from "react-hook-form";
import Select from "react-select";
import * as yup from "yup";
import { usePreview } from "../../../commons/hooks";
import { Money, MoneyInput } from "../../../commons/money";


type FormValues = {
  formaPago: {
    id: number,
    label: string
  }
  importe: Money
  numeroComprobante: number
  comprobante: File
}

export type MedioPagoFormModalRef = {
  open(value: Partial<FormValues>, index?: number, errors?: FieldErrorsImpl<DeepRequired<FormValues>>): void
}

type Props = {
  readOnly?: boolean
  onSubmit?: (values: FormValues, index?: number) => void
}
const MediosPagoOptions = [
  {id: 1, label: "Efectivo"},
  {id: 2, label: "Deposito o transferencia bancaria"}
]
export const MedioPagoFormModal = forwardRef<MedioPagoFormModalRef, Props>(({onSubmit, readOnly}, ref)=>{
  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(yup.object({
      formaPago: yup.object().required(),
      importe: yup.mixed().label("importe").required().test("min", "El importe debe ser mayor que 0", function(value, context){
        return !value.amount || value.amount.gt("0")
      }).test("required", "El importe es requerido", function(value){
        return !!value?.amount
      }),
      numeroComprobante: yup.number().undefined().label("n.º de comprobante").when("formaPago", {
        is: 2,
        then: schema => schema.required()
      }),
      comprobante: yup.mixed().label("comprobante").when("formaPago", {
        is: 2,
        then: (schema) => schema.test("required", "El ${path} es requerido", function(value, context){
          return !!value
        })
      })
    }))
  })

  const [show, setShow] = useState(false)
  const [index, setIndex] = useState<number>()

  useImperativeHandle(ref, () => {
    return {
      open(value, index, errors) {
        setIndex(index)
        setValue("formaPago", value.formaPago ?? null as any)
        setValue("importe", value.importe!)
        setValue("numeroComprobante", value.numeroComprobante ?? "" as any)
        setValue("comprobante", value.comprobante ?? null as any)
        errors?.formaPago && setError("formaPago", errors.formaPago)
        errors?.importe && setError("importe", errors.importe)
        errors?.numeroComprobante && setError("numeroComprobante", errors.numeroComprobante)
        errors?.comprobante && setError("comprobante", errors.comprobante as any)
        setShow(true)
      },
    }
  })

  let comprobantePreview = usePreview(!readOnly ? watch("comprobante") : null)
  comprobantePreview = readOnly ? watch("comprobante") as any : comprobantePreview

  const renderForm = () => {
    const formaPago = watch("formaPago")
    // console.log(formaPago)
    return <Form aria-label="Formulario medios de pago" onSubmit={handleSubmit((values)=>{
        onSubmit?.(values, index)
        reset(undefined, {
          keepIsSubmitted: false,
          keepSubmitCount: false
        })
        setShow(false)
      })}
    >
      <Form.Group className="mb-3" controlId="forma-pago">
        <Form.Label>Forma de pago</Form.Label>
        {
          readOnly ? <Form.Control
            readOnly
            {...register("formaPago.label")}
          /> : <Controller
            control={control}
            name="formaPago"
            render={({field, fieldState})=>{
              return <Select
                {...field}
                inputId="forma-pago"
                options={MediosPagoOptions}
                getOptionValue={option => String(option.id)}
              />
            }}
          />
        }
      </Form.Group>
      <Controller
        control={control}
        name="importe"
        render={({field})=>{
          return <Form.Group className="mb-3" controlId="importe">
            <Form.Label>Importe</Form.Label>
            {
              readOnly ? 
                <Form.Control readOnly value={`${field.value.amount} ${field.value.currency}`} /> :
                <MoneyInput
                  {...field}
                />
            }
          </Form.Group>
        }}
      />        
      {formaPago?.id == 2 ? <>
        <Form.Group className="mb-3" controlId="numero-comprobante">
          <Form.Label>N.º de comprobante</Form.Label>
          <Form.Control
            readOnly={readOnly}
            {...register("numeroComprobante", {
              shouldUnregister: true
            })}
          />
        </Form.Group>
        {readOnly ? null : <Controller
          control={control}
          name="comprobante"
          // shouldUnregister
          render={({field, fieldState})=>{
            return <Form.Group className="mb-3" controlId="comprobante">
              <Form.Label>Comprobante</Form.Label>
              <Form.Control
                type="file"
                accept="image/*"
                ref={field.ref}
                name={field.name}
                onBlur={field.onBlur}
                onChange={(e: any)=>{
                  field.onChange(e.target.files.item(0))
                }}
                isInvalid={!!fieldState.error}
              /> 
            </Form.Group>
          }}
        />}
        {comprobantePreview ? <div className="text-center mb-3">
          <Image src={comprobantePreview} fluid  />
        </div> : null}
      </> : null}
      <Button type="submit">
        Aceptar
      </Button>
    </Form>
  }

  return <Modal centered show={show} onHide={()=>setShow(false)}>
    <Modal.Header>Medio de pago</Modal.Header>
    <Modal.Body>
      {renderForm()}
    </Modal.Body>
  </Modal>
})