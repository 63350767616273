import { Alert, Breadcrumb, Col, Row, Spinner } from "react-bootstrap"
import { FaEdit } from "react-icons/fa"
import { Link, useParams } from "react-router-dom"
import { useGetRolQuery } from "../rolesApi"
import { RolForm } from "./RolForm"

export function RolView(){
  const {
    rolId
  } = useParams()
  const getRol = useGetRolQuery(+rolId)

  const renderView = ()=>{
    if(getRol.isLoading){
      return <div className="text-nowrap">
        <Spinner size="sm" animation="border" className="me-2" />
        Cargando...
      </div>
    }

    if(getRol.error){
      const error = getRol.error as any
      const message: Array<JSX.Element | string> = [
        "Ocurrio un error al realizar la solicitud:",
        <br key="error-br" />,
        error.message ?? error.data?.message ?? "Error de red"
      ]
      return <Alert variant="danger">
        {message}
      </Alert>
    }

    const rol = getRol.currentData

    return <>
      <RolForm
        data={rol}
        readOnly
      />
      <Row className="gx-3">
      <Col xs="auto">
          <Link
            className="btn btn-primary"
            to="editar"
          >
            <FaEdit className="me-1 mt-n1" />
            Editar
          </Link>
        </Col>
      </Row>
    </>
  }

  return <>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{
        to: "../roles"
      }}>Roles</Breadcrumb.Item>
      <Breadcrumb.Item active>{rolId}</Breadcrumb.Item>
    </Breadcrumb>
    {renderView()}
  </>
}