import Decimal from "decimal.js"
import moment from "moment"
import { useState } from "react"
import { Breadcrumb } from "react-bootstrap"
import { FaExclamationTriangle } from "react-icons/fa"
import Skeleton from "react-loading-skeleton"
import { useParams } from "react-router-dom"
import { IndexTemplate, TableView } from "../../../commons/components"
import { useRefetch } from "../../../commons/utils"
import { ventasPolicy } from "../policies"
import { useGetVentasQuery, VentaFilter } from "../ventasApi"
import { RowOptions } from "./RowOptions"

export const VentasIndex = () => {

  const { proyectoId } = useParams()

  const [page, setPage] = useState({current: 1, size: 10})
  const [filter, setFilter] = useState<VentaFilter>({})

  const buscar = useGetVentasQuery({context: {proyectoId: proyectoId!}, params: {filter, page}})

  const [isRefetching, refetch] = useRefetch(buscar)

  return <div>    
    <Breadcrumb>
      <Breadcrumb.Item active>Ventas</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      total={buscar.data?.meta.totalRecords || -1}
      page={page}
      pageSizes={[10,20,30,50,100]}
      registerPath="registrar"
      policy={ventasPolicy}
      onPageChange={setPage}
      onRefetch={refetch}
      renderView={()=>{
        return <TableView
          expectedLength={page.size}
          data={isRefetching ? undefined : buscar.currentData?.records}
          isLoading={buscar.isFetching}
          hasError={buscar.isError}
          renderHeaders={()=><tr>
            <th style={{width: 0}}>#</th>
            <th>Fecha</th>
            <th>Cliente</th>
            <th>Lote</th>
            <th>Importe</th>
            <th>Crédito</th>
            <th>Estado</th>
            <th style={{width: 0}}></th>
          </tr>}
          renderRow={(item, index)=><tr key={item?.id || index}>
            <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
            <td className="text-nowrap">{item ? moment(item.fecha).format("DD/MM/YYYY") : <Skeleton />}</td>
            <td>{item ? <>
              <div>{item.cliente.nombreCompleto}</div>
              <div className="small text-muted" style={{textTransform: "none"}}>{`Código de pago: ${item.cliente.codigoPago}`}</div>
              <div className="small text-muted" style={{textTransform: "none"}}>{`${item.cliente.documentoIdentidad.tipoText}: ${item.cliente.documentoIdentidad.numero}`}</div>
            </> : <Skeleton />}</td>
            <td className="text-nowrap">
              {item?.observaciones ? <FaExclamationTriangle title={item?.observaciones} className="text-warning mt-n1 me-1" /> : null}
              {item ? `Mz ${item.lote.manzana.numero}, Lt ${item.lote.numero}` : <Skeleton />}
            </td>
            <td className="text-nowrap">{item ? `${new Decimal(item.importe.amount).toFixed(2)} ${item.importe.currency}` : <Skeleton />}</td>
            <td  className="text-nowrap" style={{textTransform: "none"}}>{item ? item.credito ? <>
              <div>{new Decimal(item.importePendiente.amount).toFixed(2)} {item.importePendiente.currency}</div>
              <div className="small text-muted">Código: {item.credito.codigo}</div>
            </> : "NO" : <Skeleton />}</td>
            <td className={item ? {1: "bg-primary text-white", 2: "bg-danger text-white"}[item.estado] : ""}>{item ? item.estado == 1 && new Decimal(item.saldo.amount).gt("0") ? "Pago pendiente" : {
              0: "Procesando",
              1: "Registrado",
              2: "Anulado"
            }[item.estado] ?? "" : <Skeleton />}</td>
            <td>{item ? <RowOptions venta={item} /> : null}</td>
          </tr>}
        />
      }}
    />
  </div>
}