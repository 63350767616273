import { LatLngExpression } from "leaflet"
import { useCallback, useEffect } from "react"
import { Breadcrumb, Col, Form, Row, Toast } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { Link, Navigate } from "react-router-dom"
import { LoadingButton } from "../../../commons/components"
import { LocationControl } from "../../../commons/components/LocationControl"
import { PercentageInput } from "../../../commons/components/PercentageInput"
import { useServerValidationErrors } from "../../../commons/hooks"
import { Money, MoneyInput } from "../../../commons/money"
import { useRegistrarProyectoMutation } from "../proyectosApi"
import * as yup from "yup"
import Decimal from "decimal.js"
import { yupResolver } from "@hookform/resolvers/yup"
import { createPortal } from "react-dom"

type FormValues = {
  nombre: string
  // socio: string
  ubicacion: LatLngExpression

  moneda: string
  precioReservas: Money
  duracionReservas: number
  redondeo: number

  cuotaInicial: Money
  tasaInteres: string
  tasaMora: string
}

const schema = yup.object().shape({
  nombre: yup.string().required("El campo '${path}' es requerido"),
  ubicacion: yup.mixed().label("ubicación").required("El campo '${path}' es requerido"),

  moneda: yup.string().oneOf(["USD", "BOB"]),
  redondeo: yup.number().required().min(0.01),
  precioReservas: yup.mixed().label("precio").test("required", "El campo '${path}' es requerido", function(value: Money){
    return !!value.amount
  }).test("min", "El campo '${path}' debe ser mayor que 0", function(value: Money){
    return !!value.amount?.gt("0")
  }),
  duracionReservas: yup.number().label("duración").undefined().required("El campo '${path}' es requerido").integer(),

  cuotaInicial: yup.mixed().label("cuota inicial").test("required", "El campo '${path}' es requerido", function(value: Money){
    return !!value.amount
  }).test("min", "El campo '${path}' debe ser mayor que 0", function(value: Money){
    return !!value.amount?.gt("0")
  }),
  tasaInteres: yup.string().label("tasa de interés").undefined().required("El campo '${path}' es requerido").test("min", "El campo '${path}' debe ser mayor o igual que 0", function(value){
    return !value || !! new Decimal(value).gte("0")
  }),
  tasaMora: yup.string().label("tasa de interés por mora").undefined().required("El campo '${path}' es requerido").test("min", "El campo '${path}' debe ser mayor o igual que 0", function(value){
    return !value || !! new Decimal(value).gte("0")
  }),
})

export const ProyectoForm = ()=>{

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError,
    setValue,
    watch
  } = useForm<FormValues>({
    resolver: yupResolver(schema),
    defaultValues: {
      nombre: "",
      moneda: "USD",
      redondeo: 100,
      precioReservas: new Money(null, "USD"),
      duracionReservas: 7,
      cuotaInicial: new Money(null, "USD"),
      // tasaInteres: "0.1",
      // tasaMora: "0.03"
    }
  })

  const [createProyecto, creationState] = useRegistrarProyectoMutation()
  // const [updateProyecto, updateState] = useActualizarProyectoMutation()

  useServerValidationErrors(creationState, setError, useCallback((key: string) => {
    if (key === "ubicacion.latitud" || key == "ubicacion.longitud") return "ubicacion"
    return key
  }, []))

  useEffect(()=>{
    watch((values, info)=>{
      if(info.name === "moneda"){
        setValue("precioReservas", new Money((values.precioReservas as Money).amount, values.moneda!))
        setValue("cuotaInicial", new Money((values.cuotaInicial as Money).amount, values.moneda!))
      }
    })
  }, [formState.isSubmitted])

  const renderAlert = ()=>{
    const request = creationState
    if(request.isSuccess){
      return <Toast className="fixed-bottom ms-auto me-2 mb-2" bg="success" delay={5} autohide>
        <Toast.Header closeButton={false}>OK</Toast.Header>
        <Toast.Body className="text-white">El preyecto ha sido registrado</Toast.Body>
      </Toast>
    }
    else if(request.isError){
      return <Toast className="fixed-bottom ms-auto me-2 mb-2" bg="danger" delay={5} autohide>
        <Toast.Header closeButton={false}>Error</Toast.Header>
        <Toast.Body className="text-white">Ocurrió un error al procesar la solicitud</Toast.Body>
      </Toast>
    }
  }

  if(creationState.isSuccess){
    return <Navigate
      to={`../proyectos/${creationState.data.id}`}
      replace
    />
  }

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{ to: "../proyectos" }}>Mis proyectos</Breadcrumb.Item>
      <Breadcrumb.Item active>Registro</Breadcrumb.Item>
    </Breadcrumb>
    <Form aria-label="Formulario de registro de proyectos" onSubmit={handleSubmit(async (values)=>{
      try{
        const [lat, lng] = values.ubicacion instanceof Array ? values.ubicacion : [values.ubicacion.lat, values.ubicacion.lng]
        await createProyecto({
          nombre: values.nombre,
          ubicacion: {
            latitud: lat,
            longitud: lng
          },
  
          moneda: values.moneda,
          redondeo: values.redondeo.toFixed(2),
          precioReservas: values.precioReservas.amount!.toFixed(2),
          duracionReservas: values.duracionReservas,
          cuotaInicial: values.cuotaInicial.amount!.toFixed(2),
          tasaInteres: new Decimal(values.tasaInteres).dividedBy(100).toFixed(4),
          tasaMora: new Decimal(values.tasaMora).dividedBy(100).toFixed(4)
        }).unwrap()
        reset()
      }
      catch(e) {
        // console.error(e)
      }
    })}>
      <Row className="gx-2">
          <Form.Group as={Col} xs={12} className="mb-3" controlId="nombre">
            <Form.Label>Nombre</Form.Label>
            <Form.Control
              {...register("nombre")}
              isInvalid={!!formState.errors.nombre}
            />
            <Form.Control.Feedback type="invalid">{formState.errors.nombre?.message}</Form.Control.Feedback>
          </Form.Group>
          {/* <Form.Group as={Col} md={6} className="mb-3" controlId="socio">
            <Form.Label>Socio</Form.Label>
            <Form.Control />
            <Form.Control.Feedback type="invalid"></Form.Control.Feedback>
          </Form.Group> */}
          <Controller
              control={control}
              name="ubicacion"
              render={({field: {ref, ...field}, fieldState})=>{
                return <Form.Group data-testid="ubicacionFormGroup" as={Col} xs={12} className="mb-3" controlId="ubicacion">
                  <Form.Label>Ubicación</Form.Label>
                  <LocationControl
                    {...field}
                    zoom={5}
                    center={[-17.7837273,-63.1822753]}
                    isInvalid={!!fieldState.error}
                  />
                  <Form.Control.Feedback type="invalid">{fieldState.error?.message?.split("\n").map((e, i)=><div key={i}>{e}</div>)}</Form.Control.Feedback>
                </Form.Group>
              }}
            />
        </Row>
        <h2 className="fs-5 mb-3">Valores predeterminados del proyecto</h2>
        <Row className="gx-2">
          <Col md={6}>
            <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
              <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Moneda</Form.Label>
              <Row className="gx-2" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
                <Col>
                  <Form.Check
                    id="dolar"
                    type="radio"
                    label="Dolar"
                    value="USD"
                    isInvalid={!!formState.errors.moneda && getValues("moneda") === "USD"}
                    feedback={formState.errors.moneda?.message}
                    feedbackType="invalid"
                    {...register("moneda")}
                  />
                </Col>
                <Col>
                  <Form.Check
                    id="boliviano"
                    type="radio"
                    label="Boliviano"
                    value="BOB"
                    isInvalid={!!formState.errors.moneda && getValues("moneda") === "BOB"}
                    feedback={formState.errors.moneda?.message}
                    feedbackType="invalid"
                    {...register("moneda")}
                  />
                </Col>
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <Row className="gx-2">
          <Col md={3}>
            <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
              <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Lotes</Form.Label>
              <Row className="gx-2">
                <Form.Group as={Col} controlId="redondeo">
                  <Form.Label>Redondeo</Form.Label>
                  <Form.Control
                    {...register("redondeo")}
                    isInvalid={!!formState.errors.redondeo}
                  />
                  <Form.Control.Feedback type="invalid">{formState.errors.redondeo?.message}</Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form.Group>
          </Col>
          <Col md={6}>
            <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
              <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Reservas</Form.Label>
              <Row className="gx-2">
                <Controller
                  control={control}
                  name="precioReservas"
                  render={({field, fieldState})=>{
                    return <Form.Group as={Col} xs={6} controlId="precioReservas">
                      <Form.Label>Precio</Form.Label>
                      <MoneyInput
                        {...field}
                        isInvalid={!!fieldState.error}
                      />
                      <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                    </Form.Group>
                  }}
                />
                <Form.Group as={Col} xs={6} controlId="duracionReservas">
                  <Form.Label>Duración</Form.Label>
                  <Form.Control
                    {...register("duracionReservas")}
                    isInvalid={!!formState.errors.duracionReservas}
                  />
                  <Form.Control.Feedback type="invalid">{formState.errors.duracionReservas?.message}</Form.Control.Feedback>
                </Form.Group>
              </Row>
            </Form.Group>
          </Col>
          <Col md={9}>
            <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
              <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Créditos</Form.Label>
              <Row className="gx-2">
                <Controller
                  control={control}
                  name="cuotaInicial"
                  render={({field, fieldState})=>{
                    return <Form.Group as={Col} md={4} xs={6} controlId="cuotaInicial">
                      <Form.Label>Cuota inicial</Form.Label>
                      <MoneyInput
                        {...field}
                        isInvalid={!!fieldState.error}
                      />
                      <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                    </Form.Group>
                  }}
                />
                <Controller
                  control={control}
                  name="tasaInteres"
                  render={({field, fieldState})=>{
                    return <Form.Group as={Col} md={4} xs={6} controlId="tasaInteres">
                      <Form.Label>Tasa de interés</Form.Label>
                      <PercentageInput
                        {...field}
                        isInvalid={!!fieldState.error}
                      />
                      <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                    </Form.Group>
                  }}
                />
                <Controller
                  control={control}
                  name="tasaMora"
                  render={({field, fieldState})=>{
                    return <Form.Group as={Col} md={4} xs={6} controlId="tasamora">
                      <Form.Label>Tasa de interés por mora</Form.Label>
                      <PercentageInput
                        {...field}
                        isInvalid={!!fieldState.error}
                      />
                      <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                    </Form.Group>
                  }}
                />
              </Row>
            </Form.Group>
          </Col>
        </Row>
        <LoadingButton type="submit" isLoading={creationState.isLoading}>
          Guardar
        </LoadingButton>
    </Form>
    {createPortal(renderAlert(), document.body)}
  </div>
}