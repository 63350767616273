import Decimal from "decimal.js"
import { Alert, Col, Form, Row } from "react-bootstrap"
import { Controller, useForm } from "react-hook-form"
import { FaCheckCircle, FaSave } from "react-icons/fa"
import { LoadingButton } from "../../../commons/components"
import { PercentageInput } from "../../../commons/components/PercentageInput"
import { Money, MoneyInput } from "../../../commons/money"
import { useActualizarProyectoMutation, useProyectoContext } from "../proyectosApi"
import { Proyecto } from "../types"
import * as yup from "yup"
import { yupResolver } from "@hookform/resolvers/yup"
import { useServerValidationErrors } from "../../../commons/hooks"

const schema = yup.object().shape({
  moneda: yup.string().required().oneOf(["USD", "BOB"]),
  redondeo: yup.number().undefined().label("factor de redondeo").required().min(0.01),
  precioReservas: yup.mixed().label("precio").test("required", "El campo '${path}' es requerido.", function (value: Money) {
    return !!value.amount
  }).test("min", "El campo '${path}' debe ser mayor que 0.", function (value: Money) {
    return !!value.amount?.gt("0")
  }),
  duracionReservas: yup.number().label("duración").undefined().required().integer(),

  cuotaInicial: yup.mixed().label("cuota inicial").test("required", "El campo '${path}' es requerido.", function (value: Money) {
    return !!value.amount
  }).test("min", "El campo '${path}' debe ser mayor o igual que 0.", function (value: Money) {
    return !!value.amount?.gte("0")
  }),
  tasaInteres: yup.string().label("tasa de interés").undefined().required().test("min", "El campo '${path}' debe ser mayor o igual que 0.", function (value) {
    return !value || !!new Decimal(value).gte("0")
  }),
  tasaMora: yup.string().label("tasa de interés por mora").undefined().required().test("min", "El campo '${path}' debe ser mayor o igual que 0.", function (value) {
    return !value || !!new Decimal(value).gte("0")
  }),
})

type FormValues = {

  moneda: string
  precioReservas: Money
  duracionReservas: number
  redondeo: number

  cuotaInicial: Money
  tasaInteres: string
  tasaMora: string
}
export const PreferencesForm = () => {
  const proyecto = useProyectoContext()!

  const {
    control,
    formState,
    getValues,
    handleSubmit,
    register,
    reset,
    setError
  } = useForm<FormValues>({
    defaultValues: {
      moneda: proyecto._raw.moneda,
      redondeo: parseFloat(proyecto.redondeo),
      precioReservas: proyecto.precioReservas,
      duracionReservas: proyecto.duracionReservas,
      cuotaInicial: proyecto.cuotaInicial,
      tasaInteres: new Decimal(proyecto.tasaInteres).mul("100").toFixed(2),
      tasaMora: new Decimal(proyecto.tasaMora).mul("100").toFixed(2)
    },
    resolver: yupResolver(schema)
  })

  const [update, updateState] = useActualizarProyectoMutation()

  useServerValidationErrors(updateState, setError)

  const renderAlert = () => {
    if (updateState.isSuccess) {
      return <Alert className="text-nowrap" variant="success">
        <FaCheckCircle className="me-1" style={{ position: "relative", top: "-0.125rem" }} />
        Guardado.
      </Alert>
    }
    if (updateState.isError) {
      const error = updateState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  // console.log("Render", formState.dirtyFields)
  const dirtyFields = formState.dirtyFields

  return <Form aria-label="Formulario de preferencias" onSubmit={handleSubmit((values) => {
    // console.log("onSubmit", formState.dirtyFields, values)
    const body = (Object.keys(dirtyFields) as Array<keyof FormValues>).reduce((carry, name) => {
      switch (name) {
        case "cuotaInicial":
        case "precioReservas":
          carry[name] = values[name].amount!.toFixed(2)
          break
        case "tasaInteres":
        case "tasaMora":
          carry[name] = new Decimal(values[name]).div(100).toFixed(4)
          break
        case "redondeo":
          carry[name] = values[name].toFixed(2)
          break;
        default:
          carry[name] = values[name] as any
      }
      return carry
    }, {} as Partial<Proyecto>)
    body.id = proyecto.id
    update(body)
      .unwrap()
      .then(() => {
        reset(values)
      }, () => { })
  })}>
    {renderAlert()}
    <Row className="gx-2">
      <Col md={6}>
        <Form.Group data-testid="moneda-form-group" as="fieldset" className="border rounded px-2 pb-2 mb-3">
          <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Moneda</Form.Label>
          <Row className="gx-2" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
            <Col>
              <Form.Check
                id="dolar"
                type="radio"
                label="Dolar"
                value="USD"
                isInvalid={!!formState.errors.moneda && getValues("moneda") === "USD"}
                // feedback={formState.errors.moneda?.message}
                // feedbackType="invalid"
                {...register("moneda")}
              />
            </Col>
            <Col>
              <Form.Check
                id="boliviano"
                type="radio"
                label="Boliviano"
                value="BOB"
                isInvalid={!!formState.errors.moneda && getValues("moneda") === "BOB"}
                // feedback={formState.errors.moneda?.message}
                // feedbackType="invalid"
                {...register("moneda")}
              />
            </Col>
          </Row>
          <Form.Control type="hidden" isInvalid={!!formState.errors.moneda} />
          <Form.Control.Feedback type="invalid">{formState.errors.moneda?.message}</Form.Control.Feedback>
        </Form.Group>
      </Col>
    </Row>
    <Row className="gx-2">
      <Col md={4}>
        <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
          <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Lotes</Form.Label>
          <Row className="gx-2">
            <Controller
              control={control}
              name="redondeo"
              render={({ field, fieldState }) => {
                return <Form.Group data-testid="redondeo-form-group" as={Col} controlId="redondeo">
                  <Form.Label>Factor de redondeo</Form.Label>
                  <Form.Control
                    {...field}
                    // onBlur={(evt) => {
                    //   field.onBlur()
                    //   try {
                    //     evt.target.value = parseFloat(evt.target.value)
                    //     field.onChange(evt)
                    //   }
                    //   catch (error) { }
                    // }}
                    onChange={(evt) => {
                      // const originalValue = e.target.value
                      // const parsedValue = parseFloat(originalValue)
                      // field.onChange(parsedValue ? Math.rouparsedValue.toFixed(2) : originalValue)
                      // try {
                        // evt.target.value = String(new Decimal(evt.target.value).toDecimalPlaces(2))
                        // if (evt.target.value && new Decimal(evt.target.value).decimalPlaces() > 2) return
                        
                        const regexp = /^\d+(\.\d{0,2})?$/
                        if (evt.target.value && !regexp.test(evt.target.value)) return
                        field.onChange(evt)
                      // }
                      // catch (error) { }
                    }}
                    isInvalid={!!fieldState.error}
                  />
                  <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                </Form.Group>
              }}
            />
          </Row>
        </Form.Group>
      </Col>
      <Col md={8}>
        <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
          <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Reservas</Form.Label>
          <Row className="gx-2">
            <Controller
              control={control}
              name="precioReservas"
              render={({ field, fieldState }) => {
                return <Form.Group data-testid="precio-form-group" as={Col} xs={6} controlId="precioReservas">
                  <Form.Label>Precio</Form.Label>
                  <MoneyInput
                    {...field}
                    isInvalid={!!fieldState.error}
                  />
                  <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                </Form.Group>
              }}
            />
            <Form.Group data-testid="duracion-form-group" as={Col} xs={6} controlId="duracionReservas">
              <Form.Label>Duración</Form.Label>
              <Form.Control
                {...register("duracionReservas")}
                isInvalid={!!formState.errors.duracionReservas}
              />
              <Form.Control.Feedback type="invalid">{formState.errors.duracionReservas?.message}</Form.Control.Feedback>
            </Form.Group>
          </Row>
        </Form.Group>
      </Col>
      <Col>
        <Form.Group as="fieldset" className="border rounded px-2 pb-2 mb-3">
          <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Créditos</Form.Label>
          <Row className="gx-2">
            <Controller
              control={control}
              name="cuotaInicial"
              render={({ field, fieldState }) => {
                return <Form.Group data-testid="cuotainicial-form-group" as={Col} md={4} xs={6} controlId="cuotaInicial">
                  <Form.Label>Cuota inicial</Form.Label>
                  <MoneyInput
                    {...field}
                    isInvalid={!!fieldState.error}
                  />
                  <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                </Form.Group>
              }}
            /> 
            <Controller
              control={control}
              name="tasaInteres"
              render={({field, fieldState})=>{
                return <Form.Group data-testid="tasainteres-form-group" as={Col} md={4} xs={6} controlId="tasaInteres">
                  <Form.Label>Tasa de interés</Form.Label>
                  <PercentageInput
                    {...field}
                    isInvalid={!!fieldState.error}
                  />
                  <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                </Form.Group>
              }}
            />
            <Controller
              control={control}
              name="tasaMora"
              render={({field, fieldState})=>{
                return <Form.Group data-testid="tasamora-form-group" as={Col} md={4} xs={6} controlId="tasamora">
                  <Form.Label>Tasa de interés por mora</Form.Label>
                  <PercentageInput
                    {...field}
                    isInvalid={!!fieldState.error}
                  />
                  <Form.Control.Feedback type="invalid">{fieldState.error?.message}</Form.Control.Feedback>
                </Form.Group>
              }}
            />
          </Row>
        </Form.Group>
      </Col>
    </Row>
    <LoadingButton
      type="submit"
      disabled={!formState.isDirty}
      isLoading={updateState.isLoading}
      icon={<FaSave style={{ position: "relative", top: "-0.125rem" }} />}
    >
      Guardar
    </LoadingButton>
  </Form>
}