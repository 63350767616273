import { Suspense } from "react"
import { Image } from "react-bootstrap"
import { FaHome, FaUserLock, FaShieldAlt, FaCoins, FaCalendarAlt, FaCashRegister, FaUsers } from "react-icons/fa"
import { BsCurrencyExchange } from "react-icons/bs"
import { Outlet, Routes } from "react-router-dom"
import { SidebarLayout } from "../../commons/layouts/SidebarLayout"
import { InvoiceAlertIcon } from "../../commons/components/icons"

export const MainBody = () => {  

  return <SidebarLayout sidebar={{
    header: <div className="flex-grow-1"><Image src="/logo192.png" style={{width: "100%"}} /></div>,
    items: [
      {
        id: "home/navitem",
        title: "Inicio",
        icon: <FaHome />,
        path: "/"
      },
      {
        id: "vendedores/navitem",
        title: "Vendedores",
        icon: <FaUsers />,
        path: "/vendedores"
      },
      {
        id: "clientes/navitem",
        title: "Clientes",
        icon: <FaUsers />,
        path: "/clientes"
      },
      {
        id: "lista-mora/navitem",
        title: "Lista de mora",
        icon: <InvoiceAlertIcon />,
        path: "/lista-mora"
      },
      {
        id: "caja/navitem",
        title: "Caja",
        icon: <FaCashRegister />,
        path: "/caja"
      },
      {
        id: "seguridad/navitem-group",
        title: "Seguridad",
        items: [
          {
            id: "usuarios/navitem",
            title: "Usuarios",
            icon: <FaUserLock />,
            path: "usuarios"
          },
          {
            id: "roles/navitem",
            title: "Roles",
            icon: <FaShieldAlt />,
            path: "roles"
          }
        ]
      },
      {
        id: "otros/navitem-group",
        title: "Otros",
        items: [
          {
            id: "ufv/navitem",
            title: "UFV's",
            icon: <FaCoins />,
            path: "ufv"
          },
          {
            id: "exchange-rate/navitem",
            title: "Tipos de cambio",
            icon: <BsCurrencyExchange />,
            path: "tipos-de-cambio"
          }
          // {
          //   id: "calendario/navitem",
          //   title: "Calendario",
          //   icon: <FaCalendarAlt />,
          //   path: "calendario"
          // }
        ]
      }
    ]
  }}>
    <Outlet />
  </SidebarLayout>
}