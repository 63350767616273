import { useState } from "react"
import ReactDOM from "react-dom"
import {Dropdown} from "react-bootstrap"
import {Link} from "react-router-dom"
import { FaEye, FaTimes } from "react-icons/fa"
import {VerticalEllipsisDropdownToggle} from "../../../commons/components"
import { RawTransaccion } from "../types"
import { AnulacionFormModal } from "./AnulacionFormModal"

type Props = {
  transaccion: RawTransaccion
}

export function RowOptions({
  transaccion
}:Props){
  const [showAnulacionForm, setShowAnulacionForm] = useState(false)

  const options: React.ReactElement[] = [
    <Dropdown.Item key="detalles" as={Link} to={`../caja/${transaccion.id}`}><span className="me-2"><FaEye style={{position: "relative", top: "-0.125rem"}} /></span><span>Detalles</span></Dropdown.Item>,
    <Dropdown.Item key="anular" onClick={()=>{
      setShowAnulacionForm(true)
    }}>
      <FaTimes className="me-2 mt-n1" />
      {transaccion.anulacion ? "Ver anulación" : "Anular"}
    </Dropdown.Item>,
  ]
  return <>
    <Dropdown>
      <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
      {ReactDOM.createPortal(
        <Dropdown.Menu style={{textTransform: 'none'}}>
          {options}
        </Dropdown.Menu>
      , document.body)}
    </Dropdown>
    <AnulacionFormModal show={showAnulacionForm} transaccion={transaccion} onHide={()=>{
      setShowAnulacionForm(false)
    }}/>
  </>
}