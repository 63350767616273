import { useState } from "react"
import ReactDOM from "react-dom"
import {Dropdown} from "react-bootstrap"
import {VerticalEllipsisDropdownToggle} from "../../../commons/components"
import { FaClock, FaFileDownload, FaTimes } from "react-icons/fa"
import { RawVenta } from "../types"
import { Link } from "react-router-dom"
import { AnulacionFormModal } from "./AnulacionFormModal"

type Props = {
  venta: RawVenta
}

export function RowOptions({
  venta
}:Props){
  const [showAnulacionForm, setShowAnulacionForm] = useState(false)

  const options: React.ReactElement[] = [
    <Dropdown.Item key="anular" onClick={()=>{
      setShowAnulacionForm(true)
    }}>
      <FaTimes className="me-2 mt-n1" />
      {venta.anulacion ? "Ver anulación" : "Anular"}
    </Dropdown.Item>,
    <Dropdown.Item key="descargarNotaVenta" href={(venta as any).urlNotaVenta} target="_blank">
      <span className="me-2"><FaFileDownload style={{position: "relative", top: "-0.125rem"}} /></span>
      <span>Nota de venta</span>
    </Dropdown.Item>,
  ]
  if(venta.tipo == 2){
    options.push(
      <Dropdown.Item key="descargarPlanPagos" href={venta.credito!.urlPlanPago} target="_blank">
        <span className="me-2"><FaFileDownload style={{position: "relative", top: "-0.125rem"}} /></span>
        <span>Plan de pagos</span>
      </Dropdown.Item>,
      <Dropdown.Item key="descargarHistorialPagos" href={venta.credito!.urlHistorialPagos} target="_blank">
        <span className="me-2"><FaFileDownload style={{position: "relative", top: "-0.125rem"}} /></span>
        <span>Historial de pagos</span>
      </Dropdown.Item>,
      <Dropdown.Item key="programarPagoExtra" as={Link} to={`/creditos/${venta.credito!.codigo}/pagos-extras`} target="_blank">
        <span className="me-2"><FaClock style={{position: "relative", top: "-0.125rem"}} /></span>
        <span>Programar pago adelantado</span>
      </Dropdown.Item>
    )
  }
  if(!options.length) return null
  return <>
    <Dropdown>
      <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
      {ReactDOM.createPortal(
        <Dropdown.Menu style={{textTransform: 'none'}}>
          {options}
        </Dropdown.Menu>
      , document.body)}
    </Dropdown>
    <AnulacionFormModal venta={venta} show={showAnulacionForm} onHide={()=>{
      setShowAnulacionForm(false)
    }} />
  </>
}