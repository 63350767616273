import moment from "moment";
import { useState } from "react";
import { Breadcrumb } from "react-bootstrap";
import Skeleton from "react-loading-skeleton"
import { useParams } from "react-router-dom";
import { skipToken } from "@reduxjs/toolkit/query"
import { IndexTemplate, TableView } from "../../../commons/components";
import { useRefetch } from "../../../commons/utils";
import { reservaPolicy } from "../policies";
import { Filter, useGetReservasQuery, useReservaParser } from "../reservaApi";
import { RowOptions } from "./RowOptions";
import { FaExclamationTriangle } from "react-icons/fa";

export function ReservasIndex(){
  const { proyectoId } = useParams()
  const [ page, setPage ] = useState({
    current: 1,
    size: 10
  })

  const [filter ,setFilter] = useState<Filter>({})

  const buscar = useGetReservasQuery(proyectoId ? {page, filter, proyectoId: +proyectoId} : skipToken)

  const [isRefetching, refetch] = useRefetch(buscar)

  const parser = useReservaParser()

  return <div>
    <Breadcrumb>
      <Breadcrumb.Item active >Reservas</Breadcrumb.Item>
    </Breadcrumb>
    <IndexTemplate
      total={buscar.data?.meta.totalRecords || -1}
      page={page}
      pageSizes={[10,20,30,50,100]}
      registerPath="registrar"
      policy={reservaPolicy}
      onPageChange={setPage}
      onRefetch={refetch}
      renderView={()=>{
        return <TableView
          expectedLength={page.size}
          data={isRefetching ? undefined : buscar.currentData?.records}
          isLoading={buscar.isFetching}
          hasError={buscar.isError}
          renderHeaders={()=><tr>
            <th style={{width: 0}}>#</th>
            <th>Fecha</th>
            <th>Vencimiento</th>
            <th>Cliente</th>
            <th>Lote</th>
            <th>Importe</th>
            <th>Estado</th>
            <th style={{width: 0}}></th>
          </tr>}
          renderRow={(item, index)=>{
            const parsedItem = item ? parser(item): null
            return <tr key={parsedItem?.id || index}>
              <th scope="row">{(page.current - 1)*page.size + index + 1}</th>
              <td className="text-nowrap">{parsedItem ? parsedItem.fecha.format("DD-MM-YYYY") : <Skeleton />}</td>
              <td className={parsedItem?.estado == 1 && moment().startOf("day").isSameOrAfter(parsedItem?.vencimiento.startOf("day")) ? "text-danger" : ""}>
                {parsedItem ? <>
                  <div className="text-nowrap">{parsedItem.vencimiento.format("DD-MM-YYYY")}</div>
                  {parsedItem.estado != 4 ? <div style={{textTransform: 'none'}}>{parsedItem.vencimiento.endOf("day").fromNow()}</div> : null}
                </> : <Skeleton />}
              </td>
              <td>{parsedItem ? <>
                <div>{parsedItem.cliente.nombreCompleto}</div>
                <div className="small text-muted" style={{textTransform: "none"}}>{`Código de pago: ${parsedItem.cliente.codigoPago}`}</div>
                <div className="small text-muted" style={{textTransform: "none"}}>{`${parsedItem.cliente.documentoIdentidad.tipoText}: ${parsedItem.cliente.documentoIdentidad.numero}`}</div>
              </> : <Skeleton />}</td>
              <td className="text-nowrap">
                {item?.observaciones ? <FaExclamationTriangle title={item?.observaciones} className="text-warning mt-n1 me-1" /> : null}
                {parsedItem ? `Mz ${parsedItem.lote.manzana.numero}, Lt ${parsedItem.lote.numero}` : <Skeleton />}
              </td>
              <td className="text-nowrap">{parsedItem ? parsedItem.importe.toFixed(2) : <Skeleton />}</td>
              <td className={item ? {1: "bg-primary text-white", 3: "bg-primary text-white", 2: "bg-danger text-white", 4: "bg-danger text-white"}[item.estado] : ""}>{item ? {
                0: "Procesando",
                1: "Registrada",
                2: "Anulada",
                3: "Concretada",
                4: "Vencida"
              }[item.estado] : <Skeleton/>}</td>
              <td>{item ? <RowOptions reserva={item} /> : null}</td>
            </tr>
          }}
        />
      }}
    />
  </div>
}