import React, { PropsWithChildren } from "react";
import { Button } from "react-bootstrap";

export class ErrorBoundary extends React.Component<PropsWithChildren<{}>, {
  hasError: boolean
}> {
  constructor(props: PropsWithChildren<{}>) {
    super(props);
    this.state = { hasError: false };
  }

  static getDerivedStateFromError(error: Error) {
    // Update state so the next render will show the fallback UI.
    return { hasError: true };
  }

  componentDidCatch(error: Error, errorInfo: React.ErrorInfo): void {
      // You can also log the error to an error reporting service
      // logErrorToMyService(error, errorInfo);
      console.error("Error Boundary", error)
  }

  render() {
    if (this.state.hasError) {
      // You can render any custom fallback UI
      return <>
        <h1>Algo anda mal.</h1>;
        <Button onClick={()=>{
          this.setState({hasError: false})
        }}>Reintentar</Button>
      </>
    }

    return this.props.children; 
  }
}