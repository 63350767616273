import { Col, DropdownButton, Dropdown, Form, InputGroup, Modal, Row, Alert } from "react-bootstrap"
import { FaSave } from "react-icons/fa"
import { Controller, useForm } from "react-hook-form"
import { createHooks } from "../../../commons/modal-context"
import { LoadingButton } from "../../../commons/components"
import { ClienteView, useRegistrarClienteMutation } from "../services/clientesApi"
import { useServerValidationErrors } from "../../../commons/hooks"

const modalHooks = createHooks<{
  onCreated: (cliente: ClienteView) => void
}>("clienteFormModal")

type Inputs = {
  tipo: 1 | 2
  tipoDocumento: 1 | 2
  numeroDocumento: string
  apellidoPaterno: string
  apellidoMaterno: string
  nombre: string
  telefono: string
}
export const ClienteFormModal = (props: {
  defaultShow?: boolean
})=>{

  const {
    isOpen,
    onCreated
  } = modalHooks.useModalState(props.defaultShow)

  const {
    close
  } = modalHooks.useModalActions()

  const {
    control,
    formState,
    handleSubmit,
    register,
    reset,
    setValue,
    setError,
    watch
  } = useForm<Inputs>({
    defaultValues: {
      tipo: 1,
      tipoDocumento: 1
    }
  })

  const [guardar, guardarState] = useRegistrarClienteMutation()

  useServerValidationErrors(guardarState, setError)
  
  const renderAlert = () => {
    // if (mutationState.isSuccess) {
    //   return <Alert className="text-nowrap" variant="success">
    //     <FaCheckCircle className="me-1" style={{ position: "relative", top: "-0.125rem" }} />
    //     La solicitud se completó exitosamente.
    //   </Alert>
    // }
    if (guardarState.isError) {
      const error = guardarState.error as any
      const message: Array<JSX.Element | string> = ["Ocurrio un error al realizar la solicitud"]
      if (error.status != 422) message.push(":", <br key="lnbr" />, error.message ?? error.data?.message ?? "Error de red.")
      else message.push(".")

      return <Alert variant="danger">
        {message}
      </Alert>
    }
  }

  return <Modal centered size="lg" show={isOpen} onHide={()=>{
    close()
  }}>
    <Modal.Header>Registro de clientes</Modal.Header>
    <Modal.Body>
      <Form onSubmit={handleSubmit((values)=>{
        guardar({
          tipo: values.tipo,
          tipoDocumento: values.tipoDocumento,
          numeroDocumento: values.numeroDocumento,
          nombre: values.nombre,
          apellidoPaterno: values.apellidoPaterno,
          apellidoMaterno: values.apellidoMaterno,
          telefono: values.telefono
        }).unwrap().then(result =>{
          onCreated?.(result)
          reset()
          close()
        }, ()=>{ })
      })}>
        {renderAlert()}
        <Row className="gx-2">
          <Col>
            <Form.Group data-testid="tipo-form-group" as="fieldset" className="border rounded px-2 pb-2 mb-3">
              <Form.Label as="legend" className="float-none w-auto px-1 fs-6">Tipo</Form.Label>
              <Controller
                control={control}
                name="tipo"
                render={({field: {ref, value, ...field}, fieldState})=><Row className="gx-2" style={{ paddingTop: "0.375rem", paddingBottom: "0.375rem" }}>
                  <Col>
                    <Form.Check id="ventaAlContado" type="radio" label="Persona natural" value={1} checked={value == 1}
                      isInvalid={!!fieldState.error && value == 1}
                      feedbackType="invalid"
                      feedback={value == 1 ? fieldState.error?.message : ""}
                      {...field} />
                  </Col>
                  <Col>
                    <Form.Check id="ventaAlCredito" type="radio" label="Persona jurídica" value={2} checked={value == 2}
                      isInvalid={!!fieldState.error && value == 2}
                      feedbackType="invalid"
                      feedback={value == 2 ? fieldState.error?.message : ""}
                      {...field} />
                  </Col>
                </Row>}
              />
            </Form.Group>
          </Col>
        </Row>
        <Row className="gx-2">
          <Col lg={3} sm={6}>
            <Form.Group data-testid="documento-form-group" controlId="documento">
              <Form.Label>Documento de identidad</Form.Label>
              <InputGroup hasValidation>
                <DropdownButton
                  title={watch("tipoDocumento") === 1 ? "CI" : "NIT"}
                  align="end"
                >
                  <Dropdown.Item onClick={()=>setValue("tipoDocumento", 1)}>CI</Dropdown.Item>
                  <Dropdown.Item onClick={()=>setValue("tipoDocumento", 2)}>NIT</Dropdown.Item>
                </DropdownButton>
                <Form.Control
                  {...register("numeroDocumento")}
                  isInvalid={!!(formState.errors.numeroDocumento || formState.errors.tipoDocumento)}
                />
                <Form.Control.Feedback type="invalid">{[formState.errors.tipoDocumento?.message, formState.errors.numeroDocumento?.message].join("\n")}</Form.Control.Feedback>
              </InputGroup>
            </Form.Group>
          </Col>
          <Col lg={9}>
            <Row className="gx-2">
              {watch("tipo") == 1 ? <><Form.Group data-testid="apellidopaterno-form-group" as={Col} sm={4} className="mb-3">
                <Form.Label>Apellido Paterno</Form.Label>
                <Form.Control
                  {...register("apellidoPaterno")}
                  isInvalid={!!formState.errors.apellidoPaterno}
                />
                <Form.Control.Feedback type="invalid">{formState.errors.apellidoPaterno?.message}</Form.Control.Feedback>
              </Form.Group>
              <Form.Group data-testid="apellidomaterno-form-group" as={Col} sm={4} className="mb-3">
                <Form.Label>Apellido Materno</Form.Label>
                <Form.Control
                  {...register("apellidoMaterno")}
                  isInvalid={!!formState.errors.apellidoMaterno}
                />
                <Form.Control.Feedback type="invalid">{formState.errors.apellidoMaterno?.message}</Form.Control.Feedback>
              </Form.Group></> : null}
              <Form.Group data-testid="nombre-form-group" as={Col} className="mb-3">
                <Form.Label>Nombre</Form.Label>
                <Form.Control
                  {...register("nombre")}
                  isInvalid={!!formState.errors.nombre}
                />
                <Form.Control.Feedback type="invalid">{formState.errors.nombre?.message}</Form.Control.Feedback>
              </Form.Group>
            </Row>
          </Col>
        </Row>
        <Row>
          <Form.Group data-testid="telefono-form-group" as={Col} sm={4} lg={3} className="mb-3">
            <Form.Label>Telefono</Form.Label>
            <Form.Control
              {...register("telefono")}
              isInvalid={!!formState.errors.telefono}
            />
            <Form.Control.Feedback type="invalid">{formState.errors.telefono?.message}</Form.Control.Feedback>
          </Form.Group>
        </Row>
        <LoadingButton
          type="submit"
          isLoading={guardarState.isLoading}
          icon={<FaSave className="me-2" style={{position: "relative", top: "-0.125rem"}}/>}
        >
          Guardar
        </LoadingButton>
      </Form>
    </Modal.Body>
  </Modal>
}

ClienteFormModal.useActions = modalHooks.useModalActions