import { appApi, PaginatedResponse } from "../../commons/services";

export type Plano = {
  id: number
  titulo: string
  descripcion: string
  isVigente: boolean
  isLocked: boolean
  hasErrors: boolean
}

export const planosApi = appApi.enhanceEndpoints({
  addTagTypes: ["Plano"]
}).injectEndpoints({
  endpoints: (builder) => ({
    getPlanos: builder.query<PaginatedResponse<Plano>, {proyectoId: number}>({
      query: ({proyectoId, ...params}) => ({
        url: `/proyectos/${proyectoId}/planos`,
        params
      }),
      providesTags: (results)=>(results?.records??[])
        .map(({id})=>({type: "Plano" as const, id: String(id)}))
        .append({type: "Plano" as const, id: "*"})
    }),
    getPlano: builder.query<Plano, {proyectoId: number, planoId: number}>({
      query: ({proyectoId, planoId}) => ({
        url: `/proyectos/${proyectoId}/planos/${planoId}`,
      }),
      providesTags: (result)=> result ? [{type: "Plano" as const, id: String(result.id)}] : []
    }),


    registrarPlano: builder.mutation<Plano, {
      proyectoId: number,
      body: FormData
    }>({
      query: ({proyectoId, body})=>({
        method: "POST",
        url: `/proyectos/${proyectoId}/planos`,
        body
      }),
      invalidatesTags: (result) => result ? [{type: "Plano" as const, id: "*"}] : []
    }),
    actualizarPlano: builder.mutation<Plano, {
      proyectoId: number,
      planoId: number,
      body: FormData
    }>({
      query: ({proyectoId, planoId, body})=>{
        body.append("_method", "PUT")
        return {
          method: "POST",
          url: `/proyectos/${proyectoId}/planos/${planoId}`,
          body
        }
      },
      invalidatesTags: (result) => result ? [{type: "Plano" as const, id: String(result.id)}] : []
    }),
  })
})

export const {
  useGetPlanosQuery,
  useGetPlanoQuery,
  useRegistrarPlanoMutation,
  useActualizarPlanoMutation,
} = planosApi