import { forwardRef, useImperativeHandle, useState } from "react"
import { Modal } from "react-bootstrap"
import { PDFViewer } from "./PDFViewer"

type Props = {
  defaultShow?: boolean
}

export type PdfModalRef = {
  open(url: string, title: string): void
}

export const PdfModal = forwardRef<PdfModalRef, Props>(({
  defaultShow
}, ref)=>{
  const [show, setShow] = useState(!!defaultShow)
  const [title, setTitle] = useState("")
  const [url, setUrl] = useState("")

  useImperativeHandle(ref, ()=>({
    open: (url, title)=>{
      setShow(true)
      setTitle(title)
      setUrl(url)
    }
  }))

  const renderModalHeader = ()=>{
    return title
  }

  const renderModalBody = ()=>{
    return url ? <PDFViewer url={url} height="100%" /> : null
  }

  return <Modal
    centered
    show={show}
    size="lg"
    dialogClassName="align-items-stretch"
    onHide={()=>{
    setShow(false)
  }}>
    <Modal.Header>
      {renderModalHeader()}
    </Modal.Header>
    <Modal.Body className="p-0">
      {renderModalBody()}
    </Modal.Body>
  </Modal>
})