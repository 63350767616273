import React from 'react'
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { User, useUser } from '../hooks';
import { superUserPolicyEnhancer } from '../utils';

type Props = {
  authorize?: (user: User, url: string) => boolean | undefined
  children: React.ReactNode
}
export const RequireAuth = ({ authorize, children }: Props) => {
  const location = useLocation()
  const ignoreAuthorization = (location.state as {ignoreAuthorization: boolean})?.ignoreAuthorization
  const user = useUser()
  
  if(user === null) return <Navigate
    replace
    to="/login"
    state={{from: location}}
  />
  if(user.isLoading) return null
  if(user.error) return <>No se pudieron obtener los datos del usuario</>
  if(ignoreAuthorization || !authorize || superUserPolicyEnhancer(authorize)(user, location.pathname)) return <>{children}</>
  return <Navigate
    replace
    to="/forbidden"
  />
}

export default RequireAuth