import Decimal from "decimal.js"
import { useRef, useState } from "react"
import { Button, Col, Row } from "react-bootstrap"
import Skeleton from "react-loading-skeleton"
import { Link } from "react-router-dom"
import { IndexTemplate, GridView } from "../../../commons/components"
import { Page } from "../../../commons/services"
import { ProyectoFilter, useGetProyectosQuery } from "../proyectosApi"

export const ProyectosIndex = () => {
  const [filter, setFilter] = useState<ProyectoFilter>({})
  const [page, setPage] = useState<Page>({
    current: 1,
    size: 6
  })

  const getProyectos = useGetProyectosQuery({filter, page})

  const ignoreData = useRef(false)

  if(!getProyectos.isFetching) ignoreData.current = false

  return <div>
    <h1 style={{fontSize: "1.5rem", fontWeight: "bold"} }>Mis proyectos</h1>
    <IndexTemplate
      total={getProyectos.data?.meta.totalRecords || -1}
      page={page}
      pageSizes={[6, 12, 24, 48, 96]}
      policy={{view: ()=>true, register: ()=>true}}
      onPageChange={setPage}
      onRefetch={()=>{
        ignoreData.current = true
        getProyectos.refetch()
      }}
      registerPath="/proyectos/registrar"
      renderView={()=>{
        return <GridView
          data={ignoreData.current ? undefined : getProyectos.currentData?.records}
          expectedLength={page.size}
          isLoading={getProyectos.isFetching}
          hasError={getProyectos.isError}
          renderItemHeader={(item)=>item ? item.nombre : <Skeleton />}
          renderItemBody={(p) => {
            return <div>
              <Row as="dl">
                {/* <Col xs={12}>
                  <Row className="gx-2">
                    <Col as="dt" xs="auto">Socio:</Col>
                    <Col as="dd">{p ? p.socio : <Skeleton />}</Col>
                  </Row>
                </Col> */}
                <Col xs={12}>
                  <Row className="gx-2">
                    <Col as="dt" xs="auto">Lotes:</Col>
                    <Col as="dd">{p ? `${p.lotesSummary.total} (${p.lotesSummary.disponibles} disponibles)` : <Skeleton />}</Col>
                  </Row>
                </Col>
                
                <Col xs={12}>
                  <Row className="gx-2">
                    <Col as="dt" xs="auto">Clientes en mora:</Col>
                    <Col as="dd">{p ? p.clientesEnMora : <Skeleton />}</Col>
                  </Row>
                </Col>
              </Row>
              {p ? <Row>
                <Col>
                  <Button 
                    //@ts-expect-error
                    as={Link}
                    to={{pathname: `/proyectos/${p.id}`}} 
                    state={{proyectoContext: p}} 
                    className="ms-auto"
                    style={{width: "100%"}}
                  >Ir</Button>
                </Col>
              </Row> : null}
            </div>
          }}
        />
      }}
    />
  </div>
}