import { appApi, PaginationParams } from "../../commons/services"
import { PaginatedResponse } from "../../commons/services/types"

export type UFV = {
  id: string
  fecha: string
  valor: string
}

export type UFVFilter = {
  desde?: string
  hasta?: string
}

export type UfvIndexParams = PaginationParams<UFVFilter>

export const ufvApi = appApi.enhanceEndpoints({
  addTagTypes: [ "UFV" ]
}).injectEndpoints({
  endpoints: (build) => ({
    getUFVs: build.query<PaginatedResponse<UFV>, UfvIndexParams>({
      query: (params)=>({
        url: `ufvs`,
        params
      }),
      providesTags: (results)=>(results?.records??[])
        .map(({id})=>({type: "UFV" as const, id: String(id)}))
        .append({type: "UFV" as const, id: "*"})
    }),

    registrarUfv: build.mutation<UFV, Omit<UFV, "id">>({
      query: (body) => ({
        method: "POST",
        url: `ufvs`,
        body
      }),
      invalidatesTags: (result) => result ? [{type: "UFV" as const, id: "*"}] : []
      /**
       * 
       * Si la pagina actual no es la primera, tomando en cuenta que se quiere actualizar la cache
       * para mastrar el nuevo registro al principio de todo ¿Debería actualizarse la cache?
       * Y si el filtro usado no coincide con el nuevo registro ¿Debería mostrarse en el indice?
       * peor aun si la logica para aplicar el filtro es muy compleja que tiene que realizarse en
       * el servidor
       * 
       * Probablemente sea mejor solo invalidar la cache
       */
      // onQueryStarted: (arg, api)=>{
      //   api.queryFulfilled.then((response)=>{
      //     api.dispatch(ufvApi.util.updateQueryData('getUFVs', arg._meta.indexParams, (draftUfv) => {
      //       draftUfv.records.splice(0, 0, response.data)
      //     }))
      //   }, (e)=>{ })
      // }
    })
  })
})

export const {
  useGetUFVsQuery,
  useRegistrarUfvMutation
} = ufvApi