import ReactDOM from "react-dom"
import {Dropdown} from "react-bootstrap"
import {Link} from "react-router-dom"
import {VerticalEllipsisDropdownToggle} from "../../../commons/components"
import { ListaMoraEntry } from "../../../commons/services/types"
import { FaEye, FaWhatsapp } from "react-icons/fa"

type Props = {
  item: ListaMoraEntry
}

export function RowOptions({
  item
}:Props){
  return <Dropdown>
    <Dropdown.Toggle as={VerticalEllipsisDropdownToggle} variant="link" />
    {ReactDOM.createPortal(
      <Dropdown.Menu style={{textTransform: 'none'}}>
          <Dropdown.Item as={Link} to={`../lista-mora/${item.cliente.id}`} state={{entry: item}}>
            <FaEye className="me-1 mt-n1"/>
            Ver detalles
          </Dropdown.Item>
          <Dropdown.Item
            target="_blank"
            href={`https://wa.me/${item.cliente.telefono}/?text=Estimado(a)%20${item.cliente.nombre.trim().toLocaleLowerCase().split(/\s+/).map(w => w[0].toLocaleUpperCase() + w.substring(1)).join(" ")},`} 
          >
            <FaWhatsapp className="me-1 mt-n1" />
            Abrir Whatsapp
          </Dropdown.Item>
      </Dropdown.Menu>
    , document.body)}
  </Dropdown>
}