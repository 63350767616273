import { createApi } from "@reduxjs/toolkit/dist/query/react";
import { apiBaseUrl } from "../../configs/app";
import { createBaseQuery } from "./createBaseQuery";
import { PaginatedResponse, ListaMoraEntry, ListaMoraFilter, Page, PaginationParams } from "./types";

const appBaseQuery = createBaseQuery(apiBaseUrl)

export const appApi = createApi({
  reducerPath: "appApi",
  baseQuery: appBaseQuery(),
  tagTypes: ["ListaMora"],
  refetchOnMountOrArgChange: 30,
  endpoints: (build) =>({
    getListaMora: build.query<PaginatedResponse<ListaMoraEntry>, PaginationParams<ListaMoraFilter>>({
      query: (params) => ({url: `lista-mora`, params}),
      providesTags: [{type: "ListaMora" as const, id: "*"}]
    }),
  })
})