import { Alert, Breadcrumb, Spinner } from "react-bootstrap"
import { Link, useParams } from "react-router-dom"
import { useGetUsuarioQuery } from "../usuariosApi"
import { UsuarioForm } from "./UsuarioForm"

export function UsuarioFormEdit(){
  const {
    usuarioId
  } = useParams()
  const getUsuario = useGetUsuarioQuery(+usuarioId)

  const renderForm = ()=>{
    if(getUsuario.isLoading){
      return <div className="text-nowrap">
        <Spinner size="sm" animation="border" className="me-2" />
        Cargando...
      </div>
    }

    if(getUsuario.error){
      const error = getUsuario.error as any
      const message: Array<JSX.Element | string> = [
        "Ocurrio un error al realizar la solicitud:",
        <br key="error-br" />,
        error.message ?? error.data?.message ?? "Error de red"
      ]
      return <Alert variant="danger">
        {message}
      </Alert>
    }

    const usuario = getUsuario.currentData

    return <UsuarioForm
      data={usuario}
    />
  }

  return <>
    <Breadcrumb>
      <Breadcrumb.Item linkAs={Link} linkProps={{
        to: "../usuarios"
      }}>Usuarios</Breadcrumb.Item>
      <Breadcrumb.Item  linkAs={Link} linkProps={{
        to: `../usuarios/${usuarioId}`
      }}>{usuarioId}</Breadcrumb.Item>
      <Breadcrumb.Item active>Editar</Breadcrumb.Item>
    </Breadcrumb>
    {renderForm()}
  </>
}